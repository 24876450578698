'use strict';

export default class PinEntry {

  constructor() {

    const $pinEntryForm = $('form.form--pin'),
          serverUrl = $pinEntryForm.attr('data-url'),
          errorMsg = $pinEntryForm.attr('data-errorMsg'),
          $pinField = $('input.pin--field'),
          $pinErrorBox = $('.pin-entry .pin-alert-box'),
          $pinErrorBoxText = $('h2', $pinErrorBox);


    $pinEntryForm.on({
      input: function(e){
        e.preventDefault();

        let target = e.srcElement || e.target,
            targetLength = target.value.length,
            pin = '',
            totalPin = '';
  
  
        $('form.form--pin input.pin--field').map((i, ele) => {
          if ($(ele).hasClass('error')){
            $(ele).removeClass('error');
          }
        });
        
        if (!$pinErrorBox.hasClass('hide')) $pinErrorBox.addClass('hide');
        
        if (targetLength >= 1){
          let next = target;
          while (next = $(next).next()){ //as long as there is a next target
              if (next == null) break;
              next.focus();
              break;
          }
        }
  
        $('form.form--pin input.pin--field').map((i, ele) => {
          if ($(ele).val()){
            pin += $(ele).val();
            totalPin ++;
          }
        });
  
        if (totalPin >= 4){
          const scController = $('#scController').val(),
          scAction = $('#scAction').val(),
          treeId = $('#treeId').val();

          const formData = {
            'scController': scController,
            'scAction': scAction,
            'treeId': treeId,
            'pin': pin
          };
          
          // console.log('post');  
          
          $.ajax({
            method: 'POST',
            url: serverUrl, //change server url for pin code
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(formData),
            success: function(data){
              //get url from server and load.
              if (data.status == "success"){

                if (data.message){
                  // console.log(data.Redirection);
                  window.location.replace(data.message)
                }
                
              }else{
                // console.log('forbidden. Please try again or contact administrator ');
  
                $('form.form--pin input.pin--field').map((i, ele) => {
                    if (i === 0){
                      $(ele).focus();
                    }
                    $(ele).addClass('error');
                    $(ele).val('');
                });
  
                if (data.message){
                  $pinErrorBoxText.html(data.message);
                  $pinErrorBox.removeClass('hide');            
                }

              }
            },
            error: function (data){

              $('form.form--pin input.pin--field').map((i, ele) => {
                  if (i === 0){
                    $(ele).focus();
                  }
                  $(ele).addClass('error');
                  $(ele).val('');
              });

              if (data.responseJSON){
                if (data.responseJSON.message){
                  $pinErrorBoxText.html(data.responseJSON.message);
                  $pinErrorBox.removeClass('hide');            
                }
              }

            }
          });
        }
        
      },
      keyup: function(e) {

        if (e.keyCode === 8){ //backspace

          let target = e.srcElement || e.target,
              previous = target;
              
          while (previous = $(previous).prev()){
              if (previous == null) break;
              previous.focus();
              previous.val('');
              break;
          }
        }
      }

    });

  }
}
