'use strict';

import $ from 'jquery';

export default class PlantTreeConfirmPopup {
  constructor(bool, url, map, $markersLayer) {

    let $popup = $('.plant-tree-confirm-popup'),
    $btn = $('button', $popup),
    iconUrl = url,
    $map = map,
    plotTreeIframe = document.getElementById('plotTreeIframe');

    // resizeIFrameToFitContent(plotTreeIframe);


    /** HANDLE SHOWING AND HIDING OF POPUP **/
    if (bool) {

      $('.plant-tree-confirm-popup').addClass('show');

    } else {

      $('.plant-tree-confirm-popup').removeClass('show');

    }


    $(document).unbind().on('click touchend', '.plant-tree-confirm-popup button', function(e) {
      e.preventDefault();

      let $this = $(this),
      val = $this.val();


      window.pinDropped = false;
      window.emitter.emit('allowZoomFeature', true);


      $('.plant-tree-confirm-popup').removeClass('show');


      if (val === 'yes') {

        $('.plot-tree-form').addClass('show');


        window.bodyScrollTop = $(document).scrollTop();

        $('body').css('top', `-${window.bodyScrollTop}px`);

        $('body').addClass('form-open');


      } else if (val === 'no') {


        window.emitter.emit('closeForm', []);

      }
    });

  }
}
