'use strict';

import $ from 'jquery';
import { addClass, removeClass, triggerFirstPageClick } from '_helpers';

export default class MapContribute {
	constructor() {
		let $contributeWrapper = $('.map-contribute-wrapper'),
			$contributeForm = $('.contribute-form-wrapper'),
			$searchMessage = $('.search-message', $contributeWrapper),
			$searchInput = $('.search-input', $searchMessage),
			$writeMessage = $('.write-message', $contributeWrapper),
			$search = $('.btn--search', $contributeWrapper),
			$closeSearch = $('.js-close-search', $contributeWrapper);

		$search.on('click', (e) => {
			let $this = $(e.target);
			e.preventDefault();

			addClass($searchMessage, 'open');
			addClass($search, 'hide');
			addClass($writeMessage, 'hide');
			triggerFirstPageClick($('.page-item.first'));
		});


		function addElementsToForm() {
			// character count
			let beforeCharcount = $('#iframe').contents().find('span[data-valmsg-for="wffm8694d9e84af84fbf9c182e881ffdc7fa.Sections[0].Fields[2].Value"]');
			beforeCharcount.after('<span class="charcount">0/140</span>');

			// required warning
			let beforeWarning = $('#iframe').contents().find('.form-group:nth-of-type(3)');
			beforeWarning.after('<span class="required-warning">* Required</span>');

			let afterUpload =  $('#iframe').contents().find('input#wffm8694d9e84af84fbf9c182e881ffdc7fa_Sections_1__Fields_0__Id');
			afterUpload.before('<div class="filename"><span><img src="../Cwp/assets/PTM/images/image.svg" alt=""></span></div>');

			let beforeLimit = $('#iframe').contents().find('span[data-valmsg-for="wffm8694d9e84af84fbf9c182e881ffdc7fa.Sections[1].Fields[0].Value"]');
			beforeLimit.after('<span class="limit">(max. 1MB)</span><span class="error">File Size exceeds 1MB.</span>');

			let beforeDisclaimer = $('#iframe').contents().find('form');
			beforeDisclaimer.after('<div class="disclaimer-text"><p>Disclaimer: Lorem ipsum dolor sit amet adiscpling elit sed do eiusmod tempor incididunt ut.</p></div>')

			$('#iframe').contents().find('.control-label[for="wffm8694d9e84af84fbf9c182e881ffdc7fa_Sections_0__Fields_0__Value"]').append('<span class="required-warning name-check">&nbsp;*</span>');

			$('#iframe').contents().find('.control-label[for="wffm8694d9e84af84fbf9c182e881ffdc7fa_Sections_0__Fields_1__Value"]').append('<span class="required-warning email-check">&nbsp;*</span>');
		}
	}
}
