'use strict';

import $ from 'jquery';

const checkIDInLocalStorage = treeid => {
  let localStorageTreeIDs = JSON.parse(localStorage.getItem(treeIDStorage)) || [],
    flag = false;

  if (localStorageTreeIDs) {
    // flag = localStorageTreeIDs.indexOf(treeid) !== -1;
    localStorageTreeIDs.forEach(function (each) {
      if (treeid.toString() == each.treeID.toString()) {
        flag = true;
      }
    });
  }
  return flag;
};

const addClass = (ele, className) => {
  ele.addClass(className);
};

const removeClass = (ele, className) => {
  ele.removeClass(className);
};

const triggerFirstPageClick = ele => {
  ele.trigger('click');
};

const validateName = name => {
  if (name) {
    return true;
  }
};

const validateEmail = email => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const escapeString = input => {
  return String(input)
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/=/g, '&#61;')
    .replace(/:/g, '&#58;')
    .replace(/\$/g, '&#36;')
    .replace(/\*/g, '&#42;')
    .replace(/\!/g, '&#33;')
    .replace(/\(/g, '&#40;')
    .replace(/\)/g, '&#41;')
    .replace(/`/g, '&#180;');
};

const assignIDForAria = ele => {
  ele.each((index, ele) => {
    let $this = $(ele);
    if ($this.attr('aria-describedby') != undefined) {
      $this.attr('id', $this.attr('aria-describedby'));
    }
  });
};


const setSearchState = (state) => {
  if (!state){
    $('#searchBtn i').removeClass('icon-close');
    $('#searchBtn i').addClass('icon-search');
  }else{
    $('#searchBtn i').removeClass('icon-search');
    $('#searchBtn i').addClass('icon-close');
  }
}

//PROD mapLayers
var mapLayers = {
	searchLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/TreeInformation_Hashing/FeatureServer/0',
  treeLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/TreeInformation_Hashing/FeatureServer/0', //for flowering, heritage and all
  userPlantedLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/TreesPlotting/FeatureServer/0',
  otherLayers: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTMap/FeatureServer',
  view_11: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid/FeatureServer/0',
  view_12: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid/FeatureServer/1',
  view_13: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid/FeatureServer/2',
  view_14: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid/FeatureServer/3',
  view_15: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid/FeatureServer/4',
  view_16: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid/FeatureServer/5',
  view_17: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid/FeatureServer/6',
  view_18: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/TreeInformation_Hashing/FeatureServer/0',
  heritageLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/corpWeb/FeatureServer/0',
  statisticsLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_Statistics/FeatureServer/0'
};

//UAT mapLayers
/*var mapLayers = {
	searchLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/TreeInformation_UAT/FeatureServer/0',
  treeLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/TreeInformation_UAT/FeatureServer/0', //for flowering, heritage and all
  userPlantedLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/TreesPlotting_UAT/FeatureServer/0',
  otherLayers: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTMap_UAT/FeatureServer',
  view_11: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid_UAT/FeatureServer/0',
  view_12: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid_UAT/FeatureServer/1',
  view_13: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid_UAT/FeatureServer/2',
  view_14: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid_UAT/FeatureServer/3',
  view_15: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid_UAT/FeatureServer/4',
  view_16: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid_UAT/FeatureServer/5',
  view_17: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_View_Grid_UAT/FeatureServer/6',
  view_18: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/TreeInformation_UAT/FeatureServer/0',
  heritageLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/corpWebStg/FeatureServer/0',
  statisticsLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_Statistics_UAT/FeatureServer/0'
};*/

export {
  checkIDInLocalStorage,
  addClass,
  removeClass,
  triggerFirstPageClick,
  validateEmail,
  validateName,
  escapeString,
  assignIDForAria,
  mapLayers,
  setSearchState
};
