"use strict";

import $ from "jquery";

export default class TreeDetail {
	constructor() {
		$("body").on("click", "#addthisShare", function(e) {
			e.preventDefault();

			let $this = $(this),
				url = $this.attr("href");

			/* Copy the text inside the text field */
			//copyStringToClipboard(url);
			$('.shareUrl').text(url);
			$('.custom-alert-box').removeClass('hide');
			document.body.scrollTop = 0; // For Safari
  			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
			// alert(`${url} copied! Now share it with your friends.`);
		});

		$("body").on("click", "#tooltipclose", function(e) {
			e.preventDefault();
			$(this).parent().addClass('hide');
			// setCookie('visited', true, 32); //cookie expiry 32 days
		});

		$("body").on("click", ".vip-planted-button", function(e) {
			e.preventDefault();
			
			const $speciesName = $(".detail-wrapper__text h2 span").text(),
				  $treeAge = $(".detail-wrapper__text .age-param").text(),
				  $treeSize = $(".accordion-text span").text(),
				  $commonName = $(".detail-wrapper__text p.common-name").text(),
				  $iFrameContent = $("#vipTreeIframe").contents(),
				  $vipTreeInfoGirth = $iFrameContent.find(".vip-tree-form__form-content .tree-information-wrapper .girth"),
				  $vipTreeInfoAge = $iFrameContent.find(".vip-tree-form__form-content .tree-information-wrapper .age"),
				  $vipTreeInfoHeight = $iFrameContent.find(".vip-tree-form__form-content .tree-information-wrapper .height"),
				  $vipTreeInfoScientific = $iFrameContent.find(".vip-tree-form__form-content .tree-information-wrapper .scientific-name"),
				  $vipTreeInfoTreeID = $iFrameContent.find(".vip-tree-form__form-content #vipTreeForm input[name=treeID]"),
				  $vipTreeInfoTreeType = $iFrameContent.find(".vip-tree-form__form-content #vipTreeForm input[name=treeType]"),
				  $vipTreeInfoTreeMail = $iFrameContent.find(".vip-tree-form__form-content #vipTreeForm input[name=treeMail]"),
				//   $vipTreeInfoMarker = $iFrameContent.find(".vip-tree-form__form-content #vipTreeForm input[name=markerObject]"),
				  urlParams = new URLSearchParams(window.location.search),
				  urlBase = window.location.origin;
          	


			$vipTreeInfoTreeID.val(urlParams.get('treeID'));
			$vipTreeInfoTreeType.val(urlParams.get('treetype'));
			$vipTreeInfoTreeMail.val(urlBase + '/' + window.location.search + '&treemail=true');
			// console.log($('.leaflet-marker-active').attr('src'));
			
			// for (const [key, value] of urlParams){
			// 	console.log(key, value);
			// }
			// console.log(urlParams.get('treeType'));
			let	commonName = '',
				treeDimension = [],
				treeGirth = '',
				treeHeight = '',
				treeAge = '';

			if ($commonName){
				commonName = $commonName.replace('Common Name:', '').trim();
			}

			if ($treeAge){
				if (!$treeAge.toLowerCase().includes("years") || !$treeAge.toLowerCase().includes("year")){
					treeAge = $treeAge + " years";
				}else{
					treeAge = $treeAge;
				}
			}

			if ($treeSize){
				treeDimension = $treeSize.split(';');
				if (treeDimension[0]){
					treeGirth = treeDimension[0].replace('Girth Size:', '').trim();
				}

				if (treeDimension[1]){
					treeHeight = treeDimension[1].replace('Height:', '').trim();
				}
			}

			if (treeGirth){
				const htmlGirth = 'Girth size <span class=sub-param>' + treeGirth + '</span>'
				$vipTreeInfoGirth.html("").html(htmlGirth);
			}else{
				$vipTreeInfoGirth.addClass('hide');
			}

			if (treeHeight){
				const htmlHeight = 'Height <span class=sub-param>' + treeHeight + '</span>'
				$vipTreeInfoHeight.html("").html(htmlHeight);
			}else{
				$vipTreeInfoHeight.addClass('hide');
			}

			if (treeAge){
				const htmlAge = 'Age <span class=sub-param>' + treeAge + '</span>'
				$vipTreeInfoAge.html("").html(htmlAge);
			}else{
				$vipTreeInfoAge.addClass('hide');
			}

			if ($speciesName){
				const htmlScientific = '</strong>' + $speciesName + '</strong> <small class=sub-param common-name>' + commonName + '</small>'
				$vipTreeInfoScientific.html("").html(htmlScientific);
			}else{
				$vipTreeInfoScientific.addClass('hide');
			}

			// console.log(window.selectedTree_global);
			// $vipTreeInfoMarker.val(window.selectedTree_global);

			// console.log($speciesName);
			// console.log(commonName);
			// console.log(girthSize);
			// console.log(treeHeight);
			$(".vip-tree-form").addClass('show');
			//console.log('hello');
		});

		$("body").on('click', '.report-button', function (e) {
			e.preventDefault();
			
			window.emitter.emit('openFlagTreeForm', true);

			$('html, body').animate({ scrollTop: 0 }, 'slow');
			//$('.onemap-wrapper .overlay').removeClass('close');
		});
		


	}
}
