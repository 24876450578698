'use strict';

import $ from 'jquery';
import { checkIDInLocalStorage } from '_helpers';
import ErrorPopup from '../error-popup/error-popup';

export default class HugTree {
  constructor(ele, treeid, addhugsurl, removehugsurl) {

    let $icon = $('.icon', ele),
      $number = $('.number', ele.parent()),
      currentMonth = new Date().getMonth(),
      currentPage = window.location.pathname,
      localStorageTreeIDs = '';
    

    localStorageTreeIDs = JSON.parse(localStorage.getItem(treeIDStorage))


    const treeIDArr = JSON.parse(localStorage.getItem(treeIDStorage)) || [];

    let checkTreeOfMonth = function () {
      let len = treeIDArr.length;

      while (len--) {
        if (currentMonth > treeIDArr[len].setMonth) {
          treeIDArr.splice(len, 1);
        } else {
          if (checkIDInLocalStorage(treeid)) {
            $icon.removeClass('icon-heart-line dislike').addClass('icon-heart animate');
          }
        }
      }

      localStorage.setItem(treeIDStorage, JSON.stringify(treeIDArr));
    }();

    ele.on('click', (e) => {

      e.preventDefault();

      let currentHugsCount = $number.html();



      if (window.freezeSite) {

        new ErrorPopup(true);

      } else {
        let json = {
          TreeId: treeid
        }

        if (checkIDInLocalStorage(treeid)) {
          // minus here
          $.ajax({
            method: 'POST',
            url: removehugsurl,
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(json)
          }).done(function (data) {
          });

          let filteredArr = treeIDArr.filter(id => {
            return id.treeID.toString() !== treeid.toString()
          });

          localStorage.setItem(treeIDStorage, JSON.stringify(filteredArr));

          currentHugsCount--;
          $number.html(currentHugsCount);
        } else {
          // add here
          $.ajax({
            method: 'POST',
            url: addhugsurl,
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(json)
          }).done(function (data) {

          });

          let newTreeObject = {
            'treeID': treeid.toString(),
            'setMonth': currentMonth,
          }

          if (!treeIDArr.find((item) => {
            return item.treeID.toString() === newTreeObject.treeID.toString() && item.setMonth === newTreeObject.setMonth
          })) {
            treeIDArr.push(newTreeObject);
          }

          localStorage.setItem(treeIDStorage, JSON.stringify(treeIDArr));

          currentHugsCount++;
          $number.html(currentHugsCount);
        }

        // for markup
        if ($icon.hasClass('icon-heart-line')) {
          $icon.removeClass('icon-heart-line dislike').addClass('icon-heart animate');
        } else {
          $icon.removeClass('icon-heart').addClass('icon-heart-line animate dislike');
        }
      }




    });
  }
}
