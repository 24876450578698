'use strict';

import gsap from'gsap';
import {TweenMax} from 'gsap';
import ScrollMagic from 'scrollmagic';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

export default class StandardContent {
	constructor() {
		ScrollMagicPluginGsap(ScrollMagic, gsap);

		let scrollMagicController = new ScrollMagic.Controller({
			globalSceneOptions: {
				triggerHook: 'onLeave'
			}
		});

		let tweenLayer1 = TweenMax.fromTo('.parallax-layer-top', 1 , {
			y: 0
		}, {
			y: 250,
			ease: Power3.easeOut
		});

		let tweenLayer2 = TweenMax.fromTo('.parallax-layer-bottom', 1 , {
			y: -390
		}, {
			y: -200,
			ease: Power3.easeOut
		});

		let scene1 = new ScrollMagic.Scene({
			triggerElement: '.standard--content',
			offset: -200,
			duration: '200%'
		})
		.setTween(tweenLayer1);

		let scene2 = new ScrollMagic.Scene({
			triggerElement: '.standard--content',
			offset: -200,
			duration: '200%'
		})
		.setTween(tweenLayer2);

		scrollMagicController.addScene([
			scene1,
			scene2
		]);

	}
}
