'use strict';

import $ from 'jquery';

export default class MapContainer {
	constructor() {

		this.$mapContainer = $('.map--container'),
		this.$mapBanner = $('.map--banner', this.$mapContainer),
		this.$mapView = $('.map--view', this.$mapContainer),
		this.$onemapWrapper = $('.onemap-wrapper', this.$mapContainer),
		this.$exploreMap = $('.js-explore-map', this.$mapBanner);

		$(window).on('scroll.parallaxElement', this.parrallaxBackground).trigger('scroll.parallaxElement');

		// check url param.
		$.urlParam = (name) => {
			var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
			if (results){
				return results[1] || 0;
			}
			return results = null;
		}

		//if ?direct=true, hide first layer with explore map and about this map pop up.
		if ($.urlParam('direct')){
			$('.info-popup').css("display", "none");
			$('.about-map').removeClass("show-info"); //remove about map pop up
			this.showMap();
		}

		this.$exploreMap.on('click', (e) =>{
			e.preventDefault();
			this.showMap();
		});
	}

	showMap(){

		if (!this.$mapContainer.hasClass('map-height'))
			this.$mapContainer.addClass('map-height');

		if (!this.$mapBanner.hasClass('hide'))
			this.$mapBanner.addClass('hide');

		if (!this.$mapView.hasClass('show')){
			this.$mapView.addClass('show');

			setTimeout(basemap_info_fade_out, 10000);

			function basemap_info_fade_out() {
				$(".basemap-change-button__information").fadeOut();
			}
		}

		if (!this.$onemapWrapper.hasClass('open-map'))
			this.$onemapWrapper.addClass('open-map');

		if (!$('.trees-count').hasClass('open-map'))
			$('.trees-count').addClass('open-map');

		if (!$('.header').hasClass('open-map'))
			$('.header').addClass('open-map');
		
		console.log(document.getElementById("announcement"));
		
		if (document.getElementById("announcement") != null) {
			document.getElementById("announcement").style.display = "block";
			$('html, body').animate({
				scrollTop: $('.site-header__announcement').offset().top
			}, 'slow');
		}
		else {
			$('html, body').animate({
				scrollTop: $('.map-filter-wrapper').offset().top
			}, 'slow');
		}

	}

	parrallaxBackground(e) {
		let scrolled = $(window).scrollTop(),
			$parallaxEle = $('.map--banner'),
			depth = $parallaxEle.data('depth'),
			movement = -(scrolled * depth),
			backgroundPosition = `${movement}px`;

		$parallaxEle.css({
			'background-position-y': backgroundPosition
		});
		if(scrolled > 0) {
		}
	}
}
