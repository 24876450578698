'use strict';

import $ from 'jquery';
import 'slick-carousel';
import { assignIDForAria } from '_helpers';

export default class TreeCarousel {
	constructor() {

			let $treeCarousel = $('.tree-carousel'),
				$treeCarouselItem = $('.tree-carousel--item'),
				$header = $('.tree-carousel--header', $treeCarousel),
				$slider = $('.tree-carousel--slider', $treeCarousel);

			/*
			* Add Slick buttons into the header
			*/

			let $prevBtn = $('<button class="slick-prev btn-carousel slick-arrow js-tree-next" type="button"><span class="visuallyHidden">Previous Slide</span><i class="icon icon-left"></i></button>'),
			$nextBtn = $('<button class="slick-next btn-carousel slick-arrow js-tree-next" type="button"><span class="visuallyHidden">Next Slide</span><i class="icon icon-right"></i></button>'),
			$btnGroup = $('<div class="slick-btn-group"></div>');

			$btnGroup.append($prevBtn).append($nextBtn);

			$header.append($btnGroup);

			$slider.slick({
				arrows: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				draggable: false,
				swipe: false,
				dots: false,
				cssEase: 'cubic-bezier(.78, 0, .22, 1)',
				prevArrow: $prevBtn,
				nextArrow: $nextBtn,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							draggable: true,
							swipe: true
						}
					},
					{
						breakpoint: 520,
						settings: {
							slidesToShow: 1,
							draggable: true,
							swipe: true
						}
					}
				]
			});

			assignIDForAria($treeCarouselItem);


	}
}
