'use strict';

import $ from 'jquery';
import TreeInfo from '../../molecules/tree-info/tree-info';
import { checkIDInLocalStorage } from '_helpers';
import HugTree from '../../atoms/hug-tree/hug-tree';

export default class TreeOfMonth {
  constructor() {

    let $treeOfMonth = $('.tree-of-month'),
      $treeWrapper = $('.tree--wrapper', $treeOfMonth),
      $locateTree = $('.locate-tree', $treeWrapper),
      $hugWrapper = $('.share--wrapper__hug', $treeOfMonth),
      $hugTree = $('.hug__tree', $hugWrapper),
      $hugTreeIcon = $('.icon', $hugWrapper),
      $numberOfHugs = $('.number', $hugWrapper),
      treeid = $treeOfMonth.data('treeid'),
      url = $treeOfMonth.data('counthugsurl'),
      addhugsurl = $treeOfMonth.data('addhugsurl'),
      removehugsurl = $treeOfMonth.data('removehugsurl'),
      treeIDStorage = 'huggedTrees';

    window.treeIDStorage = treeIDStorage;

    const treeIDArr = JSON.parse(localStorage.getItem(treeIDStorage)) || [];

    new TreeInfo(treeid);

    let hash = window.location.hash;
    let target;

    if (window.innerWidth > 767) {
      target = $('#tree-of-month').offset().top - 1300
    } else {
      target = $('#tree-of-month').offset().top - 1800
    }

    if (hash == '#tree-of-month') {
      $('html, body').animate({
        scrollTop: target
      }, 1000);
    }

    let hugTree = new HugTree($hugTree, treeid, addhugsurl, removehugsurl);

    var json = {
      TreeId: treeid
    }

    $.ajax({
      method: 'POST',
      url,
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(json)
    }).done((response) => {

      let localStorageTreeID = JSON.parse(localStorage.getItem(treeIDStorage));

      // check if tree id is in localStorage
      if (checkIDInLocalStorage(treeid)) {
        // $hugTreeIcon.removeClass('icon-heart-line dislike').addClass('icon-heart animate');
      } else {
        // treeIDArr.push(treeid);
        // localStorage.setItem('treeids', JSON.stringify(treeIDArr));
      }

      $numberOfHugs.html(response.Hugs);
    });
  }
}
