'use strict';

import $ from 'jquery';

export default class InfoPopup {
  constructor() {

    let $popup = $('.info-popup');
    let $closeBtn = $popup.find('.info-popup__close, .icon-close');


    $closeBtn.on('click', (e) => {
      e.preventDefault();

      this.closePopup();

    });


    this.$popup = $popup;
    this.showNotification = true; // default as true

    // Use localStorage to check whether the notification is new or old
    // if (typeof (Storage) !== "undefined") {

    //     let pubDate = $popup.attr('data-pubdate'), // get the notification published date
    //         notificationPublished = new Date(pubDate),
    //         notificationPublishedMs = notificationPublished.getTime(); // notification pusblished time in milliseconds

    //     if (localStorage.getItem("notification_trees") === null) { // This will be true if the user is new to the site or the user cleared cache
    //         console.log('callliiinggg');

    //         localStorage.notification_trees = notificationPublishedMs; // set local storage value
    //     } else { // the below code will run if repeated user
    //         console.log('callliiinggg22');
    //         // compare existing localStorage value with notification published time
    //         if (localStorage.notification_trees != notificationPublishedMs) { // if the notification published time is different from localStorage
    //             this.showNotification = true;
    //             localStorage.notification_trees = notificationPublishedMs; // override the localStorage value
    //         } else {
    //             this.showNotification = false;
    //         }
    //     }
    // }


    // // show hide notification based on localStorage value
    // if (this.showNotification) {
    //     this.$popup.show();
    // }

  }

  closePopup() {

    this.$popup.hide();

  }

}
