// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import objectFitImages from 'object-fit-images';
import 'fancybox';
import Cookies from 'js-cookie';
import Emitter from 'tiny-emitter';
import Bloodhound from 'bloodhound-js';
import 'typeahead.bundle.js';
import ScrollReveal from 'scrollreveal';
import 'lazysizes';
import 'lazy-bgset';
import 'lazysizes/plugins/respimg/ls.respimg.min.js';


import SocialShare from 'atoms/social-share/social-share';
import ErrorPopup from 'atoms/error-popup/error-popup';
import FlagTree from 'atoms/flag-tree/flag-tree';
import TreeDetail from 'atoms/tree-detail/tree-detail';


import TextAccordion from 'molecules/text-accordion/text-accordion';
import ContributeForm from 'molecules/contribute-form/contribute-form';
import TreeInfoItem from 'molecules/tree-info-item/tree-info-item';
import EventsSlide from 'molecules/events-slide/events-slide';
import DidYouKnowSlide from 'molecules/did-you-know-slide/did-you-know-slide';
import SecondaryNav from 'molecules/secondary-nav/secondary-nav';
import TreeCounter from 'molecules/tree-counter/tree-counter';
import MapLayerFilter from 'molecules/map-tools/map-tools';
import VipTreeForm from 'molecules/vip-tree-form/vip-tree-form';
import CustomAlertBox from 'molecules/custom-alert-box/custom-alert-box';

import SearchWrapper from 'organisms/search-wrapper/search-wrapper';
import CarouselWrapper from 'organisms/carousel-wrapper/carousel-wrapper';
import StatisticsWrapper from 'organisms/statistics-wrapper/statistics-wrapper';
import MapWrapper from 'organisms/map-wrapper/map-wrapper';
import Onemap from 'organisms/onemap/onemap';
import MapContribute from 'organisms/map-contribute/map-contribute';
import SiteFooter from 'organisms/site-footer/site-footer';
import FloweringTreeCarousel from 'organisms/whats-happening/whats-happening';
import TreeOfMonth from 'organisms/tree-of-month/tree-of-month';
import SiteHeader from 'organisms/site-header/site-header';
import MapContainer from 'organisms/map-container/map-container';
import TreeCarousel from 'organisms/tree-carousel/tree-carousel';
import StandardContent from 'organisms/standard-content/standard-content';
import TreeCounterDisplay from 'organisms/tree-counter-display/tree-counter-display';
import InfoPopup from 'organisms/info-popup/info-popup';
import PinEntry from 'organisms/pin-entry/pin-entry';


$(() => {

  objectFitImages();

  // change this truth/false to freeze/unfreeze functions accordingly
  window.freezeSite = false;

  //read cookie for accordion
  if (Cookies.get('accordionState') == 'open') {
    $('.exploreMap').addClass('open');
    $('.accordion-text', '.exploreMap').slideDown(300);
  }

  document.addEventListener('lazybeforeunveil', (e) => {
    let $bgSrc = $(e.target).data('bg');
    if ($bgSrc) {
      $(e.target).css({
        'background-image': 'url(\'' + $bgSrc + '\')'
      });
    }
  });

  window.mapLayers = {
    searchLayer: 'https://services6.arcgis.com/s5gdswleLl0QthYa/arcgis/rest/services/PTM_Search/FeatureServer/2'
  };

  //Tree ID Array for localStorage
  window.emitter = new Emitter();
  window.sr = ScrollReveal({
    duration: 1000,
    distance: '60px',
    viewFactor: 0
  });

  new CustomAlertBox();

  new VipTreeForm();

  new SearchWrapper();

  new CarouselWrapper();

  new StatisticsWrapper();

  new TextAccordion();

  new SiteFooter();

  new SiteHeader();

  new SocialShare();

  if ($('.pin-entry').length){
    new PinEntry();
  }

  if ($('.map--container').length) {

    new MapWrapper();

    new Onemap();

    new MapContribute();

    new ContributeForm();

    new MapLayerFilter();

    new FlagTree();

    new TreeDetail();
  }

  if ($('.whats-happening').length) {
    new FloweringTreeCarousel();
  }

  if ($('.tree-of-month').length) {
    new TreeOfMonth();
  }

  if ($('.events--wrapper').length) {
    new EventsSlide();
  }

  if ($('.did-you-know--slider').length) {
    new DidYouKnowSlide();
  }

  if ($('.map--container').length) {
    new MapContainer();
  }

  if ($('.secondary--nav-wrapper').length) {
    new SecondaryNav();
  }

  if ($('.tree-info-wrapper').length) {
    new TreeInfoItem();
  }

  if ($('.tree-carousel').length) {
    new TreeCarousel();
  }

  if ($('.standard--content').length) {
    new StandardContent();
  }

  if ($('.tree-counter-display').length) {
    new TreeCounterDisplay();
  }

  if ($('.trees-count').length) {
    new TreeCounter();
  }

  if ($('.info-popup').length) {
    new InfoPopup();
  }

  new ErrorPopup(false);



});
