
'use strict';
import $ from 'jquery';
import { addClass, removeClass } from '_helpers';

export default class MapLayerFilter {
  constructor() {

	let $infoButton = $('.js-info-button'),
		$aboutMap = $('.about-map'),
		$okButton = $('.btn--ok');

	$infoButton.on('click', (e) => {
		addClass($aboutMap, 'show-info');
	});

	$okButton.on('click', (e) => {
		removeClass($aboutMap, 'show-info');
	});

	// $('#onemap').on('click', (e)=> {
	// 	$('.basemap-change-button__information').fadeOut();
	// });

	$('.basemap-change-button__information').on('click', (e)=> {
		e.stopPropagation();
	});

	$('.icon-close').on('click', (e)=> {
		$('.basemap-change-button__information').fadeOut();
	});

	$('.basemap-change-button').on('click', (e)=> {
		$('.basemap-change-button__information').fadeOut();
	});
  }
}
