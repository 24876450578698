'use strict';

import $ from 'jquery';

export default class FlagTree {
  constructor() {

    let $flagTree = $('.flag-tree'),
      endpoint = $flagTree.data('endpoint'),
      $form = $('.flag-tree__form'),
      $textarea = $('textarea', $form),
      $submitBtn = $('button[type="submit"]', $form),
      $close = $('.flag-tree__close');

    $close.click(function (e) {
      //$('.onemap-wrapper .overlay').addClass('close');
      e.preventDefault();

      window.emitter.emit('openFlagTreeForm', false);

    });


    $submitBtn.click(function (e) {
      e.preventDefault();
      let comment = $textarea.val();
      let EYTreeId = $('.onemap-wrapper').data('ey-tree-id');

      if (!comment.length) {
        // show error

      } else {

        $.ajax({
          method: 'POST',
          url: endpoint,
          data: {
            Comment: comment,
            TreeId: EYTreeId
          },
          success: function (data) {
            // console.log(data);
            if (data.Reported) {
              // callback for successful
              
              
              alert('Reported successfully');

              window.emitter.emit('openFlagTreeForm', false);

              //remove the selected leaflet
              $('.marked-icon').remove();
              
              //close tree detail after report success
              $('.map-details-wrapper .info-buttons .close').trigger('click');
              

            } else {

              // show error
              showError('An error occurred');
              window.emitter.emit('openFlagTreeForm', false);
              //location.replace("http://localhost:3000/");
 
            }
          },
          error: function (error) {
            console.log(error);
            
            showError('An error occurred');

          }
        })

      }

    })


    // click outside to close

    window.emitter.on('openFlagTreeForm', function (bool) {

      if (bool) {
        $flagTree.addClass('show');
      } else {
        $flagTree.removeClass('show');
      }

    })


    // $(document).mouseup(function (e) {
    // 	var container = $form;

    // 	// if the target of the click isn't the container nor a descendant of the container
    // 	if (!container.is(e.target) && container.has(e.target).length === 0) {
    // 		// close the form
    // 		window.emitter.emit('openFlagTreeForm', false);
    // 	}
    // });

    /** HELPER FUNCTIONS **/
    function showError(errorMessage) {
      alert(errorMessage);
    }

  }
}
