import $ from 'jquery';
import 'slick-carousel';

'use strict';

export default class CarouselWrapper {
	constructor() {
		let $carouselWrapper = $('.carousel-wrapper'),
			$textCarousel = $('.carousel-text', $carouselWrapper),
			$imageCarousel = $('.carousel-images', $carouselWrapper);

		$textCarousel.slick({
			arrows: true,
			asNavFor: '.carousel-images',
			fade: true,
			speed: 500,
			swipe: false,
			arrows: false
		});

		$imageCarousel.slick({
			// autoplay: true,
			// autoplaySpeed: 3000,
			infinite: true,
			arrows: false,
			asNavFor: '.carousel-text',
			dots: true,
			speed: 500,
			pauseOnHover: true,
			pauseOnDotsHover: true,
			variableWidth: true,
			slidesToShow: 1,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						centerMode: true
					}
				}
			]
		});
	}
}
