'use strict';

import $ from 'jquery';
import 'slick-carousel';
import { assignIDForAria } from '_helpers';

export default class EventsSlide {
	constructor() {

		let $eventsWrapper = $('.events--wrapper'),
			$eventsSlide = $('.events--slide', $eventsWrapper),
			$eventsDate = $('.date', $eventsSlide),
			$images = $('.image', $eventsSlide);

		// Add slick buttons to the slide
		let $prevBtn = '<button class="slick-prev btn-carousel" type="button"><span class="visuallyHidden">Previous Slide</span><i class="icon-left"></i></button>',
			$nextBtn = '<button class="slick-next btn-carousel" type="button"><span class="visuallyHidden">Next Slide</span><i class="icon-right"></i></button>';

		let $sliderBtnGroup = $('<div class="events__slider__buttons"></div>');
		$('.events').append($sliderBtnGroup);

		$eventsWrapper.slick({
			lazyLoad: 'ondemand',
			prevArrow: $prevBtn,
			nextArrow: $nextBtn,
			centerMode: false,
			dots: false,
			arrows: true,
			draggable: false,
			adaptiveHeight: true,
			autoplay: true,
			autoplaySpeed: 5000,
			appendArrows: $('.events__slider__buttons'),
			responsive: [
				{
					breakpoint: 768,
					settings: {
						dots: false,
						draggable: true,
						swipe: true,
						pauseOnDotsHover: true
					}
				}
			]
		});

		$images.map((index, image) => {
			let $this = $(image),
				imageSource = $this.data('lazy');

			$this.attr('src', '');
			$this.css('background-image','url("'+imageSource+'")'); //replace with background instead
			$this.css('background-size','cover');
			$this.css('background-position','center');
			$this.css('background-repeat','no-repeat');
		});


		$('.slick-prev').bind('click touchstart', () => {
			$('.events--wrapper').slick('slickPrev');
		});

		$('.slick-next').bind('click touchstart', () => {
			$('.events--wrapper').slick('slickNext');
		});

		assignIDForAria($eventsSlide);

	}
}
