'use strict';

export default class CustomAlertBox {
  constructor() {

    $('.copyButton').on('click', (e) => {

      console.log(($('.shareUrl').text()));

      copyStringToClipboard($('.shareUrl').text());

	  alert('Copied!');
	  $('.custom-alert-box').addClass('hide');
    });

    $('.icon-alert-box').on('click', (e) => {
      $('.custom-alert-box').addClass('hide');
	});
	
	function copyStringToClipboard(str) {
		// Create new element
		var el = document.createElement("textarea");
		// Set value (string to be copied)
		el.value = str;
		// Set non-editable to avoid focus and move outside of view
		el.setAttribute("readonly", "");
		el.style = { position: "absolute", left: "-9999px" };
		document.body.appendChild(el);
		// Select text inside element
		el.select();
		// Copy text to clipboard
		document.execCommand("copy");
		// Remove temporary element
		document.body.removeChild(el);
	}
  }
}
