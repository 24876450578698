import $ from 'jquery';
import 'leaflet';

'use strict';

export default class MapWrapper {
	constructor() {
		let $mapWrapper = $('.map-wrapper'),
			$mapDetailsWrapper = $('.map-details-wrapper', $mapWrapper);

		$('.map-details-nav ul li a').on('click', function(e){
			e.preventDefault();

			$('.map-details-nav ul li a').removeClass('active');
			$(this).addClass('active');

			var href = $(this).attr('href');

			let marginTop = parseInt($(href).css('marginTop'));
			let scrollTop = $(href)[0].offsetTop - marginTop;

			$('.details-scrollable').animate({scrollTop: scrollTop}, 1000);
		});

		$('.info-buttons button.expand').on('click', function(){
			let _this = $(this);

			if($mapDetailsWrapper.hasClass('openPartial')){
				_this.html('<i class="icon icon-compress"></i>');
				_this.addClass('active');
				$mapDetailsWrapper.removeClass('openPartial');
				$mapDetailsWrapper.addClass('openFull');
			}

			else if($mapDetailsWrapper.hasClass('openFull')){
				_this.html('<i class="icon icon-expand"></i>');
				_this.removeClass('active');
				$mapDetailsWrapper.removeClass('openFull');
				$mapDetailsWrapper.addClass('openPartial');
			}
		});

	}
}
