import $ from 'jquery';
import doT from 'dot';
import twbsPagination from 'twbs-pagination';

'use strict';

export default class ContributeForm {
	constructor() {

		const MAX_CHAR = 140;

		let
			$closeForm = $('.js-close-form', $form),
			$form = $('#contributionForm'),
			$contributeForm = $('#contributionForm'),
			$submitMessageButton = $('.form-submit-border .btn', $form);
	}

	closeForm($contributeForm) {
		$('.contribute-form-wrapper').removeClass('open');
	}

	addClass(ele, className) {
		ele.addClass(className);
	}

	removeClass(ele, className) {
		ele.removeClass(className);
	}
}
