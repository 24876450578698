import $ from 'jquery';
import typeahead from 'typeahead.js';
import Bloodhound from 'bloodhound-js';
import Onemap from '../../organisms/onemap/onemap';
import { addClass, removeClass, mapLayers, getSearchState, setSearchState } from '_helpers';

('use strict');

export default class MapFilter {
	constructor() {
		let $mapSelect = $('#mapSelect'),
			$mapSearch = $('#mapSearch'),
			$searchBtn = $('#searchBtn'),
			$mapOptions = $('option', $mapSelect),
			$mapInfoController = $('.info-buttons'),
			$close = $('.close', $mapInfoController),
			$mapFilterBtns = $('.mapFilterBtns');

		let selectedOption = $mapSelect.find(':selected').val();
		let generatedLiMarkup = '';

		//automatically add li item to html for each of the option available in select. this li is reflected in the ui for drop down box.
		$mapOptions.each(function () {
			let optionValue = $(this).val();
			generatedLiMarkup += `<li>${optionValue}</li>`;
		});

		//automatically add ul with the li created. this ul is styled as a drop down button for the search bar
		let customMarkup = `<div class="customSelect default"><span>${selectedOption}</span><ul class="customMapOptions">${generatedLiMarkup}</ul></div>`;

		//place the markup next to the select field.
		$mapSelect.after(customMarkup);

		//find the DOM of the search button created before
		let $customSelect = $('.customSelect'),
			$customOptions = $('.customMapOptions li', $customSelect);

		//Search by: drop down
		// if iPad/iPhone
		if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
			$customSelect
				.on('click', function (e) {
					// button click action
					e.stopPropagation();
					$customSelect.toggleClass('active');
					//add active class to show drop down list
				});

		}
		else {
			$customSelect.on('click', function () {
				$customSelect.toggleClass('active');
			});

		}

		//If clicked outside search by dropdown list, close dropdown
		$(document).on('click touchstart', function (e) {
			var container = $customSelect;

			// if the target of the click isn't the container nor a descendant of the container
			if (
				!container.is(e.target) &&
				container.has(e.target).length === 0
			) {
				container.removeClass('active'); //close drop down
			}
		});

		// Type Ahead plugin
		let substringMatcher = strs => {
			return function findMatches(q, cb) {
				let matches, substringRegex;

				// an array that will be populated with substring matches
				matches = [];

				// regex used to determine if a string contains the substring `q`
				substringRegex = new RegExp(q, 'i');

				// iterate through the pool of strings and for any string that
				// contains the substring `q`, add it to the `matches` array
				$.each(strs, function (i, str) {
					if (substringRegex.test(str)) {
						matches.push(str);
					}
				});

				cb(matches);//return matches array
			};
		};

		let speciesSuggestions = [],
			myTreeSuggestions = [];

		$.each(window.speciesName, function (idx, element) { //iterate over window.speciesName array in base
			if ($.inArray(element, speciesSuggestions) === -1) //if element  is not in speciesSuggestion add element into suggestion
				speciesSuggestions.push(element);
		});

		let mapTypeAheadSpecies = () => {
			$mapSearch.typeahead(
				{
					hint: false,
					hightlight: false,
					minLength: 1
				},
				{
					name: 'mapSearch',
					source: substringMatcher(speciesSuggestions),
					limit: 10
				}
			);
		};

		$mapFilterBtns.on('click', (e) => {
			var $this = $(e.currentTarget),
					value = $this.data('value'),
					placeholder = $this.data('placeholder');

			// console.log("value", value)

			$mapFilterBtns.removeClass('is-active');
			$this.addClass('is-active');

			this.clearTypeaheadSelection($mapSearch);
			$mapSearch.attr('placeholder', placeholder);
			$mapSearch.typeahead('destroy');
			$mapSearch.focus();

			if (value == 'species') {
				mapTypeAheadSpecies();
			} else if (value == 'location') {
				mapTypeAheadLocation();
			} else if (value == 'name' || value == 'email' || value == 'tag') {
				myTreeSuggestions = [];
				mapTypeAheadMyTrees(value);
			}

		});

		let	query = L.esri.query({ url:mapLayers.userPlantedLayer });
		var query2 = L.esri.query({ url: "https://apsdadvedgwap02.azurewebsites.net/ptmapi/TreeInformationApi/GetPlottedByNames" });
		const dataUrl = "/api/ptmapi/mockData.json";

		//repopulate maptypeahead whenever customSelect myTrees option
		let rePopulateMapTypeAheadMyTrees = () => {
			$mapSearch.typeahead(
				{
					hint: false,
					hightlight: false,
					minLength: 1
				},
				{
					name: 'mapSearch',
					source: substringMatcher(myTreeSuggestions),
					limit: 10
				}
			);
		}

		let mapTypeAheadMyTrees = (searchType) => {
			let newPlot = $('iframe')[0].contentWindow.newPlot

			if (!myTreeSuggestions.length || newPlot){
				newPlot = false;

				fetch('/ptmapi/TreeInformationApi/GetPlottedByNames').then(function (response) {
					if (response.status !== 200) {
						console.log('Looks like there was a problem. Status Code: ' + response.status);
						return;
					}

					// Examine the text in the response
					response.json().then(function (data) {
						data.forEach(function (d) {
							var name = d.PlottedByName;
							if (name && searchType == 'name') {
								if (!myTreeSuggestions.includes(name.toUpperCase().trim())) {
									myTreeSuggestions.push(name.toUpperCase().trim());
								}
							}
						});
					});
				}).catch(function (err) {
					console.log('Fetch Error :-S', err);
				});
				
				var authToken = window.localStorage.getItem('authToken');

				query.token(authToken).run((err, featureCollection, response) => {

					if (response){

						const features = response.features;
						features.forEach(ele => {
							const queryName = ele.attributes.Name,
								queryEmail = ele.attributes.Email,
								queryKeyword = ele.attributes.UserKeywords; //['#test', '#tester', '#test'];
							//	queryTreemail = ele.attributes.Treemail;
								// console.log(queryKeyword);
								// if (queryKeyword){
								// 	console.log(queryKeyword);
								// }
								//ele.attributes.Keywords; //add keyword attribute here

							/* if (queryName && searchType == 'name'){
								if (!myTreeSuggestions.includes(queryName.toUpperCase().trim())){
									myTreeSuggestions.push(queryName.toUpperCase().trim());
								}
							} */

							// if (queryEmail && searchType == 'email'){
							// 	if (!myTreeSuggestions.includes(queryEmail.toUpperCase().trim())){
							// 		myTreeSuggestions.push(queryEmail.toUpperCase().trim());
							// 	}
							// }

							if (queryKeyword && searchType == 'tag'){
								queryKeyword.split(/[ ,]+/).forEach((value, i)=>{
									value = value.trim();
									if (value[0] === '#') {
										if (!myTreeSuggestions.includes(value.toUpperCase().trim())) {
											myTreeSuggestions.push(value.toUpperCase().trim());
										}
									}
								});
							}

							// if (queryTreemail && searchType == 'treemail'){
							// 	if (!myTreeSuggestions.includes(queryTreemail.toUpperCase().trim())){
							// 		myTreeSuggestions.push(queryTreemail.toUpperCase().trim());
							// 	}
							// }


						});
					}
				});
			}
			//console.log(myTreesSuggestion);
			$mapSearch.typeahead(
				{
					hint: false,
					hightlight: false,
					minLength: 1
				},
				{
					name: 'mapSearch',
					source: substringMatcher(myTreeSuggestions),
					limit: 10
				}
			);
		}

		let mapTypeAheadLocation = () => {
			let locationName = new Bloodhound({
				datumTokenizer: datum => {
					console.log(Bloodhound.tokenizers.whitespace(datum.address));
					return Bloodhound.tokenizers.whitespace(datum.address);
				},
				queryTokenizer: Bloodhound.tokenizers.whitespace,
				remote: {
					//url:'https://developers.onemap.sg/commonapi/search?searchVal=%QUERY&returnGeom=Y&getAddrDetails=Y',
					url: 'https://www.onemap.gov.sg/api/common/elastic/search?searchVal=%QUERY&returnGeom=Y&getAddrDetails=Y',
					wildcard: '%QUERY',
					filter: data => {

						return $.map(data.results, result => {
							//console.log(result);
							let location = {};
							location.address = result.ADDRESS.toUpperCase();
							location.address = result.ADDRESS.replace(
								',SINGAPORE',
								', Singapore'
							);
							location.address = result.ADDRESS.replace(
								'0, SINGAPORE',
								'SINGAPORE'
							);

							location.building_name = result.BUILDING.toUpperCase();

							if (
								location.building_name == 'NIL' ||
								location.building_name == 'NA'
							) {
								location.building_name = result.SEARCHVAL;
							}

							location.lat = result.LATITUDE;
							location.lon = result.LONGITUDE;

							return {
								address: location.address,
								building: location.building_name,
								lat: location.lat,
								lon: location.lon
							};
						});
					}
				}
			});

			locationName.initialize();

			$mapSearch.typeahead(
				{
					minLength: 1,
					hint: false
				},
				{
					name: 'address',
					displayKey: 'address',
					limit: 20,
					source: locationName.ttAdapter(),
					templates: {
						suggestion: data => {
							return (
								'<div class="location-result"><p><strong>' +
								data.building +
								'</strong></p><p>' +
								data.address +
								'</p></div>'
							);
						}
					}
				}
			);
		};

		// let $selectedText = $('span', $customSelect).text(); //the selected search option
		let $selectedText = $('.mapFilterBtns.is-active').data('value'); //the selected search option

		if ($selectedText == 'location') { //default typeahead function
			mapTypeAheadLocation();
		}

		//choosing the correct type suggest library
		$customOptions.bind('click touchstart', ($ele, index) => {
			setSearchState(false);
			let value = $ele.target.textContent,
				$selectedValue = $('span', $customSelect);

			if (value == 'species') {
				this.clearTypeaheadSelection($mapSearch);
				$mapSearch.typeahead('destroy');
				mapTypeAheadSpecies();
				$mapSearch.focus();
			}
			else if (value == 'location') {
				this.clearTypeaheadSelection($mapSearch);
				$mapSearch.typeahead('destroy');
				mapTypeAheadLocation();
				$mapSearch.focus();

			}else if (value == 'myTrees'){ //remove any type suggestion library if user or email is selected
				this.clearTypeaheadSelection($mapSearch);
				$mapSearch.typeahead('destroy');
				mapTypeAheadMyTrees();
				$mapSearch.focus();
			}

			$selectedValue.text(value);

			$mapSelect.val(value).change();
		});

		$searchBtn.on('click', e => {
			e.preventDefault();
			const $icon = $('i', $searchBtn);
			// console.log($icon);
			if ($icon.hasClass('icon-close')){
				this.clearTypeaheadSelection($mapSearch);
				window.emitter.emit('clearSearch');
				$close.trigger('click');
				setSearchState(false);
			}
		});

		// $(document).on('click', (e) => {
		// 	let $this = $(e.target);

		// 	if(!$this.is($mapSearch)) {
		// 		let $icon = $searchBtn.find('.icon');
		// 		this.customSelectRemoveClass($customSelect);
		// 		if(!$icon.hasClass('icon-search')) {
		// 			removeClass($icon, 'icon-close');
		// 			addClass($icon, 'icon-search');
		// 		}
		// 	} else {
		// 		if($customSelect.hasClass('active')) {
		// 			this.customSelectRemoveClass($customSelect);
		// 		}
		// 	}
		// });
		$mapSearch.on('change', e =>{
			let $this = $(e.target);

			if ($this.val().length == 0){
				this.clearTypeaheadSelection($mapSearch);
				window.emitter.emit('clearSearch');
				$close.trigger('click');
				setSearchState(false);
			}
		});

		//on focus change icon to X
		$mapSearch.on('focus', e => {

			// let $this = $(e.target),
			// 	$icon = $searchBtn.find('.icon');

			// if ($this.val().length >= 1) {
			// 	removeClass($icon, 'icon-search');
			// 	addClass($icon, 'icon-close');
			// }
			// else {
			// 	removeClass($icon, 'icon-close');
			// 	addClass($icon, 'icon-search');
			// }
			// searchState(false);
		});
	}

	clearTypeaheadSelection($mapSearch) {
		$mapSearch.typeahead('val', '');
	}

	customSelectRemoveClass($customSelect) {
		$customSelect.removeClass('active');
	}
}



