'use strict';

import $ from 'jquery';
// import 'leaflet';
// import 'leaflet.markercluster';
// import 'leaflet-editable';
// import 'leaflet-draw';
// import 'esri-leaflet';
// import 'esri-leaflet-cluster';

window.$ = $;

export default class PlotTreeForm {
	constructor(latlng, zoomLvl) {
		let $closeBtn = $('.plot-tree-form__close-button');

		//Event Handlers ==================================================

		// allow form to close
		$closeBtn.click(function () {

			window.emitter.emit('closeForm', latlng, zoomLvl);

		});

	}

}
