
'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import 'scrollreveal';

export default class TreeInfoItem {
	constructor() {

		let matchHeight = $('.tree-info');
		$(window).on('resize', () => {
			matchHeight.matchHeight({
				_maintainScroll: true
			});
		}).trigger('resize');

		setTimeout(() => {

			sr.reveal('.tree-info-image', {
				origin: 'left',
				reset: true,
				afterReveal: () => {
					matchHeight.matchHeight._maintainScroll = true
				}
			});

			sr.reveal('.tree-info-text', {
				origin: 'right',
				reset: true,
				afterReveal: () => {
					matchHeight.matchHeight._maintainScroll = true
				}
			});
		}, 1000);
	}
}
