'use strict';

import $ from 'jquery';

export default class SocialShare {
  constructor() {
    let shareUrl = document.location.href,
    siteHeader = $('.site--header__top'),
    shareFb = $('.social--icon.facebook', siteHeader),
    shareTwitter = $('.social--icon.twitter', siteHeader);

    shareUrl = stripTrailingSlash(shareUrl);

    shareFb.attr('href', 'https://www.facebook.com/sharer.php?u=' + shareUrl);
    shareTwitter.attr('href', "https://twitter.com/intent/tweet?url=" + shareUrl + '&text=Trees.SG');

    function stripTrailingSlash(str) {
      if(str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
      }
      return str;
    }

  }
}
