
import $ from 'jquery';
import twbsPagination from 'twbs-pagination';
import moment from 'moment';

import {
  addClass,
  removeClass,
  validateEmail,
  validateName,
  triggerFirstPageClick,
  escapeString,
  mapLayers,
  selectedMarker_global,
  setSearchState
} from '_helpers';

import 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-editable';
import 'leaflet-draw';
import 'esri-leaflet';
import 'esri-leaflet-cluster';
import doT from 'dot';
import enquire from 'enquire.js';
import Bloodhound from 'bloodhound-js';
import MapFilter from '../../molecules/map-filter/map-filter';
import { geoJSON, latLng, featureGroup, icon } from 'leaflet';
import HugTree from '../../atoms/hug-tree/hug-tree';
import ErrorPopup from '../../atoms/error-popup/error-popup';
import PlotTreeButton from '../../atoms/plot-tree-button/plot-tree-button';

('use strict');

export default class Onemap {
  constructor() {
    window.selectedMarker;
    window.treeClicked = false;

    let $oneMapWrapper = $('.onemap-wrapper'),
      treeIdParameter = $oneMapWrapper.data('parameter'),
      $mapDetailsWrapper = $('.map-details-wrapper', $oneMapWrapper),
      $infoTemplateHolder = $('.map-information-wrapper .container', $mapDetailsWrapper),
      $messagesTemplateHolder = $('.message-wrapper', $mapDetailsWrapper),
      $noMessage = $('.no-message'),
      $messagePagination = $('.message-pagination', $mapDetailsWrapper),
      $scrollable = $('.details-scrollable', $mapDetailsWrapper),
      $toggleMap = $('#mapBaseToggle', $oneMapWrapper),
      $pagination = $('#pagination'),
      $loadingMessage = $('.load-message'),
      $loadingDiv = $('.loading'),
      $loadingContent = $('.loading-content span', $loadingDiv),
      divName = 'onemap',
      $mapSearch = $('#mapSearch'),
      $notices = $('.notices'),
      $aboutMap = $('.about-map'),
      $closeNotice = $('.js-close-notice'),
      $speciesError = $('.species-error'),
      $searchMessageInput = $('#searchMessage'),
      $messageFormWrapper = $('.contribute-form-wrapper'),
      $contributeWrapper = $('.map-contribute-wrapper'),
      $searchMessage = $('.search-message', $contributeWrapper),
      $searchInput = $('.search-input', $searchMessage),
      $writeMessage = $('.write-message', $contributeWrapper),
      $search = $('.btn--search', $contributeWrapper),
      $closeSearch = $('.js-close-search', $contributeWrapper),
      $closeNotification = $('.js-close-notification'),
      $closeForm = $('.js-close-form'),
      map,
      basemap,
      googleSat,
      isTreeSearch = false,
      messagesApi = $('.contribute-form-wrapper').data('endpoint'),
      selectedActiveMarker,
      toggleSatelliteLayer = false,
      // Additional variables
      $mapInfoController = $('.info-buttons'),
      $mapTools = $('.map-tools-wrapper__top-right', $mapDetailsWrapper),
      $mapContainer = $('#onemap'),
      // map and info buttons
      $mapExpandBtn = $('.map-tools-wrapper__top-right button.expand'),
      $leafletControlContainer = $('.leaflet-control-container', $oneMapWrapper),
      $layerFilterButton = $('.layer-filter-button'),
      $infoButton = $('.js-info-button'),
      $basemapButton = $('.basemap-change-button'),
      $close = $('.close', $mapInfoController),
      $treeOfMonth = $('.tree-of-month'),
      $treeWrapper = $('.tree--wrapper', $treeOfMonth),
      $locateTree = $('.locate-tree', $treeWrapper);      

      this.GetTokenAuth();

      let authToken = window.localStorage.getItem('authToken');

      console.log('authToken: ' + authToken);

    const $searchBtn = $('#searchBtn');

    // Trigger the treemail-btn click event when user clicks on the plus icon to log new photos
    // since both open the same iframe
    // $(document).ajaxComplete(function() {
    // $('.show-treemail-form').on('click', function(){
    //   $("#treemail-btn").trigger('click');
    // });

    // $('.treemail-btn-new').on('click', function(){
    //   $("#treemail-btn").trigger('click');
    // });
    // });

    // $('.show-treemail-form').on('click', function(){
    //   $("#treemail-btn").trigger('click');
    // });

    // $('.treemail-btn-new').on('click', function(){
    //   $("#treemail-btn").trigger('click');
    // });

    let markerGeoJSONLayer = L.geoJSON(null, {
      pointToLayer: (geoJSON, layer) => {
        //instead of girthSize pass size
        let featureType = geoJSON.properties.Type,
          size = geoJSON.properties.SIZE,
          icon = this.getIconsUrl(featureType, size);
        if (!icon) {
          //console.log("icon is null")
          return
        }

        return L.marker([layer.lat, layer.lng], {
          icon: icon,
          riseOnHover: true
        });
      }
    });

    // for marker clusters
    const featureLayerOptions = {
      maxZoom: 20,
      simplifyFactor: 0,
      spiderfyOnMaxZoom: false,
      disableClusteringAtZoom: 18,
      showCoverageOnHover: false,
      iconCreateFunction: function (cluster) {
        let count = cluster.getChildCount();
        let childCount =
          count >= 10000
            ? (Math.round(count / 1000) * 100) / 100 + 'K'
            : count >= 1000
              ? Math.round(count / 100) / 10 + 'K'
              : count >= 100
                ? Math.round(count / 10) * 10
                : count < 100
                  ? Math.ceil(count / 10) * 10
                  : count;
        return L.divIcon({
          className: 'marker-cluster',
          html: '<div><span>' + childCount + '</span></div>'
        });
      }
    };

    const MAVEN_FIELDS = {
      EMAIL_SEARCH: 'Email',
      USER_SEARCH: 'Name',
      KEYWORD_SEARCH: 'UserKeywords',
      SPECIES_SEARCH: 'SPCS_COMMON_NM',
      GIRTH_SIZE: 'GRTH_SIZE',
      TREEMAIL_SEARCH: 'EYTreeID'
    };

    let $filterByTrees = $('.radio-map-filter');
    // for clustering, from leaflet.markercluster
    let markers = L.markerClusterGroup(featureLayerOptions);

    let treeConservationLayer, treeReplacementLayer, heritageRoadLayer;

    this.$mapDetailsWrapper = $mapDetailsWrapper;
    this.$infoTemplateHolder = $infoTemplateHolder;
    this.$messagesTemplateHolder = $messagesTemplateHolder;
    this.$noMessage = $noMessage;
    this.$messagePagination = $messagePagination;
    this.$scrollable = $scrollable;

    const mapInfoButtons = {
      $leafletControlContainer,
      $layerFilterButton,
      $infoButton,
      $basemapButton,
      $mapContainer
    };

    const ZOOM_18 = 18,
      ICONS_PATH = '/Cwp/assets/PTM/images/',
      ITEMS_PER_PAGE = 4;

    let center = L.bounds([1.56073, 104.11475], [1.16, 103.502]).getCenter();

    const MAP_FIELDS = ['OBJECTID', 'Type', 'ID', 'GRTH_SIZE'];

    const VIEW_LAYERS = {
      11: mapLayers.view_11,
      12: mapLayers.view_12,
      13: mapLayers.view_13,
      14: mapLayers.view_14,
      15: mapLayers.view_15,
      16: mapLayers.view_16,
      17: mapLayers.view_17,
      18: mapLayers.view_18
    };

    //view option layer object
    const VIEW_LAYERS_OPTIONS = {
      pointToLayer: this.roundOffTreeClusterNumbers,
      // each of the 41 features, each of which are green markers
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          let coordinates = layer.getLatLng();
          // zoom to the marker in the center when clicked on
          map.setView([coordinates.lat, coordinates.lng], map.getZoom() + 1);
        });
      },
      cacheLayers: true,
      token: authToken
    };

    const VIEW_HERITAGE_LAYERS_OPTIONS = {
      pointToLayer: this.roundOffHeritageClusterNumbers,
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          let coordinates = layer.getLatLng();
          map.setView([coordinates.lat, coordinates.lng], map.getZoom() + 1);
        });
      },
      cacheLayers: true,
      token: authToken
    };

    const VIEW_FLOWERING_LAYERS_OPTIONS = {
      pointToLayer: this.roundOffFloweringClusterNumbers,
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          let coordinates = layer.getLatLng();
          map.setView([coordinates.lat, coordinates.lng], map.getZoom() + 1);
        });
      },
      cacheLayers: true,
      token: authToken
    };

    const VIEW_USER_PLANTED_LAYERS_OPTIONS = {
      pointToLayer: this.roundOffUserPlantedClusterNumbers,
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          let coordinates = layer.getLatLng();
          map.setView([coordinates.lat, coordinates.lng], map.getZoom() + 1);
        });
      },
      cacheLayers: true,
      token: authToken
    };

    const VIEW_VIP_PLANTED_LAYERS_OPTIONS = {
      pointToLayer: this.roundOffVIPPlantedClusterNumbers,
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          let coordinates = layer.getLatLng();
          map.setView([coordinates.lat, coordinates.lng], map.getZoom() + 1);
        });
      },
      cacheLayers: true,
      token: authToken
    };

    let {
      trees_layer_view_11,
      trees_layer_view_12,
      trees_layer_view_13,
      trees_layer_view_14,
      trees_layer_view_15,
      trees_layer_view_16,
      trees_layer_view_17
    } = this.treesLayers(VIEW_LAYERS, VIEW_LAYERS_OPTIONS);

    let {
      heritage_layer_view_11,
      heritage_layer_view_12,
      heritage_layer_view_13,
      heritage_layer_view_14,
      heritage_layer_view_15,
      heritage_layer_view_16,
      heritage_layer_view_17
    } = this.heritageLayers(VIEW_LAYERS, VIEW_HERITAGE_LAYERS_OPTIONS);

    let {
      flowering_layer_view_11,
      flowering_layer_view_12,
      flowering_layer_view_13,
      flowering_layer_view_14,
      flowering_layer_view_15,
      flowering_layer_view_16,
      flowering_layer_view_17
    } = this.floweringLayers(VIEW_LAYERS, VIEW_FLOWERING_LAYERS_OPTIONS);

    let {
      user_planted_layer_view_11,
      user_planted_layer_view_12,
      user_planted_layer_view_13,
      user_planted_layer_view_14,
      user_planted_layer_view_15,
      user_planted_layer_view_16,
      user_planted_layer_view_17
    } = this.userPlantedLayers(VIEW_LAYERS, VIEW_USER_PLANTED_LAYERS_OPTIONS);



    let userPlottedLayerView18Options = {
      url: mapLayers.userPlantedLayer,
      pointToLayer: (geojson, latLng) => {
        let featureType = geojson.properties.Type || geojson.properties.Actual_Tree_Type;
        let icon = this.getIconsUrl(featureType, '');
        // girth is an empty string as it does not determine icon
        if (!icon) {
          //console.log("icon is null")
          return
        }
        let marker = L.marker(latLng, {
          icon: icon,
          riseOnHover: true
        });

        return marker;

      },

      onEachFeature: (feature, layer) => {
        layer.on('click', handleClick);
      },
      cacheLayers: true,
      token: authToken
    };

    let layerView18Options = {
      url: VIEW_LAYERS[18],
      pointToLayer: (geojson, latLng) => {
        let featureType = geojson.properties.Type,
          girth = geojson.properties.Girth,
          icon = this.getIconsUrl(featureType, girth);
        if (!icon) {
          //console.log("icon is null")
          return
        }
        let marker = L.marker(latLng, {
          icon: icon,
          riseOnHover: true
        });

        return marker;
      },
      onEachFeature: (feature, layer) => {
        layer.on('click', handleClick);
      },
      cacheLayers: true,
      token: authToken
    };

    let layer_view_18 = L.esri.featureLayer(layerView18Options),
      allTree_cluster_layer_view_18 = L.esri.Cluster.featureLayer(layerView18Options),
      heritage_cluster_layer_view_18 = L.esri.Cluster.featureLayer(layerView18Options).setWhere('Type=\'HERITAGE\''),
      flowering_cluster_layer_view_18 = L.esri.Cluster.featureLayer(layerView18Options).setWhere('Type=\'FLOWERING\''),
      user_plotted_layer_view_18 = L.esri.featureLayer(userPlottedLayerView18Options), //add Cluster option to cluster trees
      userLayer = L.esri.featureLayer(userPlottedLayerView18Options);

    let treeLayers = {
      11: trees_layer_view_11,
      12: trees_layer_view_12,
      13: trees_layer_view_13,
      14: trees_layer_view_14,
      15: trees_layer_view_15,
      16: trees_layer_view_16,
      17: trees_layer_view_17
    };

    let heritageLayers = {
      11: heritage_layer_view_11,
      12: heritage_layer_view_12,
      13: heritage_layer_view_13,
      14: heritage_layer_view_14,
      15: heritage_layer_view_15,
      16: heritage_layer_view_16,
      17: heritage_layer_view_17
    };

    let floweringLayers = {
      11: flowering_layer_view_11,
      12: flowering_layer_view_12,
      13: flowering_layer_view_13,
      14: flowering_layer_view_14,
      15: flowering_layer_view_15,
      16: flowering_layer_view_16,
      17: flowering_layer_view_17
    };

    let userPlantedLayers = {
      // each of these are the layers that are visible when the "User Planted Trees" radio button is clicked on the top right of the map, for when user zooms in from level 11 to 17
      11: user_planted_layer_view_11,
      12: user_planted_layer_view_12,
      13: user_planted_layer_view_13,
      14: user_planted_layer_view_14,
      15: user_planted_layer_view_15,
      16: user_planted_layer_view_16,
      17: user_planted_layer_view_17
    };


    let ZOOM_LEVELS = [11, 12, 13, 14, 15, 16, 17, 18];

    let userLayerOptions = {
      url: mapLayers.searchLayer,
      maxZoom: 20,
      simplifyFactor: 0,
      spiderfyOnMaxZoom: false,
      disableClusteringAtZoom: 18,
      showCoverageOnHover: false,
      // creating each icon for species layer
      iconCreateFunction: cluster => {
        let count = cluster.getChildCount();
        return L.divIcon({
          className: 'marker-cluster',
          html: '<div><span>' + count + '</span></div>'
        });
      },
      pointToLayer: (geojson, latLng) => {
        // let featureType = "FLOWERING"; //update this line if criteria for VIP is different

        let girth = geojson.properties.Girth,
          featureType = geojson.properties.Type,
          icon = this.getIconsUrl(featureType, girth);
        if (!icon) {
          //console.log("icon is null")
          return
        }
        return L.marker(latLng, {
          icon: icon,
          riseOnHover: true
        });
      },
      onEachFeature: (feature, layer) => {
        layer.on({
          click: handleClick
        });
      },
      token: authToken
    };

    let speciesLayerOptions = {
      url: mapLayers.searchLayer,
      maxZoom: 20,
      simplifyFactor: 0,
      spiderfyOnMaxZoom: false,
      disableClusteringAtZoom: 18,
      showCoverageOnHover: false,
      // creating each icon for species layer
      iconCreateFunction: cluster => {
        let count = cluster.getChildCount();

        let childCount =
          count >= 10000
            ? (Math.round(count / 1000) * 100) / 100 + 'K'
            : count >= 1000
              ? Math.round(count / 100) / 10 + 'K'
              : count >= 100
                ? Math.round(count / 10) * 10
                : count < 100
                  ? Math.ceil(count / 10) * 10
                  : count;
        return L.divIcon({
          className: 'marker-cluster',
          html: '<div><span>' + childCount + '</span></div>'
        });
      },
      pointToLayer: (geojson, latLng) => {
        let featureType = geojson.properties.Type,
          girth = geojson.properties.Girth,
          icon = this.getIconsUrl(featureType, girth);
        if (!icon) {
          //console.log("icon is null")
          return
        }
        return L.marker(latLng, {
          icon: icon,
          riseOnHover: true
        });
      },
      onEachFeature: (feature, layer) => {
        layer.on({
          click: handleClick
        });
      },
      token: authToken
    };

    let speciesLayer = L.esri.Cluster.featureLayer(speciesLayerOptions);

    let locateTreeLayer = L.esri.featureLayer({
      url: mapLayers.searchLayer,
      pointToLayer: (geojson, latLng) => {
        let featureType = geojson.properties.Type,
          size = geojson.properties.SIZE,
          icon = this.getIconsUrl(featureType, size);
        if (!icon) {
          //console.log("icon is null")
          return
        }
        return L.marker(latLng, {
          icon: icon,
          riseOnHover: true
        });
      },
      onEachFeature: (feature, layer) => {
        layer.on({
          click: handleClick
        });
      },
      token: authToken
    });

    //map filtering
    if ($('.map-filter-wrapper').length) {
      new MapFilter();
    }

    // Create map
    map = L.map(divName, {
      zoom: 12,
      minZoom: 11,
      maxZoom: 20,
      editable: true,
      scrollWheelZoom: false
    });


    map.setView([center.x, center.y], 12);

    if (map) {
      // init PlotTreeButton once map is loaded
      new PlotTreeButton(map);
    }

    // Gray Map
    basemap = L.tileLayer('https://www.onemap.gov.sg/maps/tiles/Grey_HD/{z}/{x}/{y}.png', {
      detectRetina: true,
      attribution: 'Map data © contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>',
      minZoom: 11,
      maxZoom: 20
    });

    // Google Statelite Map
    googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: 'Map data © contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>',
      minZoom: 11,
      maxZoom: 20
    });

    //initial layer
    map.setMaxBounds([[1.56073, 104.1147], [1.16, 103.502]]);
    this.addLayerToMap(map, basemap);

    this.addLayerToMap(map, treeLayers[map.getZoom()]); //manual clustering loads instantly but less accurate

    //--------- Maven clustering // takes longer to load about 3 minutes
    // addClass($loadingDiv, 'show');
    // this.addLayerToMap(map, allTree_cluster_layer_view_18);

    // allTree_cluster_layer_view_18.once('load', e => {
    //   removeClass($loadingDiv, 'show');
    // });
    //----------------------

    // toggle zoom feature for map
    window.emitter.on('allowZoomFeature', function (bool) {

      if (bool) {
        map.touchZoom.enable();
        map.doubleClickZoom.enable();
        // map.scrollWheelZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        $('.leaflet-control-zoom').css('visibility', 'visible');

      }
      else {
        // disable zoom
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        $('.leaflet-control-zoom').css('visibility', 'hidden');
      }

    });

    //add target _blank to leaflet attribution link
    this.addTargetToAttributionLinks();

    // Toggle between basemap and google satellite map
    $toggleMap.on('click', e => {
      e.preventDefault();

      let ele = $toggleMap,
        $onemap = $('#onemap');

      if (!ele.hasClass('active')) {
        toggleSatelliteLayer = true;
        this.changeTextToOneMap(ele);
        this.addLayerToMap(map, googleSat);
        this.removeLayerFromMap(map, basemap);
        this.addClass($onemap, 'polyWBorders');
      }
      else {
        if (map.getZoom() > 18) {
          map.setZoom(18);
        }
        toggleSatelliteLayer = false;
        this.changeTextToSatellite(ele);
        this.addLayerToMap(map, basemap);
        this.removeLayerFromMap(map, googleSat);
        this.removeClass($onemap, 'polyWBorders');
      }
    });

    // show map filters on click of filter button
    $layerFilterButton.bind('touchstart click', e => {

      let $this = $(e.target),
        $layerFilter = $('.layer-filter');
      if ($this.is('img')) {
        this.showHideLayerFilter($layerFilter);
      }

    });

    document.querySelector("#onemap:not(.layer-filter)").addEventListener("touchstart", closeLayerFilter);

    function closeLayerFilter() {
      let $layerFilter = $('.layer-filter');

      if ($layerFilter.hasClass('show-filter')) {
        removeClass($layerFilter, 'show-filter');
      }
    }

    // display tree information on click of marker
    let messagesApiURL,
      messagesContent = '',
      messagesTemplate = $('#template-contribute').length ? doT.template($('#template-contribute').html()) : '';

    this.currentPage = window.location.pathname.slice(1);

    //click marker activates this
    let handleClick = e => {
      $('.marked-icon').removeClass('marked-icon');
      window.treeClicked = true;
      window.searchData = {};

      let treeInformation = this.createTreeInformationObj(e);
      $(treeInformation.activeMarker).addClass('marked-icon'); //add marker for identification of clicked icon, removed when plot tree is clicked or when info wrapper is closed

      if (treeInformation) {
        if (treeInformation.EYTreeID) {
          treeInformation.Public_treeid = treeInformation.EYTreeID;
        }


        if (treeInformation.GirthSize) {
          treeInformation.Girth = treeInformation.GirthSize;
        }
      } else {
        return;
      }
      //centers clicked tree icon in level < 18
      let currentZoom = map.getZoom();

      if (currentZoom < 18) {
        let position = e.latlng;
        map.setZoomAround([position.lat, position.lng], 18);
      }

      if (typeof treeInformation.Public_treeid !== 'undefined' && !window.pinDropped) {

        // if ID is available
        let $loadingContent = $('.loading-content span', $loadingDiv);
        $loadingContent.text('Loading Tree Information');
        addClass($loadingDiv, 'show');

        // changed treeInformation.ID to treeInformation.Public_treeid
        let treeID = treeInformation.Public_treeid,
          type = treeInformation.Actual_Tree_Type || treeInformation.ACTUAL_TREE_TYPE,
          iconType = treeInformation.Type || treeInformation.Actual_Tree_Type,
          girth = treeInformation.Girth,
          eyTreeID = treeInformation.EYTreeID || treeInformation.EYTreeId,
          treeInfoTemplate = doT.template($('#template-treeInfo').html()),
          locateTreeId;

        window.globalTreeID = treeID;

        let treeIcon = this.getIconsUrl(iconType, girth); // user planted icon

        let query,
          dbQuery;

        if (type === 'USERTREE' || type === 'VIP') {
          // if USERPLANTED tree is clicked, use the userPlantedLayer to query
          query = L.esri.query({
            url: mapLayers.userPlantedLayer,
			isModern: false
          });

          dbQuery = `EYTreeID like '%${eyTreeID}%'`;

          locateTreeId = eyTreeID;
        }
        else {
          // otherwise, use the treeLayer (for ALLTREES, FLOWERING, HERITAGE)
          query = L.esri.query({
            url: mapLayers.treeLayer,
			isModern: false
          });

          dbQuery = `Public_treeid like '%${treeID}%'`;

          locateTreeId = treeID;
        }

        if (window.selectedMarker) { map.removeLayer(window.selectedMarker); }

        query.token(authToken).where(dbQuery).run((err, featureCollection, response) => {

          if (err) { console.log(err); }
          else {
            //Pull valid eyTreeIDs from iMaven's response and store in data
            this.storeEYTreeIDs(response);

            // console.log("response", response);
            // console.log("response features", response.features);

            let infoContent = '',
              treeInfoPath = response.features[0].attributes,
              sCodeApi = $('.onemap-wrapper').attr('data-scodeapi'),
              treeInfo = new Object(),
              addTreeObj = {},
              coordinates = L.latLng(response.features[0].geometry.y, response.features[0].geometry.x),
              EYTreeID = $('.onemap-wrapper').data('ey-tree-id');

            var json = {
              TreeId: locateTreeId,
              MasterId: response.features[0].attributes.SPCS_CD || '',
              Girth: response.features[0].attributes.Girth || response.features[0].attributes.GirthSize,
              TreeItemId: EYTreeID,
              UserPlanted: EYTreeID ? true : false
            };

            $.ajax({
              url: sCodeApi,
              method: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              data: JSON.stringify(json)
            }).done(obj => {
              addTreeObj = obj;

              //$(treeInformation.activeMarker).addClass('leaflet-marker-active leaflet-marker-test');
              $('.leaflet-marker-icon').removeClass('leaflet-marker-active');

              for (let i = 0; i < addTreeObj.length; i++) {

                // each tree
                let filterObj = new Object(addTreeObj[i]);
                let treeMergeInfo = $.extend(true, treeInfo, filterObj);

                // user plotted trees won't have SPCS_CD immediately

                if (addTreeObj[i].MasterId == treeInfoPath.SPCS_CD) {

                  let treeData = {
                    treeMergeInfo,
                    treeInfoPath,
                    treeIcon,
                    addTreeObj,
                    i,
                    treeID
                  };

                  this.getTreeDataFromSpeciesCode(treeData);

                }
                else {
                  let treeData = {
                    treeMergeInfo,
                    treeInfoPath,
                    treeIcon,
                    treeID
                  };


                  this.getTreeDataFromMapService(treeData);
                }

                // for dotjs templating
                infoContent = treeInfoTemplate(treeMergeInfo);
                $infoTemplateHolder.html(infoContent); //output to html

                //------------move to tree-detail-----------
                // $(document).unbind().on('click', '.report-button', function (e) {
                //   e.preventDefault();

                //   window.emitter.emit('openFlagTreeForm', true);

                //   $('html, body').animate({ scrollTop: 0 }, 'slow');
                //   //$('.onemap-wrapper .overlay').removeClass('close');

                // });

                // retrieve title, description, image, url
                this.updateAddThisData(locateTreeId, treeIdParameter, type);

                // all accordions
                let $accordion = $('.accrd'),
                  // button to open accordion
                  $accordionController = $('.accordion-controller', $accordion),
                  $accordionText = $('.accordion-text', $accordion);

                this.toggleAccordion($accordion);

                this.addMoreClassToGallery(addTreeObj, i);

                this.addMoreClassToUserGallery(addTreeObj, i);

                // show and hide flowering tree tooltip
                let $treeDetail = $('.tree-detail'),
                  $treeOfMonth = $('.tree-of-month'),
                  $hugs = $('.hugs'),
                  $tooltipshare = $('#tooltipshare'),
                  counthugsurl = $treeOfMonth.data('counthugsurl'),
                  addhugsurl = $treeOfMonth.data('addhugsurl'),
                  removehugsurl = $treeOfMonth.data('removehugsurl'),
                  $detailsActions = $('.detail-wrapper__actions', $treeDetail),
                  $flowering = $('.flowering', $detailsActions),
                  $tooltip = $('.tooltip', $detailsActions),
                  $btnUpload = $('.btn--upload', $detailsActions),
                  $btnCont = $('.btn--cont', $detailsActions),
                  $image = $flowering.find('img'),
                  $text = $flowering.find('span');

                let treeType = treeInfoPath.Type;

                if (treeType == 'FLOWERING') {
                  this.changeFloweringText($image, $text, $flowering);
                }

                let floweringData = {
                  $flowering,
                  $tooltip,
                  $btnCont,
                  $image,
                  $text,
                  $btnUpload,
                  $loadingDiv,
                  selectedActiveMarker,
                  layer_view_18,
                  flowering_cluster_layer_view_18,
                  heritage_cluster_layer_view_18,
                  user_plotted_layer_view_18,
                  speciesLayer,
                  userLayer,
                  locateTreeLayer,
                  treeInfoPath,
                  e,
                  map
                };

                this.floweringTree(floweringData);

                let hugTree = null;

                if ($hugs.length) {
                  hugTree = new HugTree($hugs, treeID, addhugsurl, removehugsurl);
                }

                this.galleryFancybox();

                let $galleryWrapper = $('.gallery-wrapper', $mapDetailsWrapper),
                  galleryCount = $('.fancybox-gallery').length,
                  userPhotoCount = $('.fancybox-user').length;

                this.setPhotoCount(galleryCount, userPhotoCount);
              }
            });

            let treeInfoTemplate = doT.template($('#template-treeInfo').html());
            // let messagesTemplate = doT.template($('#template-contribute').html());
            let $mapDetailsWrapper = this.$mapDetailsWrapper,
              $infoTemplateHolder = this.$infoTemplateHolder,
              $noMessage = this.$noMessage,
              $messagesTemplateHolder = this.$messagesTemplateHolder,
              $mapCarousel = this.$mapCarousel,
              $scrollable = this.$scrollable;

            $leafletControlContainer.hide();
            $layerFilterButton.hide();
            $infoButton.hide();
            $basemapButton.hide();
            $mapExpandBtn.removeClass('hide');

            if ($mapExpandBtn.hasClass('active')) {
              $mapExpandBtn.removeClass('active');
              $mapExpandBtn.html('<i class="icon icon-expand"></i>');
            }

            $mapContainer.addClass('smallerMap');

            // open map info (slide up)

            this.openInfoWrapper(map, coordinates, $mapDetailsWrapper, $loadingDiv);

            this.invalidateMapSize(map, coordinates, treeIcon, selectedActiveMarker, $loadingDiv); //call select icon

            messagesApiURL = messagesApi;


            let postData = {
              TreeId: window.globalTreeID,
              Page: 1
            };

            window.searchData = {};

            let userData = {
              treeID: window.globalTreeID,
              messagesApiURL,
              $noMessage,
              $messagePagination,
              messagesContent,
              messagesTemplate,
              $messagesTemplateHolder,
              ITEMS_PER_PAGE,
              postData
            };

            this.getUserMessages(userData);

            window.emitter.on('pagination', (totalPagesObj, treeID, postData) => {
              let defaultOpts = {
                visiblePages: 3,
                initiateStartPageClick: false,
                first: '<i class="icon icon-caret-left"></i><i class="icon icon-caret-left"></i>',
                prev: '<i class="icon icon-caret-left"></i>',
                next: '<i class="icon icon-caret-right"></i>',
                last: '<i class="icon icon-caret-right"></i><i class="icon icon-caret-right"></i>',
                anchorClass: 'link-button',
                onPageClick: (event, page) => {
                  let mapInfoContainerHeight = $('#mapInfo').outerHeight(true);
                  $('.details-scrollable').animate(
                    {
                      scrollTop: mapInfoContainerHeight
                    },
                    1500
                  );
                  // postData.page = page;
                  window.searchData['TreeId'] = treeID;
                  window.searchData['Page'] = page;
                  postData = window.searchData;
                  let userData = {
                    treeID: window.globalTreeID,
                    messagesApiURL,
                    $noMessage,
                    $messagePagination,
                    messagesContent,
                    messagesTemplate,
                    $messagesTemplateHolder,
                    ITEMS_PER_PAGE,
                    postData,
                    page
                  };
                  this.getUserMessages(userData);
                }
              };

              $pagination.twbsPagination('destroy');

              $pagination.twbsPagination($.extend({}, defaultOpts, totalPagesObj));
            });
          }
        });
      }
    };

    // search tree mail messages.
    $searchMessageInput.on('keyup', e => {
      let $this = $(e.target);

      if (e.keyCode == 13) {
        let value = escapeString($this.val()),
          postData = {
            TreeId: window.globalTreeID,
            SearchTerm: value,
            Page: 1
          };

        window.searchData = postData;

        let userData = {
          treeID: window.globalTreeID,
          messagesApiURL,
          $noMessage: this.$noMessage,
          $messagePagination,
          messagesContent,
          messagesTemplate,
          $messagesTemplateHolder: this.$messagesTemplateHolder,
          ITEMS_PER_PAGE,
          postData
        };
        this.getUserMessages(userData);
      }
    });

    // call messagesAPI and pass the treeID and page number as 1 to re-display the messages
    $closeSearch.on('click', e => {
      let $this = $(e.target);
      removeClass($searchMessage, 'open');
      removeClass($search, 'hide');
      removeClass($writeMessage, 'hide');
      $searchInput.val('');
      window.searchData = {};
      let postData = {
        TreeId: window.globalTreeID,
        Page: 1
      };

      let userData = {
        treeID: window.globalTreeID,
        messagesApiURL,
        $noMessage: this.$noMessage,
        $messagePagination,
        messagesContent,
        messagesTemplate,
        $messagesTemplateHolder: this.$messagesTemplateHolder,
        ITEMS_PER_PAGE,
        postData,
        page: 1
      };
      this.getUserMessages(userData);
    });

    // close the form and update the user messages
    $closeForm.on('click', () => {
      document.getElementById('iframe').contentDocument.location.reload(true);

      $('body').removeClass('form-open');
      $messageFormWrapper.removeClass('open');
      $('.map-filter-wrapper').removeClass('form-open');
      $('.legend-wrapper').removeClass('form-open');

      let postData = {
        TreeId: window.globalTreeID,
        Page: 1
      };

      let userData = {
        treeID: window.globalTreeID,
        messagesApiURL,
        $noMessage: this.$noMessage,
        $messagePagination,
        messagesContent,
        messagesTemplate,
        $messagesTemplateHolder: this.$messagesTemplateHolder,
        ITEMS_PER_PAGE,
        postData,
        page: 1
      };
      this.getUserMessages(userData);
    });

    // close notification
    $closeNotification.on('click', function () {
      document.getElementById('announcement').style.display = "none";
    });

    // open the form and pass the tree ID value to iframe
    $writeMessage.on('click', e => {
      if (window.freezeSite) {
        new ErrorPopup(true);
      }
      else {
        //Retrieve stored EYTreeID (if any)
        let selectedEYTreeID = $('.onemap-wrapper').data('ey-tree-id');
        //EYTreeID takes priority over publicTreeID when injecting into form
        let treeIDforContribute = selectedEYTreeID ? selectedEYTreeID : window.globalTreeID;

        $('body').addClass('form-open');
        $('#iframe')
          .contents()
          .find('#plot-tree-form__tree-id')
          .val(treeIDforContribute);

        let iFrameHeight = $('#iframe')[0].contentWindow.document.body.offsetHeight + 'px';

        $messageFormWrapper.addClass('open');
        addClass($('.map-filter-wrapper'), 'form-open');
        addClass($('.legend-wrapper'), 'form-open');
        addClass($('.trees-count'), 'form-open');
      }
    });

    //trigger search without clicking suggestion


    let startSearch = () => {
      const $icon = $('i', $searchBtn),
        // $customSelect = $('.customSelect'),
        // $selectedOption = $('span', $customSelect).text();
        $selectedOption = $('.mapFilterBtns.is-active').data('value');

      if ($mapSearch.val() && ($selectedOption === 'name' || $selectedOption === 'email' || $selectedOption == 'tag' || $selectedOption == 'treemail')) {
        // if (!$loadingMessage.is(':visible')) {
        //   this.showLoadingTreesMessage($loadingMessage);
        // }

        // console.log("checking", map.hasLayer(userLayer))
        if ($speciesError.hasClass('show-error')) {
          removeClass($speciesError, 'show-error');
        }
        const suggestion = $mapSearch.val();

        window.emitter.emit('speciesSearch');

        //clear map
        if (map.hasLayer(speciesLayer)) {
          speciesLayer.removeFrom(map);
        }
        if (map.hasLayer(userLayer)) {
          //console.log("remove this please")
          userLayer.removeFrom(map);
        }
        if (map.hasLayer(user_plotted_layer_view_18)) {
          user_plotted_layer_view_18.removeFrom(map);
        }

        if (map.hasLayer(allTree_cluster_layer_view_18)) {
          allTree_cluster_layer_view_18.removeFrom(map);
        }

        if ($selectedOption === 'treemail' || $selectedOption === 'email' || $selectedOption === 'name') {

          $('#TREE').prop("checked", true);
          const that = this;
          async function getUserLayer() {
            // add the layer with search result pulling the treemail contents as well
            try {

              allTree_cluster_layer_view_18 = await that.addUserLayerTreemail(
                $loadingContent,
                $loadingDiv,
                treeLayers,
                map,
                heritage_cluster_layer_view_18,
                flowering_cluster_layer_view_18,
                MAVEN_FIELDS,
                suggestion,
                userPlottedLayerView18Options,
                allTree_cluster_layer_view_18,
                $speciesError
              );
              // console.log(allTree_cluster_layer_view_18, "all_tree layer treemail outside");
            }
            catch (error) {
              console.log("error in try block", error);
            }
          }

          getUserLayer();

        } else {

          $('#USERTREE').prop("checked", true);
          //add the layer with search result
          const instance = this;

          async function awaitUserLayer(){
            try{
              userLayer = await instance.addUserLayer2($loadingContent,
                $loadingDiv,
                treeLayers,
                map,
                heritage_cluster_layer_view_18,
                flowering_cluster_layer_view_18,
                MAVEN_FIELDS,
                suggestion,
                userPlottedLayerView18Options,
                userLayer,
                $speciesError);
            }
            catch(e){
              console.log('awaitUserLayer' + e);
            }
          }

          awaitUserLayer();          

          // console.log(userLayer, "userlayer tags email")
          // console.log(map.hasLayer(userLayer), "fff tags")

        }

        //console.log("Executed first")


        setSearchState(true);
        $mapSearch.blur();
      }
    }

    $searchBtn.on('click', e => {
      startSearch(); //trigger search query
      //console.log("button")
    });

    $mapSearch.on('keyup', e => {
      if (e.key === 'Enter') {
        startSearch();
        // console.log("enteredd");
      }
    });

    //If the filter bar above onemap 'search by' location or species dropdown auto suggestion is clicked
    // add user and email here after suggestions are added.
    $mapSearch.on('typeahead:select', (event, suggestion) => {
      let $customSelect = $('.customSelect'),
        // $selectedOption = $('span', $customSelect).text();
        $selectedOption = $('.mapFilterBtns.is-active').data('value');
      // select one of the suggestions
      if ($speciesError.hasClass('show-error')) {
        removeClass($speciesError, 'show-error');
      }

      $('.close').trigger('click');
      window.emitter.emit('treeSearch');

      if (!$loadingMessage.is(':visible')) {
        this.showLoadingTreesMessage($loadingMessage);
      }

      //If user clicks on a location dropdown suggestion
      if ($selectedOption === 'location') {
        $('#TREE').prop("checked", true);

        let latLng = L.latLng(suggestion.lat, suggestion.lon);

        map.flyTo(latLng, 18);
        this.hideLoadingTreesMessage($loadingMessage);
      }

      else if ($selectedOption === 'species') {
        //If user clicks on a species dropdown suggestion
        // if species is chosen, no lat and long info
        $('#TREE').prop("checked", true);

        // console.log("checking in species search", map.hasLayer(userLayer))
        window.emitter.emit('speciesSearch');
        this.removeLayerFromMap(map, speciesLayer);

        // remove user plotted trees layer
        this.removeLayerFromMap(map, userLayer);
        this.removeLayerFromMap(map, user_plotted_layer_view_18);
        this.removeLayerFromMap(map, allTree_cluster_layer_view_18);

        speciesLayer = this.addSpeciesLayer(
          $loadingContent,
          $loadingDiv,
          treeLayers,
          map,
          heritage_cluster_layer_view_18,
          flowering_cluster_layer_view_18,
          MAVEN_FIELDS,
          suggestion,
          speciesLayerOptions,
          speciesLayer,
          $speciesError
        );

      } else if ($selectedOption === 'name' || $selectedOption === 'email' || $selectedOption == 'tag' || $selectedOption == 'treemail') {
        window.emitter.emit('speciesSearch');
        //console.log("clicked on the button")

        //clear map
        this.removeLayerFromMap(map, speciesLayer);
        this.removeLayerFromMap(map, user_plotted_layer_view_18);
        this.removeLayerFromMap(map, allTree_cluster_layer_view_18);

        //add the layer with search result
        // userLayer = this.addUserLayer(
        //   $loadingContent,
        //   $loadingDiv,
        //   treeLayers,
        //   map,
        //   heritage_cluster_layer_view_18,
        //   flowering_cluster_layer_view_18,
        //   MAVEN_FIELDS,
        //   suggestion,
        //   userPlottedLayerView18Options,
        //   userLayer,
        //   $speciesError
        // );


        if ($selectedOption === 'treemail' || $selectedOption === 'email' || $selectedOption === 'name') {

          $('#TREE').prop("checked", true);
          const that = this;
          async function getUserLayer() {
            // add the layer with search result pulling the treemail contents as well
            try {
              allTree_cluster_layer_view_18 = await that.addUserLayerTreemail(
                $loadingContent,
                $loadingDiv,
                treeLayers,
                map,
                heritage_cluster_layer_view_18,
                flowering_cluster_layer_view_18,
                MAVEN_FIELDS,
                suggestion,
                userPlottedLayerView18Options,
                allTree_cluster_layer_view_18,
                $speciesError
              );
              // console.log(allTree_cluster_layer_view_18, "all_tree layer treemail outside");
            }
            catch (error) {
              console.log("error in try block", error);
            }
          }

          getUserLayer();

        } else {

          $('#USERTREE').prop("checked", true);
          //add the layer with search result
          const instance = this;

          async function awaitUserLayer(){
            try{
              userLayer = await instance.addUserLayer2($loadingContent,
                $loadingDiv,
                treeLayers,
                map,
                heritage_cluster_layer_view_18,
                flowering_cluster_layer_view_18,
                MAVEN_FIELDS,
                suggestion,
                userPlottedLayerView18Options,
                userLayer,
                $speciesError);
            }
            catch(e){
              console.log('awaitUserLayer' + e);
            }
          }

          awaitUserLayer();     

        }

      }
      setSearchState(true);
      $mapSearch.blur();
    });

    // Map and Info Button functions
    $close.on('click', e => {
      let data = {
        e,
        $mapDetailsWrapper,
        mapInfoButtons,
        map,
        locateTreeLayer,
        isTreeSearch,
        selectedActiveMarker
      };
      // map.setZoom(12);

      // reset search if any
      // const $icon = $('i', $searchBtn);
      // if ($icon.hasClass('icon-close')){
      // 	window.emitter.emit('clearSearch');
      // 	setSearchState(false);
      // }else{
      //   //reset layer
      //   // let checked = this.getCurrentFilter();
      //   // checked[0].trigger('click');
      // }
      this.closeInfoWrapper(data);
    });

    // Filtering the map layers
    // leaflet marker cluster layer icons on click
    markers.on('click', e => { //triggers handleclick only when marker is clicked
      handleClick(e);
    });

    $('#treeConserve').on('click', e => {
      treeConservationLayer = this.addSelectedLayer(e, treeConservationLayer, mapLayers, map);
    });

    $('#heritageRoad').on('click', e => {
      heritageRoadLayer = this.addSelectedLayer(e, heritageRoadLayer, mapLayers, map);
    });



    window.emitter.on('closeForm', (latlng, zoomLvl) => {

      window.emitter.emit('clearDropPin');
      $('.plot-tree-form').removeClass('show');

      $('body').css('top', '');
      $('body').removeClass('form-open');

      window.scrollTo(0, window.bodyScrollTop);

      window.setTimeout(function () {
        window.bodyScrollTop = null;
      }, 0);

      window.isPlotting = false;

      if ($('#plotTreeIframe').hasClass('is-successful')) {

        // change current filter to USERTREE
        document.getElementById('USERTREE').click();

        map.setView(latlng, zoomLvl);

        this.removeLayerFromMap(map, speciesLayer);
        this.removeLayerFromMap(map, userLayer);
        this.removeLayerFromMap(map, allTree_cluster_layer_view_18)

        for (let layer in treeLayers) {
          this.removeLayerFromMap(map, treeLayers[layer]);
        }

        for (let layer in heritageLayers) {
          this.removeLayerFromMap(map, heritageLayers[layer]);
        }

        for (let layer in floweringLayers) {
          this.removeLayerFromMap(map, floweringLayers[layer]);
        }

        this.addLayerToMap(map, user_plotted_layer_view_18);

      };

    });

    //radio click
    //All Trees - All, Flowering Trees - Flowering, Heritage Trees - Heritage
    $('.radio input').on('click', () => {
      // window.emitter.emit('clearSearch');
      setSearchState(false);
      isTreeSearch = false;
      $mapSearch.val('');
      let radioFilters = $('.radio input[type=radio]:checked') //an object with type and feature
        .map((index, $ele) => {
          return {
            type: $ele.dataset.type,
            feature: $ele.dataset.feature
          };
        })
        .get();

      this.showLoadingTreesMessage($loadingMessage);
      map.setView([center.x, center.y], 12);

      let suggestion = $mapSearch.typeahead('val');
      this.removeLayerFromMap(map, speciesLayer);
      this.removeLayerFromMap(map, userLayer);
      this.removeLayerFromMap(map, allTree_cluster_layer_view_18);

      // let $customSelect = $('.customSelect'),
      //   $selectedOption = $('span', $customSelect).text();
      let $selectedOption = $('.mapFilterBtns.is-active').data('value');

      if ($selectedOption === 'location') { $mapSearch.typeahead('val', ''); }

      if ($selectedOption === 'species' && suggestion.length > 0) {
        speciesLayer = this.addSpeciesLayer(
          $loadingContent,
          $loadingDiv,
          treeLayers,
          map,
          heritage_cluster_layer_view_18,
          flowering_cluster_layer_view_18,
          MAVEN_FIELDS,
          suggestion,
          speciesLayerOptions,
          speciesLayer,
          $speciesError
        );
      }
      else {
        window.treeClicked = false;

        if (radioFilters[0].feature == 'All') {
          addClass($loadingDiv, 'show');
          // remove all except "all" layers
          this.removeLayerFromMap(map, layer_view_18);
          this.removeLayerFromMap(map, heritage_cluster_layer_view_18);
          this.removeLayerFromMap(map, flowering_cluster_layer_view_18);
          this.removeLayerFromMap(map, user_plotted_layer_view_18);

          this.clearLayers(markers);
          this.clearLayers(markerGeoJSONLayer);
          //manual clustering uses treeLayers. for maven clustering use allTree_cluster_layer_view_18. use addClass($loading, show) if required
          //After changing to maven clustering, search map move, remove code for all trees.
          this.addLayerToMap(map, treeLayers[12]);
          treeLayers[12].once('load', e => {
            removeClass($loadingDiv, 'show');
          });
        }
        else {
          this.removeLayerFromMap(map, treeLayers[12]);
          // this.removeLayerFromMap(map, LAYERS[18]);

          let VIEW_LAYERS_OPTIONS;

          if (radioFilters[0].feature == 'Heritage') {
            addClass($loadingDiv, 'show');

            // remove all except Heritage
            for (let layer in treeLayers) {
              this.removeLayerFromMap(map, treeLayers[layer]);
            }
            this.removeLayerFromMap(map, layer_view_18);
            this.removeLayerFromMap(map, user_plotted_layer_view_18);
            this.removeLayerFromMap(map, flowering_cluster_layer_view_18);
            this.removeLayerFromMap(map, heritage_cluster_layer_view_18);

            this.addLayerToMap(map, heritage_cluster_layer_view_18);

            heritage_cluster_layer_view_18.once('load', e => {
              removeClass($loadingDiv, 'show');
            });
          }
          else if (radioFilters[0].feature == 'Flowering') {
            addClass($loadingDiv, 'show');

            // remove all except Flowering
            for (let layer in treeLayers) {
              this.removeLayerFromMap(map, treeLayers[layer]);
            }
            this.removeLayerFromMap(map, layer_view_18);
            this.removeLayerFromMap(map, user_plotted_layer_view_18);
            this.removeLayerFromMap(map, flowering_cluster_layer_view_18);
            this.removeLayerFromMap(map, heritage_cluster_layer_view_18);

            this.addLayerToMap(map, flowering_cluster_layer_view_18);

            flowering_cluster_layer_view_18.once('load', e => {
              removeClass($loadingDiv, 'show');
            });
          }
          else if (radioFilters[0].feature == 'UserTree') {
            addClass($loadingDiv, 'show');
            // remove all except user planted trees
            for (let layer in treeLayers) {
              this.removeLayerFromMap(map, treeLayers[layer]);
            }
            this.removeLayerFromMap(map, layer_view_18);
            this.removeLayerFromMap(map, user_plotted_layer_view_18);
            this.removeLayerFromMap(map, flowering_cluster_layer_view_18);
            this.removeLayerFromMap(map, heritage_cluster_layer_view_18);

            // show all points for phase 1
            this.addLayerToMap(map, user_plotted_layer_view_18);
            user_plotted_layer_view_18.once('load', e => {
              removeClass($loadingDiv, 'show');
            });
          }


        }

        let $layerFilter = $('.layer-filter');
        removeClass($layerFilter, 'show-filter');
      }
    });

    $(document).mouseup(function (e) {
      let $layerFilter = $('.layer-filter');
      if (!$layerFilter.is(e.target) && $layerFilter.has(e.target).length === 0) {
        removeClass($layerFilter, 'show-filter');
      }
    });

    // to remove individual marker added to map by locateTree function
    window.emitter.on('treeSearch', () => {
      this.clearLayers(markerGeoJSONLayer);
    });

    // remove tree layers
    window.emitter.on('speciesSearch', () => {
      isTreeSearch = true;
      for (let layer in treeLayers) {
        this.removeLayerFromMap(map, treeLayers[layer]);
      }
      map.setView([center.x, center.y], 12);
    });

    // to remove all other layers and add the superclustermarkers layer to the map
    window.emitter.on('clearSearch', () => {
      removeClass($speciesError, 'show-error');
      removeClass($loadingDiv, 'show');
      this.removeLayerFromMap(map, speciesLayer);
      this.removeLayerFromMap(map, userLayer);
      this.removeLayerFromMap(map, allTree_cluster_layer_view_18);
      let checked = this.getCurrentFilter();
      checked[0].trigger('click');
      isTreeSearch = false;
    });

    //locate tree function
    $locateTree.on('click', e => {
      removeClass($aboutMap, 'show-info');

      let treeid = $treeOfMonth.data('treeid').toString();

      let treeidString = treeid.toString();

      let locateLayer = locateTreeLayer;

      let locateTreeData = {
        mapLayers,
        treeid,
        map,
        handleClick,
        locateLayer,
        treeLayers,
        heritage_cluster_layer_view_18,
        flowering_cluster_layer_view_18,
        user_plotted_layer_view_18,
        layer_view_18,
        isTreeSearch,
        $loadingDiv
      };

      if (treeidString.indexOf("{") !== -1) {
        this.locateTree(locateTreeData, 'USERTREE');
      } else {
        this.locateTree(locateTreeData);
      }
    });

    //window onload
    $(window).on('load', () => {
      // 1) find if the parameter of 'treeID' exists
      let urlParams = new URLSearchParams(window.location.search);

      //check if currentpage is home page
      this.currentPage = window.location.pathname.slice(1);
      this.isVipPage = this.currentPage.toLowerCase().includes('vip');

      // 2) if yes, get its value, and assign it to treeid
      var hasTreeIDParameter = false;
      var treeidval = null;

      urlParams.forEach(function(value, key){
        if(key && value){
          if(key.toLowerCase() == treeIdParameter.toLowerCase()){
            hasTreeIDParameter = true;
            treeidval = value;
            treeIdParameter = key.toLowerCase();
          }
        }
      });      

      if (hasTreeIDParameter) {
        let treeid = treeidval,
          treeType = urlParams.get('treetype') || 'TREE',
          openTreeMail = urlParams.get('treemail'),
          triggerTreeMail = false,
          locateLayer = locateTreeLayer; //default locate npark tree layer

        if ((!$('.info-popup').hasClass('hide'))) {
          $('.info-popup').addClass('hide');
        }

        if (!this.isVipPage) { //close "Change in url" notice when redirect to treeID, only activate this if on home page
          $('header').addClass('header open-map');
        }

        if (treeType === 'USERTREE') {
          locateLayer = user_plotted_layer_view_18;
        }

        if ($('.map-details-nav').length) { //check if treemail nav exist
          if (openTreeMail) {
            triggerTreeMail = true;
          }
        }


        if (treeid) { //if has treeID locate map.
          // 3) then call all the things below
          // hide the show-info container
          removeClass($aboutMap, 'show-info');

          let locateTreeData = {
            mapLayers,
            treeid,
            map,
            handleClick,
            locateLayer,
            treeLayers,
            heritage_cluster_layer_view_18,
            flowering_cluster_layer_view_18,
            user_plotted_layer_view_18,
            layer_view_18,
            isTreeSearch,
            $loadingDiv
          };

          this.locateTree(locateTreeData, treeType, triggerTreeMail);
        }


      }
    });

    //flowering tree locate on map function
    let $floweringTrees = $('.flowering-trees'),
      $floweringTreeItem = $('.flowering-tree-item');

    window.emitter.on('flowering-carousel-init', () => {
      $floweringTreeItem.map((index, ele) => {
        let $this = $(ele);

        $this.on('click', e => {
          e.preventDefault();
          removeClass($aboutMap, 'show-info');
          let treeid = $this.data('treeid');

          let locateLayer = locateTreeLayer;

          let currentTreeData = {
            mapLayers,
            treeid,
            map,
            handleClick,
            locateLayer,
            treeLayers,
            layer_view_18,
            isTreeSearch,
            $loadingDiv
          };

          $('html, body').animate(
            {
              scrollTop: 0
            },
            600
          );
          //this.locateTree(currentTreeData);

          if (treeid.toString().indexOf("{") !== -1) {
            this.locateTree(currentTreeData, 'USERTREE');
          } else {
            this.locateTree(currentTreeData);
          }
        });
      });
    });

    // current location

    let $currentLocation = $('.js-current-location'),
      $locationDeniedPopUp = $('.location-denied-pop-up'),
      $locationDeniedPopUpOkBtn = $('.location-denied-pop-up__btn--ok');

    $locationDeniedPopUpOkBtn.on('click', function () {
      $locationDeniedPopUp.removeClass('show-info')
    })

    $currentLocation.bind('click touchstart', e => {
      e.preventDefault();

      // navigator.geolocation.getCurrentPosition(showError);

      // function showError(error) {
      //   console.log("entered", error);
      //   switch(error.code) {
      //     case error.PERMISSION_DENIED:
      //       console.log("User denied the request for Geolocation.");
      //       break;
      //   }
      // }

      map.locate({
        maxZoom: 19,
        timeout: 15000,
        maximumAge: 5000,
        enableHighAccuracy: true
      });

      map.on('locationfound', e => {

        let lat = e.latitude,
          lng = e.longitude;

        let currentLocationIcon = L.divIcon({
          className: 'icon-curr-location'
        });

        let currentLocationMarker = L.marker([lat, lng], {
          icon: currentLocationIcon
        }).addTo(map);

        map.flyTo([lat, lng], 18);

      });

      map.on('locationerror', err => {
        //console.log('error - ', err);

        if (err.message == "Geolocation error: User denied Geolocation.") {
          $locationDeniedPopUp.addClass('show-info');
        }
      });
    });

    //close notice
    $closeNotice.on('click', e => {
      addClass($notices, 'hide-notice');
      window.closeNotice = true;
    });

    // map move
    //mouse drag
    map.on('moveend', () => {
      //check if url is under vip-page
      let currentZoom = map.getZoom(),
        checkedFilter,
        currentFilter;

      //if url is not under vip page,
      if (!this.isVipPage) {
        checkedFilter = this.getCurrentFilter();
        currentFilter = checkedFilter[0].attr('id');
      } else {
        let urlParams = new URLSearchParams(window.location.search),
          treeType = urlParams.get('treetype') || 'TREE';

        currentFilter = treeType;
      }

      if (currentFilter == 'TREE') {
        //hide and show layers
        let layerToShow = this.showHideLayers(ZOOM_LEVELS, currentZoom, map, treeLayers);
        // this.removeLayerFromMap(heritage_cluster_layer_view_18);
        // this.removeLayerFromMap(flowering_cluster_layer_view_18);
        if (currentZoom >= 18) {
          if (!window.treeClicked) {
            if (!window.pinDropped) {
              layer_view_18.setWhere('1=1');
            }
          }

          if (!isTreeSearch) {
            let bounds = map.getBounds();
            layer_view_18.query().bboxIntersects(bounds);
            this.addLayerToMap(map, layer_view_18);
          }
          this.toggleSatelliteMapLayer(toggleSatelliteLayer, currentZoom, map, googleSat, basemap);
        }
        else {
          this.removeLayersAndCloseInfoWrapper($close, map, layer_view_18);
          if (!isTreeSearch && layerToShow.length > 0) {
            this.addLayerToMap(map, treeLayers[layerToShow]);
          }
        }
      }
      else if (currentFilter == 'HERITAGE') {
        /*----------------------------------Start of comment ----------------------------------
          Hadi's code for manual clustering -
          Problems
            1) Missing clusters
            2) Clustering icon showing even though there is only 1 icon underneath
                hide and show layers

          let layerToShow = this.showHideLayers(ZOOM_LEVELS, currentZoom, map, heritageLayers);
          if (currentZoom >= 18) {
            if (!window.treeClicked) {
              layer_view_18.setWhere('Type=\'HERITAGE\'');
            }
            if (!isTreeSearch) {
              let bounds = map.getBounds(); //only show the tree within the zoom area
              layer_view_18.query().bboxIntersects(bounds);
              this.addLayerToMap(map, layer_view_18);
            }
            this.toggleSatelliteMapLayer(toggleSatelliteLayer, currentZoom, map, googleSat, basemap);
          }
          else {
            this.removeLayersAndCloseInfoWrapper($close, map, layer_view_18);
            if (!isTreeSearch && layerToShow.length > 0) {
              this.addLayerToMap(map, heritageLayers[layerToShow]);
            }
          }
        ------------------------------------ end of comment -----------------------------------------*/
        //hide other layers when map has interaction
        // for (let layer in treeLayers) {
        //   this.removeLayerFromMap(map, treeLayers[layer]);
        // }
        // this.removeLayerFromMap(map, user_plotted_layer_view_18);
        // this.removeLayerFromMap(map, flowering_cluster_layer_view_18);

      }
      else if (currentFilter == 'FLOWERING') {
        /*----------------------------------Start of comment ----------------------------------\
          Hadi's code for manual clustering -
          Problems
            1) Missing clusters
            2) Clustering icon showing even though there is only 1 icon underneath
                hide and show layers

        let layerToShow = this.showHideLayers(ZOOM_LEVELS, currentZoom, map, floweringLayers);
        // this.removeLayerFromMap(map, layer_view_18);
        if (currentZoom >= 18) {
          if (!window.treeClicked) {
            layer_view_18.setWhere('Type=\'FLOWERING\'');
          }
          if (!isTreeSearch) {
            let bounds = map.getBounds();
            layer_view_18.query().bboxIntersects(bounds);
            this.addLayerToMap(map, layer_view_18);
          }
          this.toggleSatelliteMapLayer(toggleSatelliteLayer, currentZoom, map, googleSat, basemap);
        }
        else {
          this.removeLayersAndCloseInfoWrapper($close, map, layer_view_18);
          if (!isTreeSearch && layerToShow.length > 0) {
            this.addLayerToMap(map, floweringLayers[layerToShow]);
          }
        }
        ------------------------------------ end of comment -----------------------------------------*/
        // for (let layer in treeLayers) {
        //   this.removeLayerFromMap(map, treeLayers[layer]);
        // }
        // this.removeLayerFromMap(map, user_plotted_layer_view_18);
        // this.removeLayerFromMap(map, heritage_cluster_layer_view_18);

      } else if (currentFilter == "USERTREE") {

        // for (let layer in treeLayers) {
        //   this.removeLayerFromMap(map, treeLayers[layer]);
        // }
        // this.removeLayerFromMap(map, flowering_cluster_layer_view_18);
        // this.removeLayerFromMap(map, heritage_cluster_layer_view_18);

        if (!isTreeSearch) {
          let bounds = map.getBounds();
          user_plotted_layer_view_18.query().bboxIntersects(bounds);
          this.addLayerToMap(map, user_plotted_layer_view_18);
        }

        this.toggleSatelliteMapLayer(toggleSatelliteLayer, currentZoom, map, googleSat, basemap);

      }

    });
  }

  changeTextToSatellite(ele) {
    this.removeClass(ele, 'active');
    $('span span.desktop-only', ele).text('Satellite');
  }

  changeTextToOneMap(ele) {
    this.addClass(ele, 'active');
    $('span span.desktop-only', ele).text('OneMap');
  }

  toggleSatelliteMapLayer(toggleSatelliteLayer, currentZoom, map, googleSat, basemap) {
    if (!toggleSatelliteLayer && currentZoom > 18) {
      this.changeTextToOneMap($('.toggleMapBase'));
      this.addLayerToMap(map, googleSat);
      this.removeLayerFromMap(map, basemap);
    }
    else if (!toggleSatelliteLayer && currentZoom == 18) {
      this.changeTextToSatellite($('.toggleMapBase'));
      this.addLayerToMap(map, basemap);
      this.removeLayerFromMap(map, googleSat);
    }
  }

  removeLayersAndCloseInfoWrapper($close, map, layerToRemove) {

    $close.trigger('click');

    this.removeLayerFromMap(map, layerToRemove);

    if (window.selectedMarker) {
      map.removeLayer(window.selectedMarker);
    }

  }

  showHideLayerFilter($layerFilter) {
    if ($layerFilter.hasClass('show-filter')) {
      removeClass($layerFilter, 'show-filter');
    }
    else {
      addClass($layerFilter, 'show-filter');
    }
  }


  //--async tag search--//
  async addUserLayer2(
    $loadingContent,
    $loadingDiv,
    treeLayers,
    map,
    heritage_cluster_layer_view_18,
    flowering_cluster_layer_view_18,
    MAVEN_FIELDS,
    suggestion,
    userPlottedLayerView18Options,
    userLayer,
    $speciesError) {

    $loadingContent.text('Loading Search Results');
    addClass($loadingDiv, 'show');
    removeClass($speciesError, 'show-error');


    // remove all layers in case they are present
    for (let layer in treeLayers) {
      if (map.hasLayer(treeLayers[layer])) {
        treeLayers[layer].removeFrom(map);
      }
    }
    if (map.hasLayer(heritage_cluster_layer_view_18)) {
      heritage_cluster_layer_view_18.removeFrom(map);
    }
    if (map.hasLayer(flowering_cluster_layer_view_18)) {
      flowering_cluster_layer_view_18.removeFrom(map);
    }

    if (map.hasLayer(userLayer)) {
      userLayer.removeFrom(map);
    }

    // let checked = this.getCurrentFilter(),
    const featureType = 'USERTREE';

    let queryString = '';

    suggestion = suggestion.toLowerCase();

    let queryOptionKeyword = '';

    if (suggestion.includes("#")) {
      suggestion = suggestion.replace("#", "");
    }

    return new Promise((resolve, reject) => {              

      $.ajax({
        method: 'GET',
        url: 'ptmapi/TreeMessagesApi/GetTreeTagSearch',
        data: {
          searchText: suggestion
        },
        dataType: 'json',
        success: function (data) {
          if (data != null) {
            if (data && data.length > 0) {
              let dataVal = data.join(",");
              let inClauseVal = `(${dataVal})`;

              queryOptionKeyword = `${MAVEN_FIELDS.TREEMAIL_SEARCH} IN ${inClauseVal} AND Actual_Tree_Type LIKE \'%${featureType}%\'`;
            }
            else {
              queryOptionKeyword = MAVEN_FIELDS.KEYWORD_SEARCH + ' = \'' + suggestion + '\' and Actual_Tree_Type LIKE \'%' + featureType + '%\'';
            }

            queryString = queryOptionKeyword;

            let isSearch = true;

            let layerOptions = $.extend(
              {
                where: queryString
              },
              userPlottedLayerView18Options
            );

            //user name and email can only be found in user layer - url from _helpers.js
            layerOptions.url = mapLayers.userPlantedLayer;
            // assign options to speciesLayer along with queryString in an object
            userLayer = L.esri.featureLayer(layerOptions);
            // apply the queryString here

            userLayer
              .query()              
              .where(queryString)
              .run((err, featureCollection, response) => {

                if (featureCollection.features.length > 0) {

                  removeClass($loadingDiv, 'show');
                  userLayer.addTo(map);
                  // console.log("adding to userlayer tags/email", userLayer)
                  // console.log("checking on userlayer tags/email", map.hasLayer(userLayer))

                  if (featureCollection.features.length == 1) {
                    this.loadTreeInfoSingleResult(userLayer, map, response);
                  } else {
                    let arrayOfLatLngs = [];

                    featureCollection.features.map((feature, i) => {
                      if (feature.properties.EYTreeID) {
                        var lat = feature.geometry.coordinates[1],
                          lng = feature.geometry.coordinates[0],
                          coords = [lat, lng];

                        arrayOfLatLngs.push(coords);
                      }
                    });

                    map.fitBounds(arrayOfLatLngs);
                  }
                }
                else {
                  let $mapInfoController = $('.info-buttons'),
                    $close = $('.close', $mapInfoController);

                  $close.trigger('click');

                  removeClass($loadingDiv, 'show');
                  addClass($speciesError, 'show-error');
                }

              });

            resolve(userLayer);
          }
        },
        error: function (jqXhr, textStatus, errorThrown) {
          reject('GetTreeTagSearch error occured: ' + errorThrown);
        }
      });
    });
  }

  //--get token auth--//
  async GetTokenAuth(){    
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: 'ptmapi/TokenAuthApi/GetAuthToken',
        dataType: 'json',  
        async: false,            
        success: function(response){
          window.localStorage.setItem('authToken', response.EditToken);
          //resolve(response.EditToken);
        }
      });
    });
  }

  //-------------------------------------------for user search-------------------------------------------------------//

  addUserLayer(
    $loadingContent,
    $loadingDiv,
    treeLayers,
    map,
    heritage_cluster_layer_view_18,
    flowering_cluster_layer_view_18,
    MAVEN_FIELDS,
    suggestion,
    userPlottedLayerView18Options,
    userLayer,
    $speciesError,
    selectedOption
  ) {
    // , , , , MAVEN_FIELDS, , speciesLayerOptions, speciesLayer

    $loadingContent.text('Loading Search Results');
    addClass($loadingDiv, 'show');
    removeClass($speciesError, 'show-error');


    // remove all layers in case they are present
    for (let layer in treeLayers) {
      if (map.hasLayer(treeLayers[layer])) {
        treeLayers[layer].removeFrom(map);
      }
    }
    if (map.hasLayer(heritage_cluster_layer_view_18)) {
      heritage_cluster_layer_view_18.removeFrom(map);
    }
    if (map.hasLayer(flowering_cluster_layer_view_18)) {
      flowering_cluster_layer_view_18.removeFrom(map);
    }

    if (map.hasLayer(userLayer)) {
      userLayer.removeFrom(map);
    }



    // let checked = this.getCurrentFilter(),
    const featureType = 'USERTREE';

    let queryString = '';

    suggestion = suggestion.toLowerCase();

    let queryOptionName = `${MAVEN_FIELDS.USER_SEARCH} like '%${suggestion}%' and Actual_Tree_Type like '%${featureType}%'`,
      queryOptionEmail = `${MAVEN_FIELDS.EMAIL_SEARCH} like '%${suggestion}%' and Actual_Tree_Type like '%${featureType}%'`,
      queryOptionKeyword = '';
    //queryOptionKeyword = MAVEN_FIELDS.KEYWORD_SEARCH + ' = \'' + suggestion + '\' and Actual_Tree_Type like \'%' + featureType + '%\'';

    if (validateEmail(suggestion)) {
      queryString = queryOptionEmail;

      this.executeIMavenSearch(
        $loadingContent,
        $loadingDiv,
        treeLayers,
        map,
        heritage_cluster_layer_view_18,
        flowering_cluster_layer_view_18,
        MAVEN_FIELDS,
        suggestion,
        userPlottedLayerView18Options,
        userLayer,
        $speciesError,
        selectedOption,
        queryString
      );
    }
    else if (selectedOption && selectedOption.toLowerCase() == 'tag') {

      if (suggestion.includes("#")) {
        suggestion = suggestion.replace("#", "");
      }

      let data = null;

      $.ajax({
        method: 'GET',
        url: 'ptmapi/TreeMessagesApi/GetTreeTagSearch',
        async: false,
        data: {
          searchText: suggestion
        },
        dataType: 'json',
        success: function (data) {
          this.data = data;
        },
        error: function (jqXhr, textStatus, errorThrown) {
          console.log('GetTreeTagSearch error occured: ' + errorThrown);
        }
      });

      if (data != null) {
        if (data && data.length > 0) {
          let dataVal = data.join(",");
          let inClauseVal = `(${dataVal})`;

          queryOptionKeyword = `${MAVEN_FIELDS.TREEMAIL_SEARCH} IN ${inClauseVal} AND Actual_Tree_Type LIKE \'%${featureType}%\'`;
        }
        else {
          queryOptionKeyword = MAVEN_FIELDS.KEYWORD_SEARCH + ' = \'' + suggestion + '\' and Actual_Tree_Type LIKE \'%' + featureType + '%\'';
        }

        queryString = queryOptionKeyword;

        return userLayer = this.executeIMavenSearch(
          $loadingContent,
          $loadingDiv,
          treeLayers,
          map,
          heritage_cluster_layer_view_18,
          flowering_cluster_layer_view_18,
          MAVEN_FIELDS,
          suggestion,
          userPlottedLayerView18Options,
          userLayer,
          $speciesError,
          selectedOption,
          queryString
        );
      }
    }
    else {
      queryString = queryOptionName;

      this.executeIMavenSearch(
        $loadingContent,
        $loadingDiv,
        treeLayers,
        map,
        heritage_cluster_layer_view_18,
        flowering_cluster_layer_view_18,
        MAVEN_FIELDS,
        suggestion,
        userPlottedLayerView18Options,
        userLayer,
        $speciesError,
        selectedOption,
        queryString
      );
    }
  }

  //-------------------------------------------execute imaven search-------------------------------------------------------//
  executeIMavenSearch(
    $loadingContent,
    $loadingDiv,
    treeLayers,
    map,
    heritage_cluster_layer_view_18,
    flowering_cluster_layer_view_18,
    MAVEN_FIELDS,
    suggestion,
    userPlottedLayerView18Options,
    userLayer,
    $speciesError,
    selectedOption,
    queryString
  ) {
    let isSearch = true;

    let layerOptions = $.extend(
      {
        where: queryString
      },
      userPlottedLayerView18Options
    );

    //user name and email can only be found in user layer - url from _helpers.js
    layerOptions.url = mapLayers.userPlantedLayer;
    // assign options to speciesLayer along with queryString in an object
    userLayer = L.esri.featureLayer(layerOptions);
    // apply the queryString here

    userLayer
      .query()
      .where(queryString)
      .run((err, featureCollection, response) => {

        if (featureCollection.features.length > 0) {

          removeClass($loadingDiv, 'show');
          userLayer.addTo(map);
          // console.log("adding to userlayer tags/email", userLayer)
          // console.log("checking on userlayer tags/email", map.hasLayer(userLayer))

          if (featureCollection.features.length == 1) {
            this.loadTreeInfoSingleResult(userLayer, map, response);
          } else {
            let arrayOfLatLngs = [];

            featureCollection.features.map((feature, i) => {
              if (feature.properties.EYTreeID) {
                var lat = feature.geometry.coordinates[1],
                  lng = feature.geometry.coordinates[0],
                  coords = [lat, lng];

                arrayOfLatLngs.push(coords);
              }
            });

            map.fitBounds(arrayOfLatLngs);
          }
        }
        else {
          let $mapInfoController = $('.info-buttons'),
            $close = $('.close', $mapInfoController);

          $close.trigger('click');

          removeClass($loadingDiv, 'show');
          addClass($speciesError, 'show-error');
        }

      });

    // console.log("returning userlayer tags", userLayer)
    return userLayer;
  }

  //-------------------------------------------for treemail search-------------------------------------------------------//

  async addUserLayerTreemail(
    $loadingContent,
    $loadingDiv,
    treeLayers,
    map,
    heritage_cluster_layer_view_18,
    flowering_cluster_layer_view_18,
    MAVEN_FIELDS,
    suggestion,
    layerView18Options,
    allTree_cluster_layer_view_18,
    $speciesError
  ) {
    // , , , , MAVEN_FIELDS, , speciesLayerOptions, speciesLayer

    $loadingContent.text('Loading Search Results');
    addClass($loadingDiv, 'show');
    removeClass($speciesError, 'show-error');


    // remove all layers in case they are present
    for (let layer in treeLayers) {
      if (map.hasLayer(treeLayers[layer])) {
        treeLayers[layer].removeFrom(map);
      }
    }
    if (map.hasLayer(heritage_cluster_layer_view_18)) {
      heritage_cluster_layer_view_18.removeFrom(map);
    }
    if (map.hasLayer(flowering_cluster_layer_view_18)) {
      flowering_cluster_layer_view_18.removeFrom(map);
    }

    if (map.hasLayer(allTree_cluster_layer_view_18)) {
      allTree_cluster_layer_view_18.removeFrom(map);
    }

    // let checked = this.getCurrentFilter(),
    const featureType = 'TREE';

    let queryString = '';

    suggestion = suggestion.toLowerCase();

    let $selectedOptionSearch = $('.mapFilterBtns.is-active').data('value');
    let all_trees = "";

    // console.log($('#mapSearch').val(), "searched value")
    let searchText = $('#mapSearch').val();
    let configData = { "searchText": searchText, "searchType": $selectedOptionSearch };

    return new Promise((resolve, reject) => {

      $.ajax({
        url: "../PtmApi/TreeMessagesApi/GetTreeMailSearch",
        method: 'GET',
        dataType: 'json',
        data: configData,
        contentType: 'application/json',
        success: returnData => {
          // console.log(response);

          let conditionalVal = " or ";
          let treeID = "";

          for (let i = 0; i < returnData.length; i++) {
            treeID = returnData[i]
            if (i == 0) {
              if (treeID.includes("{")) {
                all_trees = `${MAVEN_FIELDS.TREEMAIL_SEARCH} like '%${treeID}%'`;
              } else {
                all_trees = `Public_treeid like '%${treeID}%'`;
              }
            } else {

              if (treeID.includes("{")) {
                all_trees += conditionalVal + `${MAVEN_FIELDS.TREEMAIL_SEARCH} like '%${treeID}%'`;
              } else {
                all_trees += conditionalVal + `Public_treeid like '%${treeID}%'`;
              }

            }
          }

          // console.log("all trees here",all_trees);

          queryString = all_trees
          let isSearch = true;

          let layerOptions = $.extend(
            {
              where: queryString
            },
            layerView18Options
          );

          //user name and email can only be found in user layer - url from _helpers.js
          layerOptions.url = mapLayers.treeLayer;
          // assign options to speciesLayer along with queryString in an object
          allTree_cluster_layer_view_18 = L.esri.featureLayer(layerOptions);


          allTree_cluster_layer_view_18
            .query()
            .where(queryString)
            .run((err, featureCollection, response) => {

              // console.log("responsed",response)
              // console.log(featureCollection, "Feature collection")

              if (featureCollection && featureCollection.features.length > 0) {

                removeClass($loadingDiv, 'show');
                allTree_cluster_layer_view_18.addTo(map);
                // console.log("checking userlayer on map", map.hasLayer(userLayer));
                // console.log("Adding userlayer to map", userLayer);

                //resolve(userLayer);

                if (featureCollection.features.length == 1) {
                  this.loadTreeInfoSingleResult(allTree_cluster_layer_view_18, map, response);
                } else {
                  let arrayOfLatLngs = [];

                  featureCollection.features.map((feature, i) => {
                    // console.log(feature.properties, "feature properties")
                    if (feature.properties.EYTreeID) {
                      var lat = feature.geometry.coordinates[1],
                        lng = feature.geometry.coordinates[0],
                        coords = [lat, lng];

                      arrayOfLatLngs.push(coords);
                    }
                  });

                  map.fitBounds(arrayOfLatLngs);
                }
              }
              else {
                let $mapInfoController = $('.info-buttons'),
                  $close = $('.close', $mapInfoController);

                $close.trigger('click');

                removeClass($loadingDiv, 'show');
                addClass($speciesError, 'show-error');

              }


            });
          // console.log("returning all_tree layer", allTree_cluster_layer_view_18);
          resolve(allTree_cluster_layer_view_18);

          // return userLayer;

        }, error: (error) => {
          reject(error, "error in promise");
        }

      });

    })
  }


  //-------------------------------------------for species search-------------------------------------------------------//
  addSpeciesLayer(
    $loadingContent,
    $loadingDiv,
    treeLayers,
    map,
    heritage_cluster_layer_view_18,
    flowering_cluster_layer_view_18,
    MAVEN_FIELDS,
    suggestion,
    speciesLayerOptions,
    speciesLayer,
    $speciesError
  ) {
    // , , , , MAVEN_FIELDS, , speciesLayerOptions, speciesLayer

    $loadingContent.text('Loading Search Results');
    addClass($loadingDiv, 'show');
    removeClass($speciesError, 'show-error');

    // remove all layers in case they are present
    for (let layer in treeLayers) {
      this.removeLayerFromMap(map, treeLayers[layer]);
    }

    if (map.hasLayer(speciesLayer)) {
      speciesLayer.removeFrom(map);
    }


    this.removeLayerFromMap(map, heritage_cluster_layer_view_18);
    this.removeLayerFromMap(map, flowering_cluster_layer_view_18);

    let checked = this.getCurrentFilter(),
      // featureType - the item filtered
      featureType = checked[0].attr('id');

    let queryString =
      featureType == 'TREE'
        ? `${MAVEN_FIELDS.SPECIES_SEARCH} like '%${suggestion}%'`
        : featureType == 'HERITAGE'
          ? `${MAVEN_FIELDS.SPECIES_SEARCH} like '%${suggestion}%' and Type like '%${featureType}%'`
          : featureType == 'FLOWERING'
            ? `${MAVEN_FIELDS.SPECIES_SEARCH} like '%${suggestion}%' and Type like '%${featureType}%'`
            : featureType == 'USERTREE'
              ? `${MAVEN_FIELDS.SPECIES_SEARCH} like '%${suggestion}%' and Actual_Tree_Type like '%${featureType}%'` //also queries VIP tree.
              : '';

    let isSearch = true;
    let layerOptions = $.extend(
      {
        where: queryString
      },
      speciesLayerOptions
    );

    // assign options to speciesLayer along with queryString in an object
    speciesLayer = L.esri.Cluster.featureLayer(layerOptions);


    // apply the queryString here
    speciesLayer
      .query()
      .where(queryString)
      .run((err, featureCollection, response) => {

        if (featureCollection.features.length > 0) {
          removeClass($loadingDiv, 'show');
          speciesLayer.addTo(map);

          if (featureCollection.features.length == 1) {
            this.loadTreeInfoSingleResult(speciesLayer, map, response);
          } else {
            let arrayOfLatLngs = [];

            featureCollection.features.map((feature, i) => {
              if (feature.properties.EYTreeID) {
                var lat = feature.geometry.coordinates[1],
                  lng = feature.geometry.coordinates[0],
                  coords = [lat, lng];

                arrayOfLatLngs.push(coords);
              }
            });

            map.fitBounds(arrayOfLatLngs);
          }
        }
        else {
          let $mapInfoController = $('.info-buttons'),
            $close = $('.close', $mapInfoController);

          $close.trigger('click');

          removeClass($loadingDiv, 'show');
          addClass($speciesError, 'show-error');
        }
      });
    return speciesLayer;
  }

  validateInputFields(name, email) {
    if (!validateName(name)) {
      $('.required', '.field__name').text(' * Invalid name');
    }
    else {
      $('.required', '.field__name').text(' *');
    }
    if (!validateEmail(email)) {
      $('.required', '.field__email').text(' * Invalid email');
    }
    else {
      $('.required', '.field__email').text(' *');
    }
  }

  getUserMessages(userData) {
    let {
      treeID,
      messagesApiURL,
      $noMessage,
      $messagePagination,
      messagesContent,
      messagesTemplate,
      $messagesTemplateHolder,
      ITEMS_PER_PAGE,
      postData,
      page
    } = userData;

    let eyTreeId = $('.onemap-wrapper').data('ey-tree-id');

    //Request using eyTreeId instead, if the tree clicked is a user plotted tree
    if (eyTreeId) {
      postData.TreeId = eyTreeId;
    }

    $.ajax({
      url: messagesApiURL,
      method: 'POST',
      data: JSON.stringify(postData),
      dataType: 'json',
      contentType: 'application/json',
      processData: true,
      enctype: 'multipart/form-data',
      success: response => {
        if (response.Messages.length) {
          let totalPagesObj = {
            totalPages: Math.ceil(response.TotalItems / ITEMS_PER_PAGE),
            startPage: page ? page : 1
          };
          $noMessage.removeClass('show').addClass('hide');
          removeClass($messagePagination, 'hide');

          let noDuplicatesObj = response.Messages;
          for (let i = 0, len = noDuplicatesObj.length; i < len; i++) {
            let checkName = noDuplicatesObj[i].Name,
              checkMessage = noDuplicatesObj[i].Message;

            for (let j = i + 1; j < noDuplicatesObj.length; j++) {
              let currentName = noDuplicatesObj[j].Name,
                currentMessage = noDuplicatesObj[j].Message;

              if (checkName == currentName || checkMessage == currentMessage) {
                noDuplicatesObj[j].isDuplicate = true;
              }
            }
          }

          messagesContent = messagesTemplate(noDuplicatesObj);
          $messagesTemplateHolder.html(messagesContent);

          this.reportUserMessage(userData);
          this.userPhotosFancybox();

          // $pagination.twbsPagination('destroy');

          window.emitter.emit('pagination', totalPagesObj, treeID);
        }
        else {
          $messagesTemplateHolder.empty();
          addClass($messagePagination, 'hide');
          $noMessage.removeClass('hide').addClass('show');
        }
      }
    });
  }

  showHideLayers(ZOOM_LEVELS, currentZoom, map, treeLayers) {

    let layerToShow = ZOOM_LEVELS.filter(level => level == currentZoom);

    let layersToHide = ZOOM_LEVELS.filter(level => level !== currentZoom);

    layersToHide.map(layer => {
      this.removeLayerFromMap(map, treeLayers[layer]);
    });

    return layerToShow;
  }

  treesLayers(VIEW_LAYERS, VIEW_LAYERS_OPTIONS) {
    // combine url and view layers options into one object for each
    let trees_layer_view_11 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[11]
        },
        VIEW_LAYERS_OPTIONS
      )
    );
    let trees_layer_view_12 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[12]
        },
        VIEW_LAYERS_OPTIONS
      )
    );
    let trees_layer_view_13 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[13]
        },
        VIEW_LAYERS_OPTIONS
      )
    );
    let trees_layer_view_14 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[14]
        },
        VIEW_LAYERS_OPTIONS
      )
    );
    let trees_layer_view_15 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[15]
        },
        VIEW_LAYERS_OPTIONS
      )
    );
    let trees_layer_view_16 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[16]
        },
        VIEW_LAYERS_OPTIONS
      )
    );
    let trees_layer_view_17 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[17]
        },
        VIEW_LAYERS_OPTIONS
      )
    );
    return {
      trees_layer_view_11,
      trees_layer_view_12,
      trees_layer_view_13,
      trees_layer_view_14,
      trees_layer_view_15,
      trees_layer_view_16,
      trees_layer_view_17
    };
  }

  heritageLayers(VIEW_LAYERS, VIEW_HERITAGE_LAYERS_OPTIONS) {
    let heritage_layer_view_11 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[11]
        },
        VIEW_HERITAGE_LAYERS_OPTIONS
      )
    );
    let heritage_layer_view_12 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[12]
        },
        VIEW_HERITAGE_LAYERS_OPTIONS
      )
    );
    let heritage_layer_view_13 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[13]
        },
        VIEW_HERITAGE_LAYERS_OPTIONS
      )
    );
    let heritage_layer_view_14 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[14]
        },
        VIEW_HERITAGE_LAYERS_OPTIONS
      )
    );
    let heritage_layer_view_15 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[15]
        },
        VIEW_HERITAGE_LAYERS_OPTIONS
      )
    );
    let heritage_layer_view_16 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[16]
        },
        VIEW_HERITAGE_LAYERS_OPTIONS
      )
    );
    let heritage_layer_view_17 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[17]
        },
        VIEW_HERITAGE_LAYERS_OPTIONS
      )
    );
    return {
      heritage_layer_view_11,
      heritage_layer_view_12,
      heritage_layer_view_13,
      heritage_layer_view_14,
      heritage_layer_view_15,
      heritage_layer_view_16,
      heritage_layer_view_17
    };
  }

  floweringLayers(VIEW_LAYERS, VIEW_FLOWERING_LAYERS_OPTIONS) {
    let flowering_layer_view_11 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[11]
        },
        VIEW_FLOWERING_LAYERS_OPTIONS
      )
    );
    let flowering_layer_view_12 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[12]
        },
        VIEW_FLOWERING_LAYERS_OPTIONS
      )
    );
    let flowering_layer_view_13 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[13]
        },
        VIEW_FLOWERING_LAYERS_OPTIONS
      )
    );
    let flowering_layer_view_14 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[14]
        },
        VIEW_FLOWERING_LAYERS_OPTIONS
      )
    );
    let flowering_layer_view_15 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[15]
        },
        VIEW_FLOWERING_LAYERS_OPTIONS
      )
    );
    let flowering_layer_view_16 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[16]
        },
        VIEW_FLOWERING_LAYERS_OPTIONS
      )
    );
    let flowering_layer_view_17 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[17]
        },
        VIEW_FLOWERING_LAYERS_OPTIONS
      )
    );

    return {
      flowering_layer_view_11,
      flowering_layer_view_12,
      flowering_layer_view_13,
      flowering_layer_view_14,
      flowering_layer_view_15,
      flowering_layer_view_16,
      flowering_layer_view_17
    };
  }

  userPlantedLayers(VIEW_LAYERS, VIEW_USER_PLANTED_LAYERS_OPTIONS) {
    let user_planted_layer_view_11 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[11]
        },
        VIEW_USER_PLANTED_LAYERS_OPTIONS
      )
    );
    let user_planted_layer_view_12 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[12]
        },
        VIEW_USER_PLANTED_LAYERS_OPTIONS
      )
    );
    let user_planted_layer_view_13 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[13]
        },
        VIEW_USER_PLANTED_LAYERS_OPTIONS
      )
    );
    let user_planted_layer_view_14 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[14]
        },
        VIEW_USER_PLANTED_LAYERS_OPTIONS
      )
    );
    let user_planted_layer_view_15 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[15]
        },
        VIEW_USER_PLANTED_LAYERS_OPTIONS
      )
    );
    let user_planted_layer_view_16 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[16]
        },
        VIEW_USER_PLANTED_LAYERS_OPTIONS
      )
    );
    let user_planted_layer_view_17 = L.esri.featureLayer(
      $.extend(
        {
          url: VIEW_LAYERS[17]
        },
        VIEW_USER_PLANTED_LAYERS_OPTIONS
      )
    );

    return {
      user_planted_layer_view_11,
      user_planted_layer_view_12,
      user_planted_layer_view_13,
      user_planted_layer_view_14,
      user_planted_layer_view_15,
      user_planted_layer_view_16,
      user_planted_layer_view_17
    };
  }

  getCurrentFilter() {
    let checked = $('.radio input[type=radio]').map((index, ele) => {
      if ($(ele).prop('checked')) {
        return $(ele);
      }
    });
    return checked;
  }

  roundOffTreeClusterNumbers(geojson, latLng) {
    let count = geojson.properties.ALL_TREES;
    let abbrev =
      count >= 10000
        ? (Math.round(count / 1000) * 100) / 100 + 'K'
        : count >= 1000
          ? Math.round(count / 100) / 10 + 'K'
          : count >= 100
            ? Math.round(count / 10) * 10
            : count < 100
              ? Math.ceil(count / 10) * 10
              : count;
    let icon = L.divIcon({
      className: 'marker-cluster',
      html: '<div><span>' + abbrev + '</span></div>'
    });

    // cleared
    return L.marker(latLng, {
      icon: icon
    });
  }

  roundOffHeritageClusterNumbers(geojson, latLng) {
    let count = geojson.properties.HERITAGE;
    count = count !== null ? count.trim() : 0;
    count = count == '' ? 0 : parseInt(count);
    let icon;

    if (count >= 10) {
      let abbrev =
        count >= 10000
          ? (Math.round(count / 1000) * 100) / 100 + 'K'
          : count >= 1000
            ? Math.round(count / 100) / 10 + 'K'
            : count >= 100
              ? Math.round(count / 10) * 10
              : count < 100
                ? Math.ceil(count / 10) * 10
                : count;

      icon = L.divIcon({
        className: 'marker-cluster',
        html: '<div><span>' + abbrev + '</span></div>'
      });
    }
    else if (count > 0 && count < 10) {
      icon = L.divIcon({
        className: 'marker-cluster',
        html: '<div><span>' + count + '</span></div>'
      });
    }
    else {
      icon = L.divIcon({
        className: 'marker-cluster-hide',
        html: '<div><span></span></div>'
      });
    }

    return L.marker(latLng, {
      icon: icon
    });
  }

  roundOffFloweringClusterNumbers(geojson, latLng) {
    let count = geojson.properties.FLOWERING;
    count = count !== null ? count.trim() : 0;
    count = count == '' ? 0 : parseInt(count);

    let icon;

    if (count > 0) {
      let abbrev =
        count >= 10000
          ? (Math.round(count / 1000) * 100) / 100 + 'K'
          : count >= 1000
            ? Math.round(count / 100) / 10 + 'K'
            : count >= 100
              ? Math.round(count / 10) * 10
              : count < 100
                ? Math.ceil(count / 10) * 10
                : count;
      icon = L.divIcon({
        className: 'marker-cluster',
        html: '<div><span>' + abbrev + '</span></div>'
      });
    }
    else {
      icon = L.divIcon({
        className: 'marker-cluster-hide',
        html: '<div><span></span></div>'
      });
    }

    return L.marker(latLng, {
      icon: icon
    });
  }

  roundOffUserPlantedClusterNumbers(geojson, latLng) {
    let count = geojson.properties.USERTREE; // TODO: change this
    count = count !== null ? count.trim() : 0;
    count = count == '' ? 0 : parseInt(count);

    let icon;

    if (count > 0) {
      let abbrev =
        count >= 10000
          ? (Math.round(count / 1000) * 100) / 100 + 'K'
          : count >= 1000
            ? Math.round(count / 100) / 10 + 'K'
            : count >= 100
              ? Math.round(count / 10) * 10
              : count < 100
                ? Math.ceil(count / 10) * 10
                : count;
      icon = L.divIcon({
        className: 'marker-cluster',
        html: '<div><span>' + abbrev + '</span></div>'
      });
    }
    else {
      icon = L.divIcon({
        className: 'marker-cluster-hide',
        html: '<div><span></span></div>'
      });
    }

    // cleared
    return L.marker(latLng, {
      icon: icon
    });
  }

  changeFloweringText($image, $text, $flowering) {
    $image.attr('src', '/Cwp/assets/PTM/images/flowering.png');
    $text.text('I\'m Flowering!');
  }

  revertFloweringText($image, $text) {
    $image.attr('src', '/Cwp/assets/PTM/images/flowering-bw.png');
    $text.text('Am I Flowering?');
  }

  addSelectedLayer(e, layer, mapLayers, map) {
    let $this = $(e.target);
    if ($this.is(':checked')) {
      // get data-layer attribute
      let layerID = $this.data('layer');
      // add layerID to end of custom mapLayer

      this.GetTokenAuth();

      let authToken = window.localStorage.getItem('authToken');
      
      layer = L.esri.featureLayer({
        url: `${mapLayers.otherLayers}/${layerID}`,
        token: authToken
      });

      this.addLayerToMap(map, layer);
      layer.once('load', evt => {
        let bounds = L.latLngBounds([]);

        layer.eachFeature(currLayer => {
          let currLayerBounds = currLayer.getBounds();
          bounds.extend(currLayerBounds);
        });

        map.fitBounds(bounds);
      });
    }
    else {
      if (map.hasLayer(layer)) {
        this.removeLayerFromMap(map, layer);
      }
    }
    removeClass($('.layer-filter'), 'show-filter');
    return layer;
  }

  showLoadingTreesMessage($loadingMessage) {
    $loadingMessage.show();
  }

  hideLoadingTreesMessage($loadingMessage) {
    $loadingMessage.hide();
  }

  addTargetToAttributionLinks() {
    let $leafletAttribution = $('.leaflet-control-attribution'),
      $leafletAttributionLinks = $('a', $leafletAttribution);
    $leafletAttributionLinks.map((index, item) => {
      $(item).attr('target', '_blank');
    });

    let $estimateText = $('<span class="estimate">* estimated number </span>');
    $leafletAttribution.before($estimateText);
  }

  locateTree(currentTreeData, type, triggerTreeMail) {
    let {
      mapLayers,
      treeid,
      map,
      handleClick,
      locateLayer,
      treeLayers,
      heritage_cluster_layer_view_18,
      flowering_cluster_layer_view_18,
      user_plotted_layer_view_18,
      layer_view_18,
      isTreeSearch,
      $loadingDiv,
    } = currentTreeData;

    let locateTreeQueryString = `Public_treeid = '${treeid}'`;

    if (type == 'USERTREE' || type == 'VIP') {
      locateTreeQueryString = `EYTreeID = '${treeid}'`;

      // if not in vip page, click map layer USERTREE
      if (!this.isVipPage) {
        $('#USERTREE').trigger('click');
      }
    }

    // if not in vip, page trigger js-explore -map
    if (!this.isVipPage) {
      $('.js-explore-map').trigger('click');
    }

    addClass($loadingDiv, 'show');
    isTreeSearch = true;

    for (let layer in treeLayers) {
      this.removeLayerFromMap(map, treeLayers[layer]);
    }

    this.removeLayerFromMap(map, heritage_cluster_layer_view_18);
    this.removeLayerFromMap(map, flowering_cluster_layer_view_18);
    this.removeLayerFromMap(map, user_plotted_layer_view_18);

    locateLayer.setWhere(locateTreeQueryString);
    locateLayer
      .query()
      .where(locateTreeQueryString)
      .run((err, featureCollection, response) => {
        // tree not found, show all tree layer

        if (!response.features[0]) {
          removeClass($loadingDiv, 'show');

          let error = $('.onemap-wrapper').data('locateError');

          alert(error);

          // show all treeLayers
          document.getElementById('TREE').click();
          this.addLayerToMap(map, treeLayers[12]);

          return false;
        }

        let lat,
          lng,
          objectID,
          geometry = response.features[0].geometry;

        if (response.features[0].attributes) {
          objectID = response.features[0].attributes.OBJECTID;
        }
        else {
          objectID = response.features[0].properties.OBJECTID;
        }

        if (geometry.coordinates) {
          lat = geometry.coordinates[1];
          lng = geometry.coordinates[0];
        }
        else {
          lat = geometry.y;
          lng = geometry.x;
        }

        locateLayer.addTo(map);
        map.setView([lat, lng], 18);

        if (!this.isVipPage) { //on.load function does not load in home page.
          setTimeout(function () {
            removeClass($loadingDiv, 'show');
            let markerObj = locateLayer._layers[objectID];

            if (markerObj) {
              // to open info partially
              markerObj.fireEvent('click');
            } else {
              //console.log(`ObjectID: ${objectID} not found. refresh page or check connectivity`);
            }

            if (triggerTreeMail) {
              $('#treemail-btn').trigger('click');
            }
          }, 2800);
        } else {
          locateLayer.once('load', e => {

            removeClass($loadingDiv, 'show');
            let markerObj = e.target._layers[objectID];

            // // to open info partially
            markerObj.fireEvent('click');
          });
        }

      });
  }

  closeInfoWrapper(data) {
    let { e, $mapDetailsWrapper, mapInfoButtons, map, locateTreeLayer, isTreeSearch, selectedActiveMarker } = data;

    if ($mapDetailsWrapper.hasClass('openPartial')) {
      if (window.selectedMarker) {
        map.removeLayer(window.selectedMarker);
      }
      $('.marked-icon').removeClass('marked-icon');
      this.removeLayerFromMap(map, locateTreeLayer);
      this.showMapButtons(mapInfoButtons);
      this.invalidateMapSize(map);
      // window.treeClicked = false;
      isTreeSearch = false;
      let marginTop = parseInt($('#mapInfo').css('marginTop'));
      let scrollTop = $('#mapInfo')[0].offsetTop - marginTop;
      $('.details-scrollable').animate(
        {
          scrollTop: scrollTop
        },
        0
      );
      this.removeClass($mapDetailsWrapper, 'openPartial');
      this.addClass($mapDetailsWrapper, 'closed');
    }
  }

  // zoomTo(map, ZOOM_18) {
  // 	let zoomToLocation = (loc) => {
  // 		map.setView(loc, ZOOM_18);
  // 	};
  // 	return zoomToLocation;
  // }

  // flyToMarker(coordinates, map) {
  // 	this.invalidateMapSize();
  // 	map.flyTo([coordinates.lat, coordinates.lng], 18);
  // }

  openInfoWrapper(map, coordinates, $mapDetailsWrapper, $loadingDiv) {
    removeClass($loadingDiv, 'show');

    if ($mapDetailsWrapper.hasClass('closed')) {
      this.partiallyOpenInfoWrapper();
      let marginTop = parseInt($('#mapInfo').css('marginTop'));
      let scrollTop = $('#mapInfo')[0].offsetTop - marginTop;

      $('.details-scrollable').animate(
        {
          scrollTop: scrollTop
        },

      );
      // $('.map-details-nav ul li:first-child').find('a').trigger('click');
    }
  }

  userPhotosFancybox() {
    $('a.fancybox-message').fancybox({
      loop: true,
      fitToView: true,
      buttons: ['close'],
      animationEffect: 'zoom-in-out'
    });
  }

  reportUserMessage(userData) {
    let {
      treeID,
      messagesApiURL,
      $noMessage,
      $messagePagination,
      messagesContent,
      messagesTemplate,
      $messagesTemplateHolder,
      ITEMS_PER_PAGE,
      postData
    } = userData;

    let $messageWrapper = $('.contribute-messages-wrapper'),
      $messageItems = $('.message-item', $messageWrapper);

    $messageItems.each((index, item) => {
      let $this = $(item),
        $reportMessage = $('.report-message', $this),
        $reportTooltip = $('.report-tooltip', $this),
        $btnYes = $('.btn--yes', $reportTooltip),
        $btnCancel = $('.btn--cancel', $reportTooltip);

      removeClass($('.report-tooltip'), 'open');
      $reportMessage.on('click', e => {

        if (window.freezeSite) {

          new ErrorPopup(true);

          return false;

        }

        addClass($reportTooltip, 'open');
      });
      $btnYes.on('click', event => {
        let $username = $('.message-item--user-name', $this).text(),
          $usermsg = $('.message-item--user-msg', $this).text(),
          $reportingUrl = $('.onemap-wrapper').data('reportingurl');

        // BUILD NOTE: the url should also be passed as a parameter for this function.
        // Change the method type to POST and uncomment the data object.

        let toHide = $(event.target).closest('.message-item');
        toHide.hide();
        let messageID = toHide.data('messageid');

        let json = {
          MessageId: messageID
        };

        $.ajax({
          url: $reportingUrl,
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(json)
        })
          .done(function (data) {
            // handle response
          })
          .fail(function (error) {
            console.log(error);
          });
      });
      $btnCancel.on('click', e => {
        removeClass($reportTooltip, 'open');
      });
      $(document).on('click', e => {
        let $target = $(e.target);
        if (!$target.parent().is($reportMessage)) {
          if ($target.closest($reportTooltip).length === 0) {
            removeClass($reportTooltip, 'open');
          }
        }
      });
    });
  }

  stickyGalleryDesktop($scrollable, $galleryWrapper) {
    let scroll = () => {
      let parentOffset = $scrollable.offset().top,
        offset = parentOffset + 48;
      $galleryWrapper.offset({
        top: offset
      });
    };
    enquire.register('screen and (min-width: 1024px)', {
      match: () => {
        $scrollable.on('scroll', scroll);
      },
      unmatch: () => {
        $scrollable.off('scroll', scroll);
      }
    });
  }

  setPhotoCount(galleryCount, userPhotoCount) {
    setTimeout(function () {
      $('.gallery__tree h2')
        .children('.gallery__count')
        .html('(' + galleryCount + ')');
      $('.gallery__user h2')
        .children('.gallery__count')
        .html('(' + userPhotoCount + ')');
    }, 100);
  }

  galleryFancybox() {
    $('a.fancybox-gallery, a.fancybox-user, a.fancybox-user-planted').fancybox({
      arrows: true,
      loop: true,
      fitToView: true,
      buttons: ['close'],
      animationEffect: 'zoom-in-out'
    });
  }

  floweringTree(floweringData) {
    let {
      $flowering,
      $tooltip,
      $btnCont,
      $image,
      $text,
      $btnUpload,
      $loadingDiv,
      selectedActiveMarker,
      layer_view_18,
      flowering_cluster_layer_view_18,
      heritage_cluster_layer_view_18,
      user_plotted_layer_view_18,
      speciesLayer,
      userLayer,
      locateTreeLayer,
      treeInfoPath,
      e,
      map
    } = floweringData;

    $flowering.on('click', e => {
      e.preventDefault();

      if (window.freezeSite) {
        new ErrorPopup(true);
      }
      else {
        let $message = $('.message', $tooltip),
          $btnUpload = $('.btn--upload', $tooltip);
        this.addClass($tooltip, 'open');

        if (treeInfoPath.FLOWERING == 'Yes') {
          $message.text('Click "Continue" to report that I\'m no longer flowering.');
          $btnUpload.hide();
        }
        else {
          $message.text('Do you have a photo of the flowering tree? Share with us.');
          $btnUpload.show();
        }
      }
    });

    $btnCont.on('click', e => {
      this.updateFloweringTreeOnMap(floweringData);
    });

    $btnUpload.on('click', e => {
      $('.write-message').trigger('click');
      this.updateFloweringTreeOnMap(floweringData);
    });

    $(document).on('click', e => {
      let $target = $(e.target);
      if (!$target.parent().is($flowering)) {
        if ($target.closest($tooltip).length === 0) {
          removeClass($tooltip, 'open');
        }
      }
    });
  }


  updateFloweringTreeOnMap(floweringData) {

    let {
      $flowering,
      $tooltip,
      $btnCont,
      $image,
      $text,
      $btnUpload,
      $loadingDiv,
      selectedActiveMarker,
      layer_view_18,
      flowering_cluster_layer_view_18,
      heritage_cluster_layer_view_18,
      user_plotted_layer_view_18,
      speciesLayer,
      userLayer,
      locateTreeLayer,
      treeInfoPath,
      e,
      map
    } = floweringData;

    let target = e.target;

    if (window.selectedMarker) {
      map.removeLayer(window.selectedMarker);
    }
    let $this = $(e.target);
    this.removeClass($tooltip, 'open');


    let markerProperties = treeInfoPath,
      type,
      cordX,
      cordY,
      geometry = target.feature.geometry,
      date = new Date();

    date = new Date(date.getTime());

    let fullTime = (date.getHours().toString().length == 2 ? date.getHours().toString() : '0' + date.getHours().toString()) + ':' + ((parseInt(date.getMinutes())).toString().length == 2 ? (parseInt(date.getMinutes())).toString() : '0' + (parseInt(date.getMinutes())).toString()) + ':' + (date.getSeconds().toString());

    let currentDate = date.getFullYear().toString() + '-' + ((date.getMonth() + 1).toString().length == 2 ? (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1).toString()) + '-' + (date.getDate().toString().length == 2 ? date.getDate().toString() : '0' + date.getDate().toString()) + ' ' + fullTime;

    //currentDate = moment().unix();
	//EPOCH time format
	   currentDate = new Date();
     currentDate = Date.parse(currentDate);


    if (treeInfoPath.FLOWERING == 'Yes' || treeInfoPath.Flowering == 'Yes') {

      markerProperties.FLOWERING = 'No';
      markerProperties.Flowering = 'No';

      markerProperties.FLOWERING_START_DATE = currentDate;
      markerProperties.Flowering_Start_Date = currentDate;
      markerProperties.Type = treeInfoPath.ACTUAL_TREE_TYPE;

      if (!markerProperties.Type) {
        if (map.hasLayer(user_plotted_layer_view_18) || map.hasLayer(userLayer) || map.hasLayer(flowering_cluster_layer_view_18)) {
          markerProperties.Type = 'USERTREE';
        }
      }
      this.revertFloweringText($image, $text);
    }
    else {

      markerProperties.FLOWERING = 'Yes';
      markerProperties.Flowering = 'Yes';

      markerProperties.FLOWERING_START_DATE = currentDate;
      markerProperties.Flowering_Start_Date = currentDate;
      markerProperties.Type = 'FLOWERING';

      this.changeFloweringText($image, $text, $flowering);
      // let treeIcon = this.getIconsUrl('FLOWERING', girth);
      // let selectedActiveMarker = L.marker([coordinates.lat, coordinates.lng], {
      //   icon: icon
      // });
      // this.invalidateMapSize(map, coordinates, treeIcon, selectedActiveMarker, $loadingDiv); //call select icon
    }

    if (map.hasLayer(layer_view_18)) {
      this.removeLayerFromMap(map, locateTreeLayer);

      layer_view_18.updateFeature(
        {
          type: 'Feature',
          id: target.feature.id,
          geometry: geometry,
          properties: markerProperties
        },
        (err, response) => { }
      );
    }
    else if (map.hasLayer(user_plotted_layer_view_18)) {
      this.removeLayerFromMap(map, locateTreeLayer);

      user_plotted_layer_view_18.updateFeature(
        {
          type: 'Feature',
          id: target.feature.id,
          geometry: geometry,
          properties: markerProperties
        },
        (err, response) => { }
      );
    }
    else if (map.hasLayer(heritage_cluster_layer_view_18)) {
      this.removeLayerFromMap(map, locateTreeLayer);

      heritage_cluster_layer_view_18.updateFeature(
        {
          type: 'Feature',
          id: target.feature.id,
          geometry: geometry,
          properties: markerProperties
        },
        (err, response) => { }
      );
    }
    else if (map.hasLayer(flowering_cluster_layer_view_18)) {
      this.removeLayerFromMap(map, locateTreeLayer);

      flowering_cluster_layer_view_18.updateFeature(
        {
          type: 'Feature',
          id: target.feature.id,
          geometry: geometry,
          properties: markerProperties
        },
        (err, response) => { }
      );

      //identify type here and update the feature accordingly.
    }
    else if (map.hasLayer(speciesLayer)) {
      speciesLayer.updateFeature(
        {
          type: 'Feature',
          id: target.feature.id,
          geometry: geometry,
          properties: markerProperties
        },
        (err, response) => { }
      );
    }
    else if (map.hasLayer(userLayer)) {
      userLayer.updateFeature(
        {
          type: 'Feature',
          id: target.feature.id,
          geometry: geometry,
          properties: markerProperties
        },
        (err, response) => { }
      );
    }
  }

  addMoreClassToUserGallery(addTreeObj, i) {
    // console.log("length", addTreeObj[i].UserPhotos.length )

    try {

      if (addTreeObj[i].UserPhotos.length > 4) {
        $('.gallery__user').addClass('hasMore');
        $('.fancybox-user')
          .slice(4)
          .delay(0)
          .fadeOut(0);
      }
      else {
        $('.gallery__user').removeClass('hasMore');
      }
    } catch (err) {
      console.log("Error in try block", err);
    }

    let treemailNew = $('.treemail-btn-new');
    treemailNew.on('click', function () {
      //console.log("clicked treemail btn")
      $('#treemail-btn').trigger('click');
    });

    let showTreemailForm = $('.show-treemail-form');
    showTreemailForm.on('click', function () {
      //console.log("clicked add photos btn")
      $('#treemail-btn').trigger('click');
    });
  }

  addMoreClassToGallery(addTreeObj, i) {
    if (addTreeObj[i].Photos) {
      if (addTreeObj[i].Photos.length > 3) {
        $('.gallery__tree').addClass('hasMore');
        $('.fancybox-gallery')
          .slice(3)
          .delay(0)
          .fadeOut(0);
      }
      else {
        $('.gallery__tree').removeClass('hasMore');
      }
    }
  }

  getGirthSizeRange(size) {
    if (size == 'XS') {
      return '0.1m - 0.5m';
    }
    else if (size == 'S') {
      return '0.6m - 1.0m';
    }
    else if (size == 'M') {
      return '1.0m - 1.5m';
    }
    else {
      return '>1.5m';
    }
  }

  getTreeAge(age) {
    if (age == null) {
      return null;
    }
    else {
      return `${age} years`;
    }
  }

  //tree data map detail
  getTreeDataFromMapService(treeData) {
    let { treeMergeInfo, treeInfoPath, treeIcon, treeID } = treeData;
    let girthSizeRange = treeInfoPath.Girth || treeInfoPath.GirthSize;
    let type = treeInfoPath.Type || treeInfoPath.Actual_Tree_Type;
    let age = this.getTreeAge(treeInfoPath.AGE) || treeInfoPath.EstimatedAge;
    let keywords = treeInfoPath.UserKeywords;
    treeMergeInfo.id = treeID;

    try {
      if (age == null || age == "") { }
      else {
        if (age == 0) {
          age = "< 1 year"
        }
        else if (age > 0) {
          age = age + " years"
        }
      }
    } catch (err) {

    }

    try {
      let datePlanted = treeInfoPath.DatePlanted;

      if (datePlanted != null) {
        let datePlantedConvert = new Date(datePlanted);
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let datePlantedActualDate = datePlantedConvert.getDate();
        let datePlantedActualMonth = monthNames[datePlantedConvert.getMonth()];
        let datePlantedActualYear = datePlantedConvert.getFullYear();
        let combinedDate = datePlantedActualDate + " " + datePlantedActualMonth + " " + datePlantedActualYear;

        treeMergeInfo.DatePlanted = combinedDate;

        let currentDateDate = moment().format("DD")
        let currentDateMonth = moment().format("MM")
        let currentDateYear = moment().format("YYYY")

        var todayDate = moment([currentDateYear, currentDateMonth, currentDateDate]);
        var datePlantedValue = moment([datePlantedActualYear, datePlantedConvert.getMonth(), datePlantedActualDate]);

        var years = todayDate.diff(datePlantedValue, 'year');
        datePlantedValue.add(years, 'years');

        var months = todayDate.diff(datePlantedValue, 'months');
        datePlantedValue.add(months, 'months');

        age = years + " years", months + " months";

        if (years == 0) {
          age = "< 1 year";
        }
      }


    } catch (err) {

    }


    if (treeInfoPath.Type == 'HERITAGE') {
      treeMergeInfo.icon = '/Cwp/assets/PTM/images/heritage-tree_380x380.png';
    }
    else {
      treeMergeInfo.icon = treeIcon.options.iconUrl;
    }
    treeMergeInfo.SpeciesCode = treeInfoPath.SPCS_CD;
    treeMergeInfo.scientificName = treeInfoPath.SPSC_NM || treeInfoPath.ScientificName;

    //set cookie for new visitor, show tooltip if first visit
    // if (getCookie('visited') == null){
    //   treeMergeInfo.showToolTip = true;
    // }

    if (treeInfoPath.COMMON_NM) {
      treeMergeInfo.commonName = treeInfoPath.COMMON_NM === 'NULL' ? 'HELLO' : treeInfoPath.COMMON_NM;
    }
    else {
      treeMergeInfo.commonName = treeInfoPath.CommonName;
    }
    if (!this.isVipPage) {
      treeMergeInfo.publicPage = true;
    } else {
      treeMergeInfo.publicPage = false;
      $('.map-details-nav').addClass('hide');
    }

    if (keywords) {
      keywords = keywords.split(/[ ,]+/);
      treeMergeInfo.keywords = keywords; //for dot template
    }
    // treeMergeInfo.keywords = ['#likeThis?', '#nice', '#beautiful','#beautiful','#beautiful']; //test data


    treeMergeInfo.treetype = this.upperCaseFirstLetter(type.toLowerCase());
    treeMergeInfo.heritageText = treeInfoPath.DESCRIPTION;
    if (!age.toLowerCase().includes("nan")) {
      treeMergeInfo.estimated = age;
    }
    treeMergeInfo.girthSize = girthSizeRange;
    treeMergeInfo.height = treeInfoPath.HEIGHT || treeInfoPath.HeightRange;
    treeMergeInfo.Pruning = treeInfoPath.PRUNING ? treeInfoPath.PRUNING : '';
    treeMergeInfo.plottedBy = treeMergeInfo.PlottedBy;
    treeMergeInfo.speciesURL = treeMergeInfo.SpeciesURL;



    // invalidateMapSize(map, coordinates, treeIcon, selectedActiveMarker, $loadingDiv);
  }

  getTreeDataFromSpeciesCode(treeData) {
    let { treeMergeInfo, treeInfoPath, treeIcon, addTreeObj, i, treeID } = treeData; //deconstruct

    let girthSizeRange = treeInfoPath.Girth || treeInfoPath.GirthSize;
    let age = this.getTreeAge(treeInfoPath.AGE) || treeInfoPath.EstimatedAge;
    let benefits = addTreeObj[i].Benefits || '';
    let carbonStorage = addTreeObj[i].CarbonStorage || '';
    let type = treeInfoPath.Type || treeInfoPath.Actual_Tree_Type;

    let splitBenefits;

    if (benefits) {
      if (benefits.indexOf('<p>') > -1) {
        if (carbonStorage) {
          carbonStorage = `<p>${carbonStorage}</p>`;
        }
        benefits = benefits += carbonStorage;
        splitBenefits = benefits.split(/<p>|<\/p>|¤|↵|\r\n|\n|\r/);
        splitBenefits = splitBenefits.filter(n => {
          return n != '' && n != '↵';
        });
      }
      else {
        splitBenefits = benefits.split(/↵|\r\n|\n|\r/);
        splitBenefits.push(carbonStorage);
      }
    }
    else {
      splitBenefits = [carbonStorage];
    }

    if (!this.isVipPage) {
      treeMergeInfo.publicPage = true;
    } else {
      treeMergeInfo.publicPage = false;
      $('.map-details-nav').addClass('hide');
    }

    let benefitsAndCarbonStorage = splitBenefits;

    treeMergeInfo.id = treeID;
    treeMergeInfo.SpeciesCode = treeInfoPath.SPCS_CD;
    treeMergeInfo.speciesURL = addTreeObj[i].SpeciesURL;
    treeMergeInfo.scientificName = treeInfoPath.SPSC_NM;
    treeMergeInfo.commonName = treeInfoPath.COMMON_NM === 'NULL' ? '' : treeInfoPath.COMMON_NM;
    treeMergeInfo.treetype = this.upperCaseFirstLetter(type.toLowerCase());


    treeMergeInfo.heritageText = treeInfoPath.DESCRIPTION;
    if (treeInfoPath.Type == 'HERITAGE') {
      treeMergeInfo.icon = '/Cwp/assets/PTM/images/heritage-tree_380x380.png';
    }
    else {
      treeMergeInfo.icon = treeIcon.options.iconUrl;
    }

    try {
      if (age == null || age == "") { }
      else {
        if (age == 0) {
          age = "< 1 year"
        }
        else if (age > 0) {
          age = age + " years"
        }
      }
    } catch (err) {

    }

    try {
      let datePlanted = treeInfoPath.DatePlanted;

      if (datePlanted != null) {
        let datePlantedConvert = new Date(datePlanted);
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let datePlantedActualDate = datePlantedConvert.getDate();
        let datePlantedActualMonth = monthNames[datePlantedConvert.getMonth()];
        let datePlantedActualYear = datePlantedConvert.getFullYear();
        let combinedDate = datePlantedActualDate + " " + datePlantedActualMonth + " " + datePlantedActualYear;

        treeMergeInfo.DatePlanted = combinedDate;

        let currentDateDate = moment().format("DD")
        let currentDateMonth = moment().format("MM")
        let currentDateYear = moment().format("YYYY")

        var todayDate = moment([currentDateYear, currentDateMonth, currentDateDate]);
        var datePlantedValue = moment([datePlantedActualYear, datePlantedConvert.getMonth(), datePlantedActualDate]);

        var years = todayDate.diff(datePlantedValue, 'year');
        datePlantedValue.add(years, 'years');

        var months = todayDate.diff(datePlantedValue, 'months');
        datePlantedValue.add(months, 'months');

        age = years + " years", months + " months";

        if (years == 0) {
          age = "< 1 year";
        }
      }


    } catch (err) {

    }

    treeMergeInfo.estimated = age;
    treeMergeInfo.hugs = addTreeObj[i].Hugs;
    treeMergeInfo.girthSize = girthSizeRange;
    treeMergeInfo.height = treeInfoPath.HEIGHT || treeInfoPath.HeightRange;
    treeMergeInfo.family = addTreeObj[i].Family;
    treeMergeInfo.nativeDistribution = addTreeObj[i].NativeDistribution;
    treeMergeInfo.localConservationStatus = addTreeObj[i].LocalConservationStatus;
    treeMergeInfo.funFacts = addTreeObj[i].FunFacts;
    treeMergeInfo.benefits = benefitsAndCarbonStorage;
    treeMergeInfo.Photos = addTreeObj[i].Photos;
    let userPhotos = addTreeObj[i].UserPhotos;
    for (let i = 0; i < userPhotos.length; i++) {
      let userPhotoImage = userPhotos[i].Image;

      for (let j = i + 1; j < userPhotos.length; j++) {
        let currentUserPhoto = userPhotos[j].Image;

        if (userPhotoImage == currentUserPhoto) {
          userPhotos[j].isDuplicate = true;
        }
      }
    }
    treeMergeInfo.UserPhotos = userPhotos;
    treeMergeInfo.Pruning = treeInfoPath.PRUNING ? treeInfoPath.PRUNING : '';

    treeMergeInfo.plottedBy = addTreeObj[i].PlottedBy;
  }

  toggleAccordion($accordion) {
    $accordion.on('click', function () {
      let _this = $(this),
        _text = $('.accordion-text', _this);

      if (!_this.hasClass('open')) {
        _this.siblings().removeClass('open');
        $('.accordion-text', _this.siblings()).slideUp(300);
        _this.addClass('open');
        _text.slideDown(300);
      }
      else {
        _this.removeClass('open');
        _text.slideUp(300);
      }
    });
  }

  showMapButtons(data) {
    let { $leafletControlContainer, $layerFilterButton, $infoButton, $basemapButton, $mapContainer } = data;
    $leafletControlContainer.show();
    $layerFilterButton.show();
    $basemapButton.show();
    $infoButton.show();
    this.removeClass($mapContainer, 'smallerMap');
  }

  //additional marker
  //change active marker class name here
  invalidateMapSize(map, coordinates, treeIcon, selectedActiveMarker, $loadingDiv) {
    if ($loadingDiv) {
      addClass($loadingDiv, 'show');
    }

    setTimeout(() => {
      // updates map after map container sized changed
      map.invalidateSize();

      if (treeIcon) {
        const treeIconUrl = treeIcon.options.iconUrl,
          iconFileName = function (str) {
            return str.split('\\').pop().split('/').pop();
          },
          treeIconName = iconFileName(treeIconUrl);

        let activeClassName = '';


        if (treeIconName === 'flowering.png' || treeIconName === 'user-planted-icon.png' || treeIconName === 'VIP.png') {
          activeClassName = 'leaflet-marker-active';
        } else { //else npark tree
          activeClassName = 'leaflet-marker-active marker-npark';
        }

        //adds additional marker on top of the selected one on click. remove itself after wrapper closes
        if (coordinates) {
          let icon = L.icon({
            iconUrl: treeIcon.options.iconUrl,
            iconRetinaUrl: treeIcon.options.iconRetinaUrl,
            className: activeClassName
          });

          selectedActiveMarker = L.marker([coordinates.lat, coordinates.lng], {
            icon: icon
          });

          selectedActiveMarker.addTo(map);
          window.selectedMarker = selectedActiveMarker;
          map.panTo([coordinates.lat, coordinates.lng]);
        }
      }
      if ($loadingDiv) {
        removeClass($loadingDiv, 'show');
      }

    }, 1300); //increase timeout to ensure marker is placed
  }

  updateMarkers(data) {
    let { mapLayers, queryString, MAP_FIELDS, map, markers, featureLayerGeoJson, $loadingMessage, isSearch } = data;
    let url = isSearch ? mapLayers.searchLayer : mapLayers.treeLayer;
    let query = L.esri.query({
      url
    });

    query
      .where(queryString)
      .fields(MAP_FIELDS)
      .run((err, featureCollection, response) => {
        if (err) {
          return;
        }
        else {
          if (map.hasLayer(markers)) {
            this.clearLayers(markers);
          }

          if (featureCollection.features.length > 0) {
            featureLayerGeoJson = L.geoJSON(featureCollection.features, {
              pointToLayer: (geoJSON, layer) => {
                let featureType = geoJSON.properties.Type,
                  size = geoJSON.properties.SIZE,
                  icon = this.getIconsUrl(featureType, size);
                if (!icon) {
                  //console.log("icon is null")
                  return
                }
                return L.marker([layer.lat, layer.lng], {
                  icon: icon,
                  riseOnHover: true
                });
              }
            });

            markers.addLayer(featureLayerGeoJson);
            this.addLayerToMap(map, markers);
            this.fitToBounds(markers, map);
            this.hideLoadingTreesMessage($loadingMessage);
          }
          else {
            alert('No Trees Found');
          }
        }
      });
  }

  clearLayers(layer) {
    layer.clearLayers();
  }

  partiallyOpenInfoWrapper() {
    $('.map-details-wrapper').removeClass('closed');
    $('.map-details-wrapper').addClass('openPartial');

  }

  getIconsUrl(featureType, girthSize) {
    const ICONS_PATH = $('.onemap-wrapper').data('imagespath');

    let size;

    switch (girthSize) {
      case '0.0 - 0.5':
        size = 'XS';
        break;

      case '0.6 - 1.0':
        size = 'S';
        break;

      case '1.1 - 1.5':
        size = 'M';
        break;

      case '> 1.5':
        size = 'L';
    }


    if (size == null) {
      size = 'M';
    }


    let icons = {
      // TREE == ALLTREES
      TREE: L.icon({
        iconUrl: ICONS_PATH + size + '.png',
        iconRetinaUrl: ICONS_PATH + size + '.png'
      }),
      FLOWERING: L.icon({
        iconUrl: ICONS_PATH + 'flowering.png',
        iconRetinaURL: ICONS_PATH + 'flowering.png'
      }),
      HERITAGE: L.icon({
        iconUrl: ICONS_PATH + 'heritageTree.png',
        iconRetinaURL: ICONS_PATH + 'heritageTree.png'
      }),
      VIP: L.icon({
        iconUrl: `${ICONS_PATH}user-planted-icon.png`,
        iconRetinaURL: `${ICONS_PATH}user-planted-icon.png`
      }),
      USERTREE: L.icon({
        iconUrl: `${ICONS_PATH}user-planted-icon.png`,
        iconRetinaURL: `${ICONS_PATH}user-planted-icon.png`
        // iconUrl: `${ICONS_PATH}VIP.png`,
        // iconRetinaURL: `${ICONS_PATH}VIP.png`
      })
    };

    return icons[featureType];
  }

  createTreeInformationObj(e) {
    // add MAVEN tree information (OBJECTID, SPCS_COMMON_NM, HEIGHT etc) + activeMarker icon to tree object

    let treeInformation = {};

    if (e.layer) {

      treeInformation = e.layer.feature.properties;
      treeInformation.activeMarker = e.layer._icon;

    }
    else if (e.target) {

      if (e.target._layers) {

        let key = Object.keys(e.target._layers);
        let markerObj = e.target._layers[key];
        treeInformation = markerObj.feature.properties;
        treeInformation.activeMarker = markerObj._icon;
      }
      else {

        treeInformation = e.target.feature.properties;
        treeInformation.activeMarker = e.target._icon;
      }
    }
    return treeInformation;
  }

  addClass(ele, className) {
    ele.addClass(className);
  }

  removeClass(ele, className) {
    ele.removeClass(className);
  }

  addLayerToMap(map, layer) {
    layer.addTo(map);
  }

  removeLayerFromMap(map, layer) {
    if (map.hasLayer(layer)) {
      layer.removeFrom(map);
    }
  }

  fitToBounds(layer, map) {
    let bounds = layer.getBounds();
    map.fitBounds(bounds);
  }

  upperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  storeEYTreeIDs(response) {
    //Pull valid eyTreeIDs from iMaven's response and store in data
    let EYTreeIDData = '';

    response.features.forEach(treeFromResponse => {
      if (treeFromResponse.attributes) {
        let EYTreeID = treeFromResponse.attributes.EYTreeID;
        //Add to array if EYTreeID is not null
        if (EYTreeID && !EYTreeIDData) {
          EYTreeIDData = EYTreeID;
        }
      }
    });
    //Store in data-ey-tree-id if valid, store empty string if no valid ey tree id
    $('.onemap-wrapper').data('ey-tree-id', EYTreeIDData);
  }

  updateAddThisData(id, paramName, type) {

    let treeId = id,
      currentUrl = window.location.href,
      searchParam = window.location.search;

    currentUrl = currentUrl.replace(searchParam, '');
    currentUrl = currentUrl.replace('#', '');

    let constructedUrl = `${currentUrl}?${paramName}=${treeId}`;

    if (type === 'USERTREE' || type === 'VIP') {

      constructedUrl += `&treetype=USERTREE`;

    }

    constructedUrl = encodeURI(constructedUrl);


    $('#addthisShare').attr('href', constructedUrl);


    history.pushState({ urlPath: constructedUrl }, "", constructedUrl);

  }

  loadTreeInfoSingleResult(mapLayer, map, response) {
    let lat, lng,
      geometry = response.features[0].geometry,
      objectID = response.features[0].attributes ? response.features[0].attributes.OBJECTID : response.features[0].properties.OBJECTID;

    if (geometry.coordinates) {
      lat = geometry.coordinates[1];
      lng = geometry.coordinates[0];
    } else {
      lat = geometry.y;
      lng = geometry.x;
    }

    map.setView([lat, lng], 18);

    mapLayer.once('load', e => {
      let markerObj = e.target._layers[objectID];
      markerObj.fireEvent('click');
    });
  }

}
