'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import { assignIDForAria } from '_helpers';

export default class FloweringTreeItem {
	constructor() {
		let $floweringTreeItem = $('.flowering-tree-item'),
			$userMsg = $('.user-msg', $floweringTreeItem);

		$userMsg.matchHeight();

		assignIDForAria($floweringTreeItem);
	}
}
