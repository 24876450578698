'use strict';

import $ from 'jquery';
import { addClass, removeClass } from '_helpers';

export default class SecondaryNav {
	constructor() {

		let $scrollerList = $('.scroller--list'),
			$secondaryNavList = $('.secondary--nav-list', $scrollerList),
			$secondaryNavItem = $('.secondary--nav-item', $secondaryNavList),
			$scrollerButtons = $('.scroller--buttons'),
			$scrollerRight = $('.scroller-right'),
			$navSectionContainer = $('.nav--section .container'),
			$secondaryListLI = $('.secondary--nav-list li'),
			$scrollerLeft = $('.scroller-left');

		let scrollerListOuterWidth = $secondaryNavList.outerWidth(true);

		let outerWidthItems = [];
		let tempArr = [];

		$secondaryNavItem.each((index, ele) => {
			outerWidthItems.push($(ele).outerWidth());
		});

		let positions = outerWidthItems.map( (item) => {
			tempArr.push(item);

			if(tempArr.length > 1) {
				let reducer = (acc, currValue) => acc + currValue;
				return tempArr.reduce(reducer);
			} else {
				return 0;
			}
		});

		$secondaryNavItem.map((index, ele) => {
			let currentIndex = 0,
			$this = $(ele);

			if($this.hasClass('active')) {
				this.animateScrollLeft($secondaryNavList, this.setScrollLeft($this));
			}
		});

		$scrollerRight.on('click', (e) => {
			let scrollLeft = this.getScrollLeft($secondaryNavList);
			let navListOuterWidth = this.getOuterWidth($secondaryNavList);
			let filteredIems = positions.filter(item => item < navListOuterWidth);
			this.animateScrollLeft($secondaryNavList, scrollLeft + positions[filteredIems.length - 1])
		});

		$scrollerLeft.on('click', (e) => {

			let scrollLeft = this.getScrollLeft($secondaryNavList);
			let navListOuterWidth = this.getOuterWidth($secondaryNavList);
			let filteredIems = positions.filter(item => item < navListOuterWidth);
			this.animateScrollLeft($secondaryNavList, scrollLeft - positions[filteredIems.length - 1])
		});

		$(window).on('resize', (e) => {

			this.showHideScrollerButtons($navSectionContainer, $secondaryListLI, $scrollerButtons);

		}).trigger('resize');
	}

	showHideScrollerButtons($navSectionContainer, $secondaryListLI, $scrollerButtons) {
		let windowWidth = $navSectionContainer.width();
		let widths = [];
		$secondaryListLI.map((index, ele) => {
			widths.push($(ele).outerWidth(true));
		});
		let totalWidth = widths.reduce((acc, val) => {
			return acc + val;
		}, 0);
		if (totalWidth >= windowWidth) {
			removeClass($scrollerButtons, 'hide');
            $('.scroller--list').addClass('content');
		}
		else {
			addClass($scrollerButtons, 'hide');
            $('.scroller--list').removeClass('content');
		}
	}

	setScrollLeft(ele) {
		let halfWidth = ele.outerWidth() / 2;
		let offsetLeft = ele.offset().left;

		return (offsetLeft - halfWidth) - 30;
	}

	getScrollLeft(ele) {
		return ele.scrollLeft();
	}

	getOuterWidth(ele) {
		return ele.outerWidth();
	}

	animateScrollLeft(ele, value) {
		ele.animate({
			scrollLeft: value
		}, 800);
	}

}
