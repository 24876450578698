import $ from 'jquery';
import Cookies from 'js-cookie';

'use strict';

export default class TextAccordion {
	constructor() {
		// let $accordion = $('.accordion'),
		let $accordion = $('.exploreMap'),
		$accordionController = $('.accordion-controller', $accordion),
		$accordionText = $('.accordion-text', $accordion);

		$accordion.on('click', function() {
			let _this = $(this),
				_text = $('.accordion-text', _this);

			if(!_this.hasClass('open')) {
				if(_this.hasClass('exploreMap')) {
					Cookies.set('accordionState', 'open');
					// console.log(Cookies.get('accordionState'));
				}

				_this.siblings().removeClass('open');
				$('.accordion-text', _this.siblings()).slideUp(300);
				_this.addClass('open');
				_text.addClass('open');
			}
			else {
				if(_this.hasClass('exploreMap')) {
					Cookies.set('accordionState', 'close');
				}

				_this.removeClass('open');
				_text.slideUp(300);
			}
		});
	}
}
