import $ from 'jquery';
import 'jquery-match-height';

'use strict';

export default class StatisticsWrapper {
	constructor() {
		let $statisticWrapper = $('.statistics-item-wrapper'),
			$statisticItem = $('.statistic-item', $statisticWrapper),
			$statNumber = $('.stat-number', $statisticItem);

		$statNumber.each(function () {
			$(this).prop('Counter', 0).animate({
					Counter: $(this).text()
			}, {
					duration: 1500,
					easing: 'swing',
					step: function (now) {
							$(this).text(commaSeparateNumber(Math.ceil(now)));
					}
			});
		});

		$statisticItem.matchHeight();

		window.emitter.on('fontResize', () => {
			$('.statistic-item').matchHeight._update();
		});
		
		function commaSeparateNumber(val){
			while (/(\d+)(\d{3})/.test(val.toString())){
				val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
			}
			return val;
		}
	}

}
