'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

import PlantTreeConfirmPopup from '../../atoms/plant-tree-confirm-popup/plant-tree-confirm-popup';
// popup to ask whether location is confirmed

import ErrorPopup from '../../atoms/error-popup/error-popup';
import DragPinPopup from '../../atoms/drag-pin-popup/drag-pin-popup';
import PlotTreeForm from '../../molecules/plot-tree-form/plot-tree-form';


export default class PlotTreeButton {
	constructor(map) {


		let $plotTreeBtn = $('.plot-tree-button'),
			$layerFilterBtn = $('.layer-filter-button'),
			$mapDetailsWrapper = $('.map-details-wrapper'),
			$dragPinPopup = $('.drag-pin-popup'),
			$dragPinPopupOkBtn = $('.drag-pin-popup__btn--ok'),
			$mapInfoController = $('.info-buttons'),
			$close = $('.close', $mapInfoController),
			iconUrl = '../Cwp/assets/PTM/images/pin.png',
			marker,
			$map = map;

		window.isPlotting = false;
		window.pinDropped = false;


		let markersLayer = L.layerGroup().addTo($map);


		// icons
		let defaultIcon = L.icon({
			iconUrl: iconUrl,
			iconSize: [20, 29] //Size of the icon image in pixels.
		});


		window.emitter.on('clearDropPin', function () {
			// 1) remove active droppin (if any)
			$('.droppin').remove();
			// 2) lengthen map container
			$('#onemap').removeClass('smallerMap');
			// 3) close map details wrapper
			$close.trigger('click');
			// 4) remove marker from layer

			if (marker) {
				marker.removeFrom(markersLayer);
			};


		});

		$plotTreeBtn.bind('click touchstart', function (e) {
			e.preventDefault();

			// 2) lengthen map container
			$('#onemap').removeClass('smallerMap');

			// 3) close map details wrapper if any
			$close.trigger('click');
		
			// 3) open the dragpinpopup
			$dragPinPopup.addClass('show-info')
		})

		$dragPinPopupOkBtn.on('click', function(){

			// close the dragpinpopup
			$dragPinPopup.removeClass('show-info');

			if (window.freezeSite) {

						new ErrorPopup(true);
		
						return false;
		
					}
		
					window.isPlotting = !window.isPlotting;
		
					// 1) remove active droppin (if any)
					$('.droppin').remove();
					// 2) lengthen map container
					$('#onemap').removeClass('smallerMap');
					// 3) close map details wrapper if any
					$close.trigger('click');
					// $mapDetailsWrapper.removeClass('openPartial').addClass('closed');
		
					// 4) remove active marker class
					$('.leaflet-market-icon').removeClass('leaflet-marker-active');
					// window.history.replaceState(null, null, "?tree=true");
				
					// console.log(window.newPlot);
					if (window.isPlotting) {
						// plotting
		
						// allow drop pin
						$('.leaflet-container').addClass('droppin-cursor');
		
						// define drop pin
						let droppin = new L.DivIcon({
							className: 'droppin',
							html: `<img src="${iconUrl}">
							<div class="plant-tree-confirm-popup">
							<h1>Confirm location?</h1>
							<div class="plant-tree-confirm-popup__wrapper">
							<button value="yes">Yes</button>
							<button value="no">No</button>
							</div>
							</div>`
						});
		
		
						// define marker for dropping
						marker = $map.editTools.startMarker(null, {
							icon: droppin
						});
		
		
						marker.on('dragstart', function (e) {
		
							dragging();
		
						});
		
						marker.on('moveend', function (e) {
		
							let location = createLatLng(e.target.getLatLng());
		
							handleDropPin(location);
		
						});
		
						// marker is now used as cursor
						marker.addTo(markersLayer);
		
		
					} else {
		
						$layerFilterBtn.show();
		
						marker.removeFrom(markersLayer);
					}
		})



		// $plotTreeBtn.bind('click touchstart', function (e) {
		// 	e.preventDefault();

		// 	if (window.freezeSite) {

		// 		new ErrorPopup(true);

		// 		return false;

		// 	}

		// 	window.isPlotting = !window.isPlotting;

		// 	// 1) remove active droppin (if any)
		// 	$('.droppin').remove();
		// 	// 2) lengthen map container
		// 	$('#onemap').removeClass('smallerMap');
		// 	// 3) close map details wrapper if any
		// 	$close.trigger('click');
		// 	// $mapDetailsWrapper.removeClass('openPartial').addClass('closed');

		// 	// 4) remove active marker class
		// 	$('.leaflet-market-icon').removeClass('leaflet-marker-active');
		// 	// window.history.replaceState(null, null, "?tree=true");
		
		// 	// console.log(window.newPlot);
		// 	if (window.isPlotting) {
		// 		// plotting

		// 		// allow drop pin
		// 		$('.leaflet-container').addClass('droppin-cursor');

		// 		// define drop pin
		// 		let droppin = new L.DivIcon({
		// 			className: 'droppin',
		// 			html: `<img src="${iconUrl}">
		// 			<div class="plant-tree-confirm-popup">
		// 			<h1>Confirm location?</h1>
		// 			<div class="plant-tree-confirm-popup__wrapper">
		// 			<button value="yes">Yes</button>
		// 			<button value="no">No</button>
		// 			</div>
		// 			</div>`
		// 		});


		// 		// define marker for dropping
		// 		marker = $map.editTools.startMarker(null, {
		// 			icon: droppin
		// 		});


		// 		marker.on('dragstart', function (e) {

		// 			dragging();

		// 		});

		// 		marker.on('moveend', function (e) {

		// 			let location = createLatLng(e.target.getLatLng());

		// 			handleDropPin(location);

		// 		});

		// 		// marker is now used as cursor
		// 		marker.addTo(markersLayer);


		// 	} else {

		// 		$layerFilterBtn.show();

		// 		marker.removeFrom(markersLayer);
		// 	}

		// });

		$map.on('editable:drawing:mousedown', function (e) {

			let location = createLatLng(e.layer.getLatLng());

			handleDropPin(location);

		});


		/** HELPER FUNCTIONS **/

		let handleDropPin = function (location) {

			window.pinDropped = true;

			window.emitter.emit('allowZoomFeature', false);

			let rootIframeUrl = $('#plotTreeIframe').data('root-url');
			$('#plotTreeIframe').attr('src', `${rootIframeUrl}?userlatlong=${location}`);


			// when user clicks Yes to confirm and location is saved. location's x and y needs to be sent to plot tree form (the iframe wffm form)

			let latLongLocation = location.split(','); // array containing strings

			let latLong = L.latLng(latLongLocation[0], latLongLocation[1]); // leaflet's latlong

			let currentZoom = $map.getZoom();
			

			if (currentZoom === 12) {

				currentZoom = 18;

			}

			$map.flyTo(latLong, currentZoom, {
				duration: 0.25
			}); // leaflet function to pan the map to our point


			$('.leaflet-container').removeClass('droppin-cursor');

			$('.leaflet-marker-icon').removeClass('leaflet-marker-active');

			$('.droppin__confirmation').addClass('show');

			new PlantTreeConfirmPopup(true, iconUrl, $map, markersLayer);

			new PlotTreeForm(latLongLocation, currentZoom);

		}


		let dragging = function () {

			$('.plant-tree-confirm-popup').removeClass('show');

		}



		// function to create lat and lng
		let createLatLng = (location) => {
			return location.lat + "," + location.lng;
		}

	}
}
