'use strict';

import $ from 'jquery';
import 'leaflet';
import 'esri-leaflet';

import { mapLayers } from '_helpers';


export default class TreeCounter {
  constructor() {

    let $counterTreeFamilies = $('.counter-tree-families'),
    $counterTreeSpecies = $('.counter-tree-species'),
    $counterTreeHeritage = $('.counter-tree-heritage'),
    $countTotal = $('.trees-count__total--count'),
    $familiesNumber = $('.trees-number', $counterTreeFamilies),
    $speciesNumber = $('.trees-number', $counterTreeSpecies),
    $heritageNumber = $('.trees-number', $counterTreeHeritage),
    heritageTreesType= $counterTreeHeritage.data('type');

    let authToken = window.localStorage.getItem('authToken');

    let $popupCounter = $('.tree-counter-display__body--count');

    // Tree Families count
    this.animateNumber($familiesNumber);
    this.animateNumber($countTotal);
    this.animateNumber($popupCounter, 3000);


    // Heritage Trees Count
    let heritageQuery = L.esri.query({
      url: mapLayers.heritageLayer,
	  isModern: false,
      token: authToken
    });

    let currentDay = new Date().getDate();
    let localStorageHTCount = JSON.parse(localStorage.getItem('heritageCount'));

    if(localStorageHTCount && currentDay == localStorageHTCount.setDay) {

      $heritageNumber.text(localStorageHTCount.count);
      this.animateNumber($heritageNumber);

    } else {

      heritageQuery
      .token(authToken)
      .where(`featureType like '%${heritageTreesType}%'`)
      .count((err, count, response) => {
        $heritageNumber.text(count);

        let heritageCount = {
          count: JSON.stringify(count),
          setDay: currentDay
        };

        localStorage.setItem('heritageCount', JSON.stringify(heritageCount));
        this.animateNumber($heritageNumber);
      });
    }

    // Species Count
    let speciesQuery = L.esri.query({
      url: mapLayers.statisticsLayer,
	  isModern: false,
      token : authToken
    });

    let localStorageTSCount = JSON.parse(localStorage.getItem('speciesCount'));

    if(localStorageTSCount && currentDay == localStorageTSCount.setDay) {
      $speciesNumber.text(localStorageTSCount.count);
      this.animateNumber($speciesNumber);
    } else {
      speciesQuery.token(authToken).where('1=1').run((err, featureCollection, response) => {
        let count = response.features[0].attributes.SpeciesCount;
        $speciesNumber.text(count);

        let speciesCount = {
          count: count,
          setDay: currentDay
        };
        localStorage.setItem('speciesCount', JSON.stringify(speciesCount));
        this.animateNumber($speciesNumber);
      });
    }

  }

  animateNumber(number, duration = 1500) {
    number.prop('Counter', 0).animate({
      Counter: number.text()
    }, {
      duration: duration,
      easing: 'swing',
      step: now => {
        number.text(this.commaSeparateNumber(Math.ceil(now)));
      }
    });
  }

  commaSeparateNumber(val) {
    while (/(\d+)(\d{3})/.test(val.toString())){
      val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
    return val;
  }
}
