"use strict";

import $ from "jquery";

export default class SiteHeader {
	constructor() {
		let $header = $(".header"),
			$nav = $(".nav", $header),
			$toggleMenu = $(".js-toggle-menu", $header),
			$icon = $(".icon", $toggleMenu);

		$toggleMenu.bind("click", e => {
			e.preventDefault();

			if ($nav.hasClass("open")) {
				this.closeMobileMenu();
				this.addHamburgerIcon();
				$(".trees-count").removeClass("open-nav");
			} else {
				this.openMobileMenu();
				this.removeHamburgerIcon();
				$(".trees-count").addClass("open-nav");
			}
		});



		var touchMoved;

		$("a")
			.bind("touchend", function(e) {
				if (touchMoved != true) {
					// button click action

					let href = $(this).attr("href");

					if (href && href !== "#") {
						window.location = href;
					}

				}
			})
			.bind("touchmove", function(e) {
				touchMoved = true;
			})
			.bind("touchstart", function() {
				touchMoved = false;
			});
	}

	openMobileMenu() {
		$(".nav").addClass("open");
		$(".header").addClass("nav-open");
		$(".map--view").addClass("nav-open");
	}

	closeMobileMenu() {
		$(".nav").removeClass("open");
		$(".header").removeClass("nav-open");
		$(".map--view").removeClass("nav-open");
	}

	removeHamburgerIcon() {
		$(".mobile-menu")
			.removeClass("icon-hamburger")
			.addClass("icon-close")
			.addClass("is-open");
		$(".site--header__mobile").addClass("is-open");
	}

	addHamburgerIcon() {
		$(".mobile-menu")
			.removeClass("icon-close")
			.removeClass("is-open")
			.addClass("icon-hamburger");
		$(".site--header__mobile").removeClass("is-open");
	}
}
