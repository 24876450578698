'use strict';

import $ from 'jquery';
import 'slick-carousel';
import { assignIDForAria } from '_helpers';

export default class DidYouKnowSlide {
	constructor() {
		let $didYouKnowSlider = $('.did-you-know--slider'),
			$slideItem = $('.did-you-know--slider-item', $didYouKnowSlider),
			$sliderContainer = $('.did-you-know'),
			itemCount = $slideItem.length;

		// Add slick buttons to the slide
		let $prevBtn = '<button class="slick-prev btn-carousel" type="button"><span class="visuallyHidden">Previous Slide</span><i class="icon-left"></i></button>',
			$nextBtn = '<button class="slick-next btn-carousel" type="button"><span class="visuallyHidden">Next Slide</span><i class="icon-right"></i></button>',
			$currSlideItem = '<span class="current-slide">01</span>',
			$totalSlides = '<span class="slide-count">05</span>';

		let $container = $('<div class="container"></div>');
		let $sliderControlsContainer = $('<div class="slider-control-container container"></div>');
		let $sliderBtnGroup = $('<div class="slider-buttons"></div>');
		let $sliderCounter = $('<div class="slider-counter"></div>');

		$sliderControlsContainer.append($sliderBtnGroup);
		$sliderControlsContainer.append($sliderBtnGroup);

		$sliderCounter.append($currSlideItem).append($totalSlides);
		$sliderControlsContainer.append($sliderCounter);

		$container.append($sliderControlsContainer);
		$sliderContainer.append($container);

		$didYouKnowSlider.slick({
			cssEase: 'cubic-bezier(.78, 0, .22, 1)',
			dots: false,
			arrows: true,
			draggable: false,
			prevArrow: $prevBtn,
			nextArrow: $nextBtn,
			appendArrows: $('.slider-buttons')
		});

		$('.slick-prev').bind('click touchstart', () => {
			$didYouKnowSlider.slick('slickPrev');
		});

		$('.slick-next').bind('click touchstart', () => {
			$didYouKnowSlider.slick('slickNext');
		});

		$didYouKnowSlider.on('afterChange', (e, slick, currentSlide) => {
			this.updateSlideNumber(currentSlide);
		});

		assignIDForAria($slideItem);
	}

	updateSlideNumber(currentSlide) {
		$('.current-slide').text(`0${currentSlide + 1}`);
	}
}
