'use strict';

import $ from 'jquery';
import 'leaflet';
import 'esri-leaflet';

import { mapLayers } from '_helpers';

export default class TreeInfo {
  constructor(UniqueId) {

    let uniqueId = UniqueId.toString();
    let $treeInfoContainer = $('.tree-info'),
      $speciesName = $('li .species-name', $treeInfoContainer),
      $commonName = $('.common-name', $treeInfoContainer),
      // $location = $('.location', $treeInfoContainer),
      // $age = $('.age', $treeInfoContainer),
      locateQueryString = `Public_Treeid= '${uniqueId}'`;

    let url = mapLayers.searchLayer;
    let fields = ['SPSC_NM', 'COMMON_NM', 'Age'];

    let query = L.esri.query({
      url
    });

    if (uniqueId.indexOf("{") !== -1) {
      locateQueryString = `EYTreeID = '${uniqueId}'`
    }

    let authToken = window.localStorage.getItem('authToken');

    query
      .token(authToken)
      .where(locateQueryString)
      .fields(fields)
      .run((err, featureCollection, response) => {

        // console.log(response.features[0]);

        if (response){
        if (response.features.length) {


          let { COMMON_NM, SPSC_NM } = response.features[0].attributes;

          handleValueInjection(COMMON_NM, $commonName);
          handleValueInjection(SPSC_NM, $speciesName);

        }
        }


      });


    /* HELPER FUNCTIONS */
    function handleValueInjection(value, container) {

      let text = value;

      if (value) {
        container.text(text);
      } else {
        container.parent().hide();
      }

    }
  }
}
