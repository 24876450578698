import $ from 'jquery';

'use strict';

export default class SearchWrapper {
	constructor() {
		let get = {},
			searchParameters = location.search.substr(1).split(/&/);

		for(let i = 0; i < searchParameters.length; i++){
			let tmp = searchParameters[i].split(/=/);
			if (tmp[0] != "") {
				get[decodeURIComponent(tmp[0])] = decodeURIComponent(tmp.slice(1).join("").replace(/\+/g, " "));
				// console.log(get);
			}
		}

		let keyword = 'Search Result for "' + get.q + '"';
		$('.searchHeader').text(keyword);
	}
}
