'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';


export default class TreeCounterDisplay {
  constructor() {

    let $link = $('.tree-counter-display__labels--link');


    enquire.register("screen and (max-width:767px)", {

      // OPTIONAL
      // If supplied, triggered when a media query matches.
      match : function() {

        $link.appendTo('.tree-counter-display__body--stats');

      },
  
      // OPTIONAL
      // If supplied, triggered when the media query transitions
      // *from a matched state to an unmatched state*.
      unmatch : function() {

        $link.appendTo('.tree-counter-display__labels');

      }
  
  });

  }
}
