'use strict';

import $ from 'jquery';
import 'slick-carousel';
import FloweringTreeItem from '../../atoms/flowering-tree-item/flowering-tree-item';

export default class WhatsHappening {
	constructor() {

		let $whatsHappeningCarousel = $('.whats-happening');

		// Add slick buttons to the slide
		let $prevBtn = '<button class="whats-happening__nav whats-happening__nav--prev" type="button"><span class="visuallyHidden">Previous Slide</span><i class="icon-arrow-left"></i></button>',
			$nextBtn = '<button class="whats-happening__nav whats-happening__nav--next" type="button"><span class="visuallyHidden">Next Slide</span><i class="icon-arrow-right"></i></button>',
			showHideSlickButton = function($prevButton, $nextButton, $items, itemCount) {

				//show and hide button for fintie scroll

				if($items.eq(0).offset().left >= $prevButton.outerWidth()) {
					$prevButton.addClass('is-hidden');
				} else {
					$prevButton.removeClass('is-hidden');
				}

				if (($items.eq(itemCount - 1).offset().left + $items.eq(itemCount - 1).outerWidth()) < $nextButton.offset().left) {
					$nextButton.addClass('is-hidden');
				} else {
					$nextButton.removeClass('is-hidden');
				}

			}

		$whatsHappeningCarousel.each(function() {
			let $this = $(this),
				$floweringTrees = $('.flowering-trees', $this),
				$items = $floweringTrees.children(),
				itemCount = $items.length,
				$prevButton, $nextButton;

				$floweringTrees.on('init', (e, slick) => {
					if(itemCount <= 1) return;
					window.emitter.emit('flowering-carousel-init');

					$prevButton = $this.find('.whats-happening__nav--prev'),
					$nextButton = $this.find('.whats-happening__nav--next');

					showHideSlickButton($prevButton, $nextButton, $items, itemCount);
				});

				$floweringTrees.on('breakpoint', (e, slick, breakpoint) => {
					$prevButton = $this.find('.whats-happening__nav--prev'),
					$nextButton = $this.find('.whats-happening__nav--next');
				});

				$floweringTrees.on('afterChange', (e, slick, currSlide) => {
					showHideSlickButton($prevButton, $nextButton, $items, itemCount);
				});
		});


		

		$whatsHappeningCarousel.each(function(e) {
			let $this = $(this),
				$floweringTrees = $('.flowering-trees', $this),
				$items = $floweringTrees.children(),
				itemCount = $items.length;

		

			$floweringTrees.slick({
				arrows: true,
				draggable: false,
				swipe: false,
				infinite: false,
				variableWidth: true,
				prevArrow: $prevBtn,
				nextArrow: $nextBtn,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							dots: true,
							draggable: true,
							swipe: true
						}
					}
				]
			});


			$('.flowering-trees-container .whats-happening__nav--prev').bind('click touchstart', () => {
				$floweringTrees.slick('slickPrev');
			});
	
			$('.flowering-trees-container .whats-happening__nav--next').bind('click touchstart', () => {
				$floweringTrees.slick('slickNext');
			});

		});

		new FloweringTreeItem();

		
		

	}

	hideSlickButton($button) {
		$button.addClass('is-hidden');
	}

	showSlickButton($button) {
		$button.removeClasS('is-hidden');
	}
}
