import $ from 'jquery';

'use strict';

export default class SiteFooter {
	constructor() {
		let $footerWrapper = $('.site-footer-wrapper'),
		$backToTop = $('.back-to-top', $footerWrapper),
		$btnBackToTop = $('.js-btn-back-to-top', $backToTop);

		$(window).on('scroll', (e) => {
			let $this = $(e.target);

			if($this.scrollTop() >= 150) {
				this.addClass($backToTop, 'show');
			} else {
				this.removeClass($backToTop, 'show');
			}
		});

		$btnBackToTop.on('click', (e) => {
			let $this = $(e.target);

			$('html, body').animate({scrollTop: 0}, 600);
		});
	}

	addClass(ele, className) {
		ele.addClass(className);
	}


	removeClass(ele, className) {
		ele.removeClass(className);
	}
}
