'use strict';

import $ from 'jquery';
// import 'leaflet';
// import 'leaflet.markercluster';
// import 'leaflet-editable';
// import 'leaflet-draw';
// import 'esri-leaflet';
// import 'esri-leaflet-cluster';

window.$ = $;

export default class VipTreeForm {
	constructor() {
		let $closeBtn = $('.vip-tree-form__close-button');

		//Event Handlers ==================================================

		// allow form to close
		$closeBtn.click(function () {
			const urlLink = $("#vipTreeIframe").contents().find('.success-info-wrapper .treeMail-link').attr('href');

			if (urlLink && urlLink != '#'){
				window.location.replace(urlLink);
			}else{
				$('.vip-tree-form').removeClass('show');
			}
		});

	}

}
