'use strict';

import $ from 'jquery';

export default class ErrorPopup {
  constructor(show) {

    let errorPopup = $('.error-popup'),
    okBtn = $('.btn--ok', errorPopup);

    okBtn.click(function() {

      errorPopup.removeClass('show-info');
      $('body').removeClass('scroll-prevent');
    });

    if (show) {
      errorPopup.addClass('show-info');
      $('body').addClass('scroll-prevent');
    }

  }
}
